import React from 'react';
import { PropTypes } from 'prop-types';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input/max';

import { isNullish } from 'service/utility';


const getCountryConfig = (currentCountryCode, value) => {
  const countryConfig = {};

  if (value === '' || isNullish(value)) {
    countryConfig.country = currentCountryCode;
    countryConfig.isLocalNumber = false;
  } else {
    const parsed = parsePhoneNumber(value);

    if (parsed) {
      if (parsed.country) {
        countryConfig.country = parsed.country;
        countryConfig.isLocalNumber = true;
      } else {
        countryConfig.country = currentCountryCode;
        countryConfig.isLocalNumber = false;
      }
    }
  }

  return countryConfig;
};


const PhoneFieldInput = React.forwardRef((props, ref) => {
  const { onChange, value, currentCountryCode, className, ...rest } = props;
  const { country, isLocalNumber } = getCountryConfig(currentCountryCode, value);

  return (
    <PhoneInput
      value={value}
      country={country}
      defaultCountry={currentCountryCode}
      displayInitialValueAsLocalNumber={isLocalNumber}
      limitMaxLength
      onChange={
        (phone) => onChange({
          target: {
            value: phone,
          },
        })
      }
      className={className}
      numberInputProps={{
        className,
      }}
      {...rest}
    />
  );
});

PhoneFieldInput.propTypes = {
  className: PropTypes.string,
  currentCountryCode: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

PhoneFieldInput.displayName = 'PhoneFieldInput';


export default PhoneFieldInput;
