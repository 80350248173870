import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';


const PasswordRequirements = ({
  classes, has1lowercase, has1number, has1symbol, has1uppercase, has8characters,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column justify-content-start align-items-start mb-2">
      <Typography
        variant="caption"
        className={classes.mediumWeight}
      >
        {`${t('auth.Passwordmustcontain')}:`}
      </Typography>
      <div className="ml-5">
        <Typography
          variant="caption"
          className={classnames({ [classes.success]: has8characters })}
        >
          {t('auth.AtLeast8Characters')}
        </Typography>
      </div>
      <div className="mt-2">
        <Typography
          variant="caption"
          className={classes.mediumWeight}
        >
          {`${t('auth.And1OfTheFollowing')}:`}
        </Typography>
      </div>
      <div className="ml-5 d-flex flex-row flex-wrap w-100">
        <div className="flex-1">
          <Typography
            variant="caption"
            className={classnames({ [classes.success]: has1uppercase })}
          >
            {t('auth.UppercaseLetter')}
          </Typography>
        </div>
        <div className="flex-1">
          <Typography
            variant="caption"
            className={classnames({ [classes.success]: has1number })}
          >
            {t('auth.Number')}
          </Typography>
        </div>
      </div>
      <div className="ml-5 d-flex flex-row flex-wrap w-100">
        <div className="flex-1">
          <Typography
            variant="caption"
            className={classnames({ [classes.success]: has1lowercase })}
          >
            {t('auth.LowercaseLetter')}
          </Typography>
        </div>
        <div className="flex-1">
          <Typography
            variant="caption"
            className={classnames({ [classes.success]: has1symbol })}
          >
            {t('auth.SpecialCharacter')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

PasswordRequirements.propTypes = {
  classes: PropTypes.object.isRequired,
  has1lowercase: PropTypes.bool.isRequired,
  has1number: PropTypes.bool.isRequired,
  has1symbol: PropTypes.bool.isRequired,
  has1uppercase: PropTypes.bool.isRequired,
  has8characters: PropTypes.bool.isRequired,
};


const styles = (theme) => ({
  mediumWeight: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  success: {
    color: theme.palette.success.main,
  },
});

const PasswordRequirements2 = withStyles(PasswordRequirements, styles);


export default PasswordRequirements2;
