import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from 'tss-react/mui';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Link as MuiLink, Typography,
} from '@mui/material';


class TermsAndConditionsDialog extends React.Component {
  handleClose = (event, reason) => {
    if (reason === 'backdropClick') return;

    if (this.props.onClose) {
      this.props.onClose();
    }

    if (this.props.onDisagree) {
      this.props.onDisagree();
    }
  };

  handlePrintClick = () => {
    window.print();
  };

  render() {
    const { t, open, classes, onClose, onDisagree, onAgree, printable, termsAndConditions } = this.props;

    const termsConditionsHTML = termsAndConditions.replace(/\n/g, '<br />');

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
      >
        <DialogTitle>
          {t('common.LatestTermsAndConditions').toUpperCase()}
        </DialogTitle>
        <DialogContent>
          <Typography
            component="span"
            dangerouslySetInnerHTML={{ __html: termsConditionsHTML }}
          />
        </DialogContent>
        <DialogActions className={classes.hideOnPrint}>
          {onClose && (
            <Button onClick={onClose}>
              {t('common.Close')}
            </Button>
          )}
          {onDisagree && (
            <MuiLink
              component="button"
              onClick={onDisagree}
            >
              {t('common.Disagree')}
            </MuiLink>
          )}
          {onAgree && (
            <Button
              color="primary"
              variant="contained"
              onClick={onAgree}
            >
              {t('common.Agree')}
            </Button>
          )}
          {printable && (
            <Button
              color="primary"
              variant="contained"
              onClick={this.handlePrintClick}
            >
              {'Print'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

TermsAndConditionsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onAgree: PropTypes.func,
  onClose: PropTypes.func,
  onDisagree: PropTypes.func,
  open: PropTypes.bool,
  printable: PropTypes.bool,
  t: PropTypes.func,
  termsAndConditions: PropTypes.string.isRequired,
};


const styles = {
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
};

const TermsAndConditionsDialog2 = withStyles(TermsAndConditionsDialog, styles);


const TermsAndConditionsDialog3 = withTranslation()(TermsAndConditionsDialog2);

TermsAndConditionsDialog3.displayName = 'TermsAndConditionsDialog';


export default TermsAndConditionsDialog3;
