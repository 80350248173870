import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import NumberField from './NumberField';


const PercentageField = (props) => {
  const { className, ...rest } = props;

  return (
    <NumberField
      {...rest}
      inputProps={{
        suffix: '%',
        decimalScale: 2,
      }}
      className={classnames('percentage-field', { [className]: className })}
    />
  );
};

PercentageField.propTypes = {
  className: PropTypes.string,
};


export default PercentageField;
