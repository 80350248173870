import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import { noDigits, noEmpty } from 'service/utility/errorMessages';
import * as validations from 'service/utility/validations';

import TextField from './TextField';


const NameField = (props) => {
  const { className, ...rest } = props;

  return (
    <TextField
      {...rest}
      validate={(value) => (
        Boolean(value) && !validations.noDigits(value)
        ? noDigits()
        : validations.isEmpty(value)
          ? noEmpty(props.label)
          : null
      )}
      className={classnames('name-field', { [className]: className })}
    />
  );
};

NameField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};


export default NameField;
