import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { getAddressArray } from 'service/utility';


const AddressInformation = ({ object }) => {
  const { t } = useTranslation();

  const addressArray = getAddressArray(object);

  return (
    addressArray.length ? (
      addressArray.map((addressRow, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={idx}>
          {addressRow}
        </Typography>
      ))
    ) : (
      <Typography>
        {`${t('common.NoAddressInformation')}.`}
      </Typography>
    )
  );
};

AddressInformation.propTypes = {
  object: PropTypes.object.isRequired,
};


export default AddressInformation;
