import { SIGN_OUT } from 'app/auth/redux/actions';
import { createReducer } from 'service/utility';

import { SET_CART } from '../actions';


const initialState = null;

const cart_ = createReducer(initialState, {
  [SET_CART]: (state, { cart }) => cart,
  [SIGN_OUT]: () => initialState,
});

export default cart_;
