import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const ButtonSuccess = ({ className }) => (
  <div className={className}>
    <CheckCircleIcon fontSize="inherit" />
  </div>
);

ButtonSuccess.propTypes = {
  className: PropTypes.string.isRequired,
};


const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '0.5rem',
    marginTop: '0.125rem',
    marginBottom: '0.125rem',
    width: 24,
    height: 24,
    color: theme.palette.success.main,
    fontSize: '1.8rem',
  },
});

const ButtonSpinner2 = withStyles(ButtonSuccess, styles);


export default ButtonSpinner2;
