import { createReducer } from 'service/utility';

import { SET_CONFIRM_ACTION_DIALOG } from '../actions';

const initialState = {
  isOpen: false,
};

export const confirmActionDialog = createReducer(initialState, {
  [SET_CONFIRM_ACTION_DIALOG](state, { isOpen, options }) {
    return isOpen ? ({
      isOpen: true,
      title: options.title,
      message: options.message,
      cancelLabel: options.cancelLabel,
      confirmLabel: options.confirmLabel,
      hideConfirm: !options.confirmCallback,
      hideDeny: !options.denyCallback,
      hideCancel: Boolean(options.hideCancel),
    }) : ({
      ...state,
      isOpen: false,
    });
  },
});
