import React from 'react';
import { useHistory, Route } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';

import * as NAVIGATION from 'config/navigation';
import { navigateTo, getReturnToURL } from 'service/navigation';
import { useEffectAsync } from 'service/utility';


const PrivateRoute = (props) => {
  const history = useHistory();
  const componentIsMounted = React.useRef(true);

  const [authenticated, setAuthenticated] = React.useState(false);

  // eslint-disable-next-line
  React.useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);


  const goToLogin = (returnHere) => {
    if (componentIsMounted.current) {
      setAuthenticated(false);
    }
    console.log('No authenticated user');
    navigateTo(history, returnHere ? getReturnToURL(NAVIGATION.LOGIN) : NAVIGATION.LOGIN);
  };

  useEffectAsync(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      console.log('Authenticated user: ', user);
      if (componentIsMounted.current) {
        setAuthenticated(true);
      }
    } catch (error) {
      if (error === 'The user is not authenticated') {
        // No authenticated user, need to redirect
        goToLogin(window.location.pathname);
      } else {
        console.log(error);
      }
    }
  }, []);

  const listener = (data) => {
    switch (data.payload.event) {
      case 'signOut':
        if (componentIsMounted.current) {
          goToLogin();
        }
        break;
      default:
    }
  };

  Hub.listen('auth', listener);

  if (!authenticated) return null;

  return <Route {...props} />;
};


export default PrivateRoute;
