import React from 'react';
import { PropTypes } from 'prop-types';
import { CircularProgress, Fab } from '@mui/material';
import { green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';


const AddToCartSpinner = ({ finished }) => {
  const buttonSx = {
    boxShadow: 0,
    cursor: 'default !important',
    '&:hover': {
      bgcolor: 'primary.main',
    },
    ...(finished && {
      bgcolor: green[500],
    }),
  };

  return (
    <div className="position-relative">
      <Fab
        color="primary"
        sx={buttonSx}
      >
        {finished ? <CheckIcon /> : <ShoppingCartOutlinedIcon />}
      </Fab>
      {!finished && (
        <CircularProgress
          size={68}
          sx={{
            color: 'primary',
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
    </div>
  );
};

AddToCartSpinner.propTypes = {
  finished: PropTypes.bool,
};


export default AddToCartSpinner;
