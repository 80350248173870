import { createReducer } from 'service/utility';

import { SET_PROFILE_SUBMITTING } from '../actions';


const initialState = false;

const submittingProfile = createReducer(initialState, {
  [SET_PROFILE_SUBMITTING]: (state, { submitting }) => submitting,
});

export default submittingProfile;
