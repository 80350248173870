import { Settings } from 'luxon';
import i18next from 'i18next';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import localeDe from 'date-fns/locale/de';
import localeEnUs from 'date-fns/locale/en-US';
import localeEs from 'date-fns/locale/es';
import localeFrCa from 'date-fns/locale/fr-CA';


export const setNewLocale = (newLocale) => {
  Settings.defaultLocale = newLocale;
  i18next.changeLanguage(newLocale);

  let lang;

  if (newLocale === 'de-DE') {
    lang = localeDe;
  } else if (newLocale === 'fr-CA') {
    lang = localeFrCa;
  } else if (newLocale === 'es-ES') {
    lang = localeEs;
  } else {
    lang = localeEnUs;
  }

  registerLocale(newLocale, lang);
  setDefaultLocale(newLocale);
};

export const getLangFromLocale = (locale) => locale.slice(0, 2);

export const getCountryFromLocale = (locale) => locale.slice(3, 5);
