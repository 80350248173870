import { SIGN_OUT } from 'app/auth/redux/actions';
import { cartIdProp } from 'config/models';
import { createReducer } from 'service/utility';

import { SET_CART } from '../actions';


const initialState = null;

const cartId_ = createReducer(initialState, {
  [SET_CART]: (state, { cart }) => (cart || {})[cartIdProp] || null,
  [SIGN_OUT]: (state, { initializer }) => (initializer ? state : initialState),
});

export default cartId_;
