import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { Switch, NavLink, Route, Redirect } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import {
  List, ListItem, ListItemButton, ListItemText, ListItemIcon, ListItemSecondaryAction,
  Divider, IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { SuspenseFallback } from 'common/statusIndicators';


class NestedVertRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  handleMenuToggle = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    const { navConfig, collapsible, classes, ...propsToPass } = this.props;
    const { collapsed } = this.state;

    const { vertRouterPath, defaultPath } = propsToPass;
    const filteredNavConfig = navConfig.filter(
      (e) => !e.subheader && !e.divider && !e.render && e.component && e.hasOwnProperty('relPath')
    );

    return (
      <div className="container pt-3 pt-sm-6 pb-3 px-sm-0">
        <div className="row">
          <div className="col-lg-3 mt-3 d-none d-md-block">
            <List
              component="nav"
              disablePadding
              className={classnames(classes.nav, { collapsed })}
              subheader={
                collapsible ? (
                  <IconButton
                    className={classes.navHamburger}
                    onClick={this.handleMenuToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                ) : null
              }
            >
              {navConfig.filter((e) => !e.hide).map((item, idx) => (
                item.render ? (
                  <div
                    key={idx}
                    className={classes.navListItem}
                  >
                    {item.render()}
                  </div>
                ) : item.subheader ? (
                  <ListItem
                    key={idx}
                    component="div"
                    className={classnames(classes.navListItem, classes.navSubheader)}
                  >
                    {item.icon && (
                      <ListItemIcon>
                        <item.icon />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      inset={!item.icon}
                      primary={item.label}
                      className={classnames(classes.navListItemText, { 'with-icon': Boolean(item.icon) })}
                    />
                  </ListItem>
                ) : item.divider ? (
                  <Divider
                    key={idx}
                    className={classnames(classes.navListItem, classes.navDivider)}
                  />
                ) : (
                  <ListItemButton
                    key={idx}
                    component={NavLink}
                    exact
                    // eslint-disable-next-line max-len
                    className={classnames(classes.navListItem, classes.navLink, { disabled: item.disabled })}
                    activeClassName="active"
                    to={item.absPath || `${vertRouterPath}${item.relPath}`}
                  >
                    {item.icon && (
                      <ListItemIcon>
                        <item.icon />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      inset={!item.icon}
                      primary={item.label}
                      className={classnames(classes.navListItemText, { 'with-icon': Boolean(item.icon) })}
                    />
                    {item.secondaryIcon && (
                      <ListItemSecondaryAction className={classes.navLinkSecondaryAction}>
                        <item.secondaryIcon />
                      </ListItemSecondaryAction>
                    )}
                  </ListItemButton>
                )
              ))}
            </List>
          </div>
          <div className="col-lg-9 mt-4 mt-sm-3">
            <React.Suspense fallback={<SuspenseFallback />}>
              <Switch>
                {defaultPath && (
                  <Route
                    exact
                    path={vertRouterPath}
                    render={() => <Redirect to={`${vertRouterPath}${defaultPath}`} />}
                  />
                )}
                {filteredNavConfig.map((item, idx) => {
                  const RouteComp = item.component;

                  return (
                    <Route
                      key={idx}
                      exact={item.exact}
                      path={`${vertRouterPath}${item.relPath}`}
                      render={(routeProps) => (
                        <RouteComp
                          {...propsToPass}
                          {...routeProps}
                        />
                      )}
                    />
                  );
                })}
              </Switch>
            </React.Suspense>
          </div>
        </div>
      </div>
    );
  }
}

NestedVertRouter.propTypes = {
  classes: PropTypes.object.isRequired,
  collapsible: PropTypes.bool,
  defaultPath: PropTypes.string,
  navConfig: PropTypes.array.isRequired,
  vertRouterPath: PropTypes.string.isRequired,
};


const styles = (theme) => ({
  nav: {
    maxWidth: '300px',
    border: `1px solid ${theme.palette.divider} !important`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    '&.collapsed': {

    },
  },
  navHamburger: {
    marginBottom: '6px',
  },
  navListItem: {
    width: '100%',
  },
  navListItemText: {
    marginTop: 0,
    marginBottom: 0,
    '&.with-icon': {

    },
  },
  navSubheader: {

  },
  navDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  navLink: {
    fontSize: '1.125rem',

    '&.active': {
      fontWeight: 'bold',
    },
  },
  navLinkSecondaryAction: {
    color: theme.palette.error.main,
  },
});

const NestedVertRouter2 = withStyles(NestedVertRouter, styles);


export default NestedVertRouter2;
