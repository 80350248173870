import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { withStyles } from 'tss-react/mui';


const FixedBottomDiv = ({ classes, children, className, color, ...rest }) => (
  <div
    className={
      classnames('fixed-bottom', 'shadow-reverse', classes.fixedBottomDiv, { [className]: className })
    }
    {...rest}
  >
    {children}
  </div>
);

FixedBottomDiv.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};


const styles = (theme, props) => ({
  fixedBottomDiv: {
    bottom: 56,
    backgroundColor: props.color || theme.palette.background.paper,
  },
});

const FixedBottomDiv2 = withStyles(FixedBottomDiv, styles);


export default FixedBottomDiv2;
