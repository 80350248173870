import { call, put, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getErrorMessage } from 'service/utility';

import { loadCart } from './cartSagas';
import { LOAD_CART, setCartLoading } from '../actions';


export function *loadCartSaga() {
  while (true) {
    const { cartId } = yield take(LOAD_CART);

    console.log('loadCartSaga; cartId = ', cartId);
    if (!cartId) return;

    try {
      yield put(setCartLoading(true));
      yield call(loadCart, cartId, true);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('loadCartSaga error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      yield put(setCartLoading(false));
    }
  }
}
