import { createReducer } from 'service/utility';

import { SET_LOCATION } from '../actions';

export const ALL_LOCATIONS = { id: 0, name: 'All Locations' };

const initialState = ALL_LOCATIONS;

export const location = createReducer(initialState, {
  [SET_LOCATION]: (state, { location: location_ }) => location_,
});
