import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { account as accountSelector } from 'app/redux/selectors';
import { getCurrentCurrencySymbol } from 'service/i18n';

import NumberField from './NumberField';


const CurrencyField = (props) => {
  const { account, dispatch, className, ...rest } = props;
  const currencySymbol = getCurrentCurrencySymbol(account);

  return (
    <NumberField
      {...rest}
      inputProps={{
        prefix: currencySymbol,
        decimalScale: 2,
        thousandSeparator: true,
      }}
      className={classnames('currency-field', { [className]: className })}
    />
  );
};

CurrencyField.propTypes = {
  account: PropTypes.object,
  className: PropTypes.string,
  dispatch: PropTypes.func,
};


const mapStateToProps = (state) => ({
  account: accountSelector(state),
});

const CurrencyField2 = connect(mapStateToProps)(CurrencyField);


export default CurrencyField2;
