import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import TextField from './TextField';


const PostalCodeField = (props) => {
  const { className, ...rest } = props;

  return (
    <TextField
      {...rest}
      className={classnames('postalcode-field', { [className]: className })}
    />
  );
};

PostalCodeField.propTypes = {
  className: PropTypes.string,
};


export default PostalCodeField;
