import React from 'react';
import { PropTypes } from 'prop-types';
import { components } from 'react-select';
import { withStyles } from 'tss-react/mui';


const SingleValue_ = ({ children, classes, ...rest }) => (
  <components.SingleValue
    {...rest}
    className={classes.singleValue}
  >
    {children}
  </components.SingleValue>
);

SingleValue_.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
};


const styles = (theme) => ({
  singleValue: {
    color: `${theme.palette.text.primary} !important`,
  },
});

const SingleValue = withStyles(SingleValue_, styles);


export default SingleValue;
