import React from 'react';
import { PropTypes } from 'prop-types';
import { Radio, ListItem, Typography } from '@mui/material';


class Language extends React.Component {
  handleSelect = () => {
    const { onSelect, value } = this.props;

    onSelect(value);
  };

  render() {
    const { divider, label, selected, value } = this.props;

    return (
      <ListItem
        divider={divider}
        onClick={this.handleSelect}
      >
        <Radio
          checked={selected}
          onChange={this.handleSelect}
          value={value}
        />
        <Typography>
          {label}
        </Typography>
      </ListItem>
    );
  }
}

Language.propTypes = {
  divider: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  value: PropTypes.string.isRequired,
};


export default Language;
