import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

import { account as accountSelector } from 'app/redux/selectors';
import { getCurrentCountryCode } from 'service/i18n';
import { isRequired } from 'service/utility/errorMessages';

import PhoneFieldInput from './PhoneFieldInput';


class PhoneField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }


  validateSelf = (value) => {
    const { validate, required, label } = this.props;

    if (!value && required) return isRequired(label);

    return validate ? validate(value) : null;
  };

  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (value) => {
    const { onChange } = this.props;
    const error = this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const {
      account, autoFocus, className, dispatch, error, fullWidth, label = this.props.t('common.Phone'),
      onChange, required, validate, value: rawValue, t, tReady, i18n, ...rest
    } = this.props;
    const { blurred } = this.state;

    const value = rawValue || '';
    const currentCountryCode = getCurrentCountryCode(account);

    return (
      <TextField
        error={blurred && Boolean(error)}
        helperText={error}
        required={required}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        label={label}
        value={value}
        onChange={(e) => this.handleChangeSelf(e.target.value)}
        onBlur={this.handleLeaveFocus}
        variant="outlined"
        classes={{ root: classnames('number-field', 'phone-field', { [className]: className }) }}
        margin="dense"
        InputProps={{
          inputComponent: PhoneFieldInput,
          inputProps: {
            currentCountryCode,
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      />
    );
  }
}

PhoneField.propTypes = {
  account: PropTypes.object,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  dispatch: PropTypes.func,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  i18n: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  t: PropTypes.func,
  tReady: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.string,
};

PhoneField.defaultProps = {
  autoFocus: false,
  error: '',
  fullWidth: false,
  required: false,
  value: '',
};



const mapStateToProps = (state) => ({
  account: accountSelector(state),
});

const PhoneField2 = connect(mapStateToProps)(PhoneField);


const PhoneField3 = withTranslation()(PhoneField2);


export default PhoneField3;
