import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, Typography } from '@mui/material';

import {
  customer as customerSelector,
  household as householdSelector,
  location as locationSelector,
} from 'app/redux/selectors';
import Person from 'common/Person';
import { ButtonSpinner } from 'common/statusIndicators';
import { ITEM_TYPE_MAP } from 'config/itemTypes';
import * as NAVIGATION from 'config/navigation';
import * as API from 'service/api';
import { getReturnToURL } from 'service/navigation';
import { getErrorMessage } from 'service/utility';
import { formatName } from 'service/utility/customer';


class ReassignPassDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCustomerId: null,
      reassigning: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  getPersonLabel = (customer) => (
    `${
      formatName(customer)
    }${
      this.props.pass.assigneeId === customer.id ? ` (${this.props.t('common.currentAssignee')})` : ''
    }`
  );

  handleCustomerSelect = (customer) => {
    this.setState({ selectedCustomerId: customer.id });
  };

  handleReassign = async () => {
    const { pass, onFinish } = this.props;
    const { selectedCustomerId } = this.state;

    this.setState({ reassigning: true });

    const payload = {
      passId: pass.id,
      assignToId: selectedCustomerId,
    };

    try {
      await API.putPass(payload);

      onFinish();
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.putPass error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      if (this._isMounted) {
        this.setState({ reassigning: false });
      }
    }
  };


  render() {
    const { t, open, customer, pass, onClose } = this.props;
    const { reassigning, selectedCustomerId } = this.state;

    const itemTypeKey = pass.itemType ? ITEM_TYPE_MAP[pass.itemType] : null;
    const itemTypeName = itemTypeKey ? t(`common:${itemTypeKey}`) : null;
    const household = this.props.household.filter((e) => e.parentCustomerId !== null);

    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          {t('common.ReassignX', { item: itemTypeName })}
        </DialogTitle>
        <DialogContent>
          <Typography paragraph>
            {`${
              t('common.WhoYouWantToReassignXTo', { context: itemTypeKey })
            }. ${
              t('common.OnlyThePersonYouSelect', { context: itemTypeKey })
            }.`}
          </Typography>
          <Typography paragraph>
            {`${t('common.IfYouWouldLikeToReassignThis')}, ${t('common.please')} `}
            <Link to={getReturnToURL(NAVIGATION.HOUSEHOLD)}>
              {t('common.clickHere')}
            </Link>
            {` ${t('common.ToGoToYourProfileAnd')}.`}
          </Typography>
          <List disablePadding className="w-100 mt-4">
            <Person
              person={customer}
              onSelect={this.handleCustomerSelect}
              selected={selectedCustomerId === customer.id}
              label={this.getPersonLabel(customer)}
              selectable={pass.assigneeId !== customer.id}
            />
            {household.map((hm) => (
              <Person
                key={`HM_${hm.id}`}
                person={hm}
                onSelect={this.handleCustomerSelect}
                selected={selectedCustomerId === hm.id}
                label={this.getPersonLabel(hm)}
                selectable={pass.assigneeId !== hm.id}
              />
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={reassigning}
          >
            {t('common.Cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleReassign}
            disabled={reassigning || !selectedCustomerId}
          >
            {t('common.Reassign')}
            {reassigning && <ButtonSpinner />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ReassignPassDialog.propTypes = {
  customer: PropTypes.object.isRequired,
  household: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pass: PropTypes.object.isRequired,
  t: PropTypes.func,
};


const mapStateToProps = (state) => ({
  customer: customerSelector(state),
  household: householdSelector(state),
  location: locationSelector(state),
});

const ReassignPassDialog2 = connect(mapStateToProps)(ReassignPassDialog);


const ReassignPassDialog3 = withTranslation()(ReassignPassDialog2);


export default ReassignPassDialog3;
