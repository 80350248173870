import { createReducer } from 'service/utility';

import { SET_LOADING_CUSTOMER } from '../actions';


const initialState = false;

const loadingCustomer = createReducer(initialState, {
  [SET_LOADING_CUSTOMER]: (state, { loading }) => loading,
});

export default loadingCustomer;
