import { take, call } from 'redux-saga/effects';

import { GET_CUSTOMER_CART_AT_LOCATION } from '../actions';
import { getCustomerCartAtLocation } from './cartSagas';


export function *getCustomerCartAtLocationSaga() {
  while (true) {
    const { locationId, aaPayload } = yield take(GET_CUSTOMER_CART_AT_LOCATION);

    yield call(getCustomerCartAtLocation, locationId, aaPayload);
  }
}
