import { v1 as uuidv1 } from 'uuid';

import i18n from 'config/i18n';
import { canGuestCheckoutItemType } from 'config/itemTypes';
import { isNullish } from 'service/utility';
import { getPriceProp, getSignupFeeProp } from 'service/utility/pricing';


export const getMembershipTypeCartItems = ({
  membershipType, assignedCustomerId = null, quantity = 1, account,
  assignedBookingId, reserveAfterPurchase,
}) => {
  const items = [];

  for (let i = 0; i < quantity; i++) {
    const membershipItem = {
      type: 'MEMBERSHIP',
      membershipTypeId: membershipType.id,
      quantity: 1,
      doNotProrate: membershipType.doNotProrate,
    };

    if (assignedCustomerId) {
      membershipItem.assignedCustomerId = assignedCustomerId;
    }

    if (assignedBookingId) {
      membershipItem.bookingId = assignedBookingId;
      membershipItem.reserveAfterPurchase = reserveAfterPurchase;
    }

    if (membershipType[getSignupFeeProp(account)]) {
      const relatedUUID = uuidv1();

      membershipItem.relatedUUID = relatedUUID;

      const feeOrderItem = {
        type: 'FEE',
        [getPriceProp(account)]: membershipType[getSignupFeeProp(account)],
        description: i18n.t('common.MembershipSignupFee'),
        relatedUUID,
        membershipTypeId: membershipType.id,
      };

      if (membershipType.isTaxExempt) {
        feeOrderItem.isTaxExempt = true;
      }

      items.push(membershipItem);
      items.push(feeOrderItem);
    } else {
      items.push(membershipItem);
    }
  }

  return items;
};

export const getPassTypeCartItems = ({
  passType, assignedCustomerId = null, quantity = 1,
  assignedBookingId, reserveAfterPurchase,
}) => {
  const items = [];

  for (let i = 0; i < quantity; i++) {
    const passItem = {
      type: 'PASS',
      passTypeId: passType.id,
      quantity: 1,
    };

    if (assignedCustomerId) {
      passItem.assignedCustomerId = assignedCustomerId;
    }

    if (assignedBookingId) {
      passItem.bookingId = assignedBookingId;
      passItem.reserveAfterPurchase = reserveAfterPurchase;
    }

    items.push(passItem);
  }

  return items;
};

export const canGuestCheckoutItem = (cartItem) => (
  cartItem.type === 'MEMBERSHIP'
    ? Boolean(cartItem.membershipType.allowGuestCheckout)
    : cartItem.type === 'PASS'
      ? Boolean(cartItem.passType.allowGuestCheckout)
      : cartItem.type === 'EVENT_BOOKING_TICKET' || cartItem.type === 'EVENT_SERIES_TICKET'
        ? Boolean(cartItem.ticketType.allowGuestCheckout)
        : true
);

export const itemsNotAvailableForGC = (cartItems, enableMPT) => cartItems.filter((item) => (
  !canGuestCheckoutItemType(item.type, enableMPT)) || !canGuestCheckoutItem(item)
);

export const cannotChargeCard = ({
  cartItemCount, authUser, customer, savingCard, transactionInProgress,
}) => (
  !cartItemCount || (authUser && !customer.source) || savingCard || transactionInProgress
);

export const getCartSummary = (cartItems) => {
  const summary = {
    deposits: 0,
    tickets: {},
    giftCards: 0,
    memberships: {},
    passes: {},
  };

  cartItems.forEach((cartItem) => {
    if (cartItem.type === 'EVENT_BOOKING_INVOICE') {
      summary.deposits += 1;
    } else if (cartItem.type === 'GIFT_CARD') {
      summary.giftCards += 1;
    } else if (cartItem.type === 'EVENT_BOOKING_TICKET' || cartItem.type === 'EVENT_SERIES_TICKET') {
      if (!summary.tickets[cartItem.ticketTypeId]) {
        summary.tickets[cartItem.ticketTypeId] = {
          qty: 0,
          name: cartItem.ticketType.name,
        };
      }
      summary.tickets[cartItem.ticketTypeId].qty += 1;
    } else if (cartItem.type === 'MEMBERSHIP') {
      if (!summary.memberships[cartItem.membershipTypeId]) {
        summary.memberships[cartItem.membershipTypeId] = {
          qty: 0,
          name: cartItem.membershipType.name,
        };
      }
      summary.memberships[cartItem.membershipTypeId].qty += 1;
    } else if (cartItem.type === 'PASS') {
      if (!summary.passes[cartItem.passTypeId]) {
        summary.passes[cartItem.passTypeId] = {
          qty: 0,
          name: cartItem.passType.name,
        };
      }
      summary.passes[cartItem.passTypeId].qty += 1;
    }
  });

  const summary2 = [
    ...Object.values(summary.memberships),
    ...Object.values(summary.passes),
    ...Object.values(summary.tickets),
  ];

  if (summary.giftCards) {
    summary2.push({
      qty: summary.giftCards,
      name: i18n.t('common.GiftCardz'),
    });
  }

  if (summary.deposits) {
    summary2.push({
      qty: summary.deposits,
      name: i18n.t('common.DepositFeez'),
    });
  }

  return summary2;
};

export const getMembershipFrequency = (membership) => {
  const { membershipType } = membership;
  const {
    isRecurring, billingType, billingDayOfMonth, billingDayOfWeek, duration, durationType,
  } = membershipType;


  if (isRecurring && billingType === 'DOP' && duration && durationType) {
    if (durationType === 'WEEK') {
      return i18n.t('calendar.EveryWeek', { count: duration });
    }

    if (durationType === 'MONTH') {
      return i18n.t('calendar.EveryMonth', { count: duration });
    }

    if (durationType === 'YEAR') {
      return i18n.t('calendar.EveryYear', { count: duration });
    }

    return '';
  }

  if (isRecurring && billingType === 'WEEKLY' && !isNullish(billingDayOfWeek)) {
    return i18n.t('calendar.EveryWeek');
  }

  if (isRecurring && billingType === 'MONTHLY' && !isNullish(billingDayOfMonth)) {
    return i18n.t('calendar.EveryMonth');
  }

  return '';
};

export const isSignupFee = (cartItem) => (
  cartItem.type === 'FEE' && cartItem.feeType === 'signup_fee' && cartItem.relatedUUID
);

export const getTACAgreedValue = (cartItem, promptForTAC) => (
  promptForTAC ? cartItem.termsAndConditionsAgreed ?? false : true
);
