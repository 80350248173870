import React from 'react';
import { PropTypes } from 'prop-types';
import { Checkbox, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@mui/material';

import { PersonAvatar } from 'common/avatars';
import { formatName } from 'service/utility/customer';


class Person extends React.Component {
  handleSelect = () => {
    const { onSelect, person } = this.props;

    onSelect(person);
  };

  render() {
    const { label, person, selected, selectable } = this.props;

    return (
      <ListItem
        button={selectable}
        onClick={selectable && this.handleSelect}
      >
        <ListItemAvatar>
          <PersonAvatar
            src={person.imageURL}
            size="sm"
          />
        </ListItemAvatar>
        <ListItemText primary={label ?? formatName(person)} />
        {selectable && (
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              onChange={this.handleSelect}
              checked={selected}
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
}

Person.propTypes = {
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  person: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectable: PropTypes.bool,
};

Person.defaultProps = {
  selectable: true,
};


export default Person;
