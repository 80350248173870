import React from 'react';
import { PropTypes } from 'prop-types';
import { components } from 'react-select';
import { withStyles } from 'tss-react/mui';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const DropdownIndicator_ = ({ classes, ...rest }) => (
  <components.DropdownIndicator {...rest}>
    <ArrowDropDownIcon className={classes.dropdownIndicator} />
  </components.DropdownIndicator>
);

DropdownIndicator_.propTypes = {
  classes: PropTypes.object.isRequired,
};


const styles = (theme) => ({
  dropdownIndicator: {
    color: theme.palette.action.active,
  },
});

const DropdownIndicator = withStyles(DropdownIndicator_, styles);


export default DropdownIndicator;
