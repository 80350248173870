import { createReducer } from 'service/utility';
import { setNewLocale } from 'service/utility/i18n';

import { SET_LANG } from '../actions';

const initialState = 'en-US';

export default createReducer(initialState, {
  [SET_LANG]: (state, { lang }) => {
    if (lang !== state) {
      setNewLocale(lang);
    }

    return lang;
  },
});
