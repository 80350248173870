import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControl, InputLabel, Typography,
} from '@mui/material';

import {
  profileConfirmed as profileConfirmedAction,
  profileCancelled as profileCancelledAction,
} from 'app/redux/actions';
import {
  confirmProfileDialog as confirmProfileDialogSelector,
  submittingProfile as submittingProfileSelector,
} from 'app/redux/selectors';
import { ButtonSpinner } from 'common/statusIndicators';
import { formatName, formatBirthdate, formatPhone, formatAddress } from 'service/utility/customer';


const ConfirmProfileDialog = (props) => {
  const { t } = useTranslation();

  const { confirmProfileDialog, profileConfirmed, profileCancelled, submittingProfile } = props;
  const { isOpen, customer } = confirmProfileDialog;

  return customer ? (
    <Dialog
      open={isOpen}
      onClose={profileCancelled}
    >
      <DialogTitle>
        {t('customer.HeyWeFoundYourProfile')}
      </DialogTitle>
      <DialogContent>
        <FormControl
          fullWidth
          margin="dense"
        >
          <InputLabel shrink>
            {t('common.Name')}
          </InputLabel>
          <Typography className="mt-3">
            {formatName(customer)}
          </Typography>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
        >
          <InputLabel shrink>
            {t('customer.Birthdate')}
          </InputLabel>
          <Typography className="mt-3">
            {formatBirthdate(customer)}
          </Typography>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
        >
          <InputLabel shrink>
            {t('common.Address')}
          </InputLabel>
          <Typography className="mt-3">
            {formatAddress(customer)}
          </Typography>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
        >
          <InputLabel shrink>
            {t('common.Email')}
          </InputLabel>
          <Typography className="mt-3">
            {customer.email}
          </Typography>
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
        >
          <InputLabel shrink>
            {t('customer.Mobile')}
          </InputLabel>
          <Typography className="mt-3">
            {formatPhone(customer)}
          </Typography>
        </FormControl>
        <p className="mt-4 mb-0 font-weight-medium">
          {t('common.IsEverythingCorrect')}
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={profileCancelled}
          disabled={submittingProfile}
        >
          {`${t('common.No')}, ${t('customer.EditProfile')}`}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={profileConfirmed}
          disabled={submittingProfile}
        >
          {t('common.Yes')}
          {submittingProfile && <ButtonSpinner />}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

ConfirmProfileDialog.propTypes = {
  confirmProfileDialog: PropTypes.object.isRequired,
  profileCancelled: PropTypes.func.isRequired,
  profileConfirmed: PropTypes.func.isRequired,
  submittingProfile: PropTypes.bool.isRequired,
};


const mapStateToProps = (state) => ({
  confirmProfileDialog: confirmProfileDialogSelector(state),
  submittingProfile: submittingProfileSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  profileConfirmed: () => dispatch(profileConfirmedAction()),
  profileCancelled: () => dispatch(profileCancelledAction()),
});

const ConfirmProfileDialog2 = connect(mapStateToProps, mapDispatchToProps)(ConfirmProfileDialog);


export default ConfirmProfileDialog2;
