import React from 'react';
import { PropTypes } from 'prop-types';
import { components } from 'react-select';
import { List } from '@mui/material';
import { withStyles } from 'tss-react/mui';


const MenuList_ = ({ children, classes, ...rest }) => (
  <components.MenuList {...rest}>
    <List className={classes.list}>
      {children}
    </List>
  </components.MenuList>
);

MenuList_.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
};


const styles = (theme) => ({
  list: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
});

const MenuList = withStyles(MenuList_, styles);


export default MenuList;
