import React from 'react';
import { PropTypes } from 'prop-types';

import Avatar from './Avatar';


const ProductAvatar = ({ src, size, onClick }) => (
  <Avatar
    className="product-avatar"
    size={size}
    src={src}
    onClick={onClick}
  />
);

ProductAvatar.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  src: PropTypes.string,
};

ProductAvatar.defaultProps = {
  size: 'normal',
  src: '',
};


export default ProductAvatar;
