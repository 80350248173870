import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import classnames from 'classnames';


const BorderedRoundedContainer = ({
  background, children, classes, className, disablePadding, header,
}) => {
  if (header) {
    return (
      <div className={classnames(classes.borderedContainer, className, { 'p-0': disablePadding })}>
        {header}
        <div className={classnames(classes.innerContainer, { [classes.background]: background })}>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className={classnames(classes.borderedContainer, classes.pad, className, { 'p-0': disablePadding })}>
      {children}
    </div>
  );
};

BorderedRoundedContainer.propTypes = {
  background: PropTypes.bool,
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disablePadding: PropTypes.bool,
  header: PropTypes.any,
};


const styles = (theme) => ({
  background: {
    backgroundColor: theme.palette.background.header,
  },
  borderedContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  innerContainer: {
    width: '100%',
    padding: '0.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  pad: {
    padding: '1rem',
  },
});

const BorderedRoundedContainer2 = withStyles(BorderedRoundedContainer, styles);


export default BorderedRoundedContainer2;
