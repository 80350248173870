import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';

import { US_STATES } from 'config/usStates';
import { isRequired } from 'service/utility/errorMessages';

import Option from './StateSearchItem';
import DropdownIndicator from './DropdownIndicator';
import MenuList from './MenuList';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
import Control from './Control';


class StateSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }


  getPicklistValue = (option) => ({ label: option, value: option });


  validateSelf = (value) => {
    const { required, label, validate } = this.props;

    if (!value && required) return isRequired(label);
    if (validate) return validate(value);

    return null;
  };


  // handleLeaveFocus = () => {
  //   const { value, onChange } = this.props;
  //   const error = this.validateSelf(value);

  //   this.setState({
  //     blurred: true,
  //   });

  //   if (onChange) {
  //     onChange({ value, error });
  //   }
  // };

  handleChangeSelf = (option) => {
    const { onChange } = this.props;
    const value = option ? option.value : null;
    const error = this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };


  render() {
    const { autoFocus, disabled, error, label, value, fullWidth, className, required } = this.props;
    const { blurred } = this.state;
    const showAsError = blurred && Boolean(error);

    return (
      <FormControl
        classes={{ root: classnames('generic-search state-search', { [className]: className }) }}
        margin="dense"
        fullWidth={fullWidth}
      >
        {label && (
          <InputLabel
            error={showAsError}
            classes={{ root: 'input-label' }}
            shrink
            variant="outlined"
          >
            {`${label}${required ? ' *' : ''}`}
          </InputLabel>
        )}
        <Select
          isClearable
          isDisabled={disabled}
          value={value ? this.getPicklistValue(value) : null}
          options={US_STATES.map(this.getPicklistValue)}
          onChange={this.handleChangeSelf}
          // onBlur={this.handleLeaveFocus}
          placeholder=""
          className={classnames('react-select-container', { error: showAsError })}
          classNamePrefix="react-select"
          components={{ Option, DropdownIndicator, MenuList, ValueContainer, SingleValue, Control }}
          maxMenuHeight={312}
          openMenuOnClick={false}
          autoFocus={autoFocus}
          menuPortalTarget={document.body}
          menuPosition="absolute"
          menuPlacement="bottom"
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {error && (
          <FormHelperText
            error={showAsError}
            variant="outlined"
            margin="dense"
            required
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

StateSearch.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.string,
};

StateSearch.defaultProps = {
  autoFocus: false,
  className: '',
  disabled: false,
  error: '',
  fullWidth: false,
  required: false,
};


export default StateSearch;
