import { makeActionCreator } from 'service/utility';

export const SIGN_IN = 'SIGN_IN';
export const signIn = makeActionCreator(SIGN_IN, 'user', 'history');

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const setCurrentUser = makeActionCreator(SET_CURRENT_USER, 'user');

export const SET_TEMPORARY_EMAIL = 'SET_TEMPORARY_EMAIL';
export const setTemporaryEmail = makeActionCreator(SET_TEMPORARY_EMAIL, 'email');

export const SET_TEMPORARY_PASSWORD = 'SET_TEMPORARY_PASSWORD';
export const setTemporaryPassword = makeActionCreator(SET_TEMPORARY_PASSWORD, 'password');

export const SIGN_OUT = 'SIGN_OUT';
export const signOut = makeActionCreator(SIGN_OUT, 'initializer');

export const SET_AUTH_FINISHED = 'SET_AUTH_FINISHED';
export const setAuthFinished = makeActionCreator(SET_AUTH_FINISHED);
