import { applyGiftCardSaga } from './applyGiftCardSaga';
import { applyPromoCodeSaga } from './applyPromoCodeSaga';
import { createCartSessionSaga } from './createCartSessionSaga';
import { getCustomerCartAtLocationSaga } from './getCustomerCartAtLocationSaga';
import { loadCartSaga } from './loadCartSaga';
import { putItemsInCartSaga } from './putItemsInCartSaga';
import { removeGiftCardSaga } from './removeGiftCardSaga';
import { removeItemFromCartSaga } from './removeItemFromCartSaga';
import { removePromoCodeSaga } from './removePromoCodeSaga';
import { updateCartItemsSaga } from './updateCartItemsSaga';
import { updateItemQuantitySaga } from './updateItemQuantitySaga';

export * from './cartSagas';

const cartSagas = [
  applyGiftCardSaga,
  applyPromoCodeSaga,
  createCartSessionSaga,
  getCustomerCartAtLocationSaga,
  loadCartSaga,
  putItemsInCartSaga,
  removeGiftCardSaga,
  removeItemFromCartSaga,
  removePromoCodeSaga,
  updateCartItemsSaga,
  updateItemQuantitySaga,
];

export default cartSagas;
