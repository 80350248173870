import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import CancelIcon from '@mui/icons-material/Cancel';


const FailureBadge = ({ className }) => (
  <div className={className}>
    <CancelIcon fontSize="inherit" />
  </div>
);

FailureBadge.propTypes = {
  className: PropTypes.string.isRequired,
};


const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1.5rem',
    width: 48,
    height: 48,
    color: theme.palette.error.main,
    fontSize: '3.6rem',
  },
});

const FailureBadge2 = withStyles(FailureBadge, styles);


export default FailureBadge2;
