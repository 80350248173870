import { DateTime } from 'luxon';
import { uniq } from 'lodash';

import { dashJoin } from 'service/utility';
import { leftPadZero } from 'service/utility/numbers';


export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;

export const EMPTY_ISO8601_DATE = '0000-00-00';


export const getLuxon = (iso8601String, timeZone) => {
  const dt1 = iso8601String ? DateTime.fromISO(iso8601String) : DateTime.local();
  const dt2 = timeZone ? dt1.setZone(timeZone) : dt1;

  return dt2;
};


// returns a mask for the Short Date format, for example ##/##/####
export const getShortDateFormat = () => (
  DateTime.fromISO('1999-12-31').toLocaleString(DateTime.DATE_SHORT).replace(/\d/g, '#')
);

// returns an empty Short Date where digits are replaced with hyphens, for example --/--/----
export const getShortDateEmpty = () => (
  DateTime.fromISO('1999-12-31').toLocaleString(DateTime.DATE_SHORT).replace(/\d/g, '-')
);

// returns a placeholder for the Short Date format, for example MM/DD/YYYY
export const getShortDatePlaceholder = () => (
  DateTime.fromISO('1999-12-31')
    .toLocaleString(DateTime.DATE_SHORT)
    .replace(/1999/, 'YYYY')
    .replace(/12/, 'MM')
    .replace(/31/, 'DD')
);

// returns a special format for the Short Date format, to be used with react-datepicker,
// for example MM/dd/yyyy
export const getShortDateDatePickerFormat = () => (
  DateTime.fromISO('1999-12-31')
    .toLocaleString(DateTime.DATE_SHORT)
    .replace(/1999/, 'yyyy')
    .replace(/12/, 'MM')
    .replace(/31/, 'dd')
);

// returns a mask for the Short Date format, to be used with react-number-format,
// for example ["M", "M", "D", "D", "Y", "Y", "Y", "Y"]
export const getShortDateMask = () => (
  getShortDatePlaceholder().replace(/[^YMD]/g, '').split('')
);

// returns an array with the order of Y/M/D based on the Short Date format,
// to be used with DateComponent, for example ["M", "D", "Y"]
export const getShortDateYMD = () => (
  uniq(getShortDatePlaceholder().replace(/[^YMD]/g, '').split(''))
);


export const iso8601Date = (iso8601String) => iso8601String.substring(0, 10);

export const iso8601DateTime = (iso8601String) => iso8601String.substring(0, 19);

export const iso8601Year = (iso8601String) => iso8601String.substring(0, 4);

export const iso8601Month = (iso8601String) => iso8601String.substring(5, 7);

export const iso8601Day = (iso8601String) => iso8601String.substring(8, 10);

export const isIso8601Date = (iso8601String) => (
  typeof iso8601String === 'string' && (/^\d{4}-\d{2}-\d{2}$/).test(iso8601String)
);

export const isEmptyIso8601Date = (iso8601String) => (
  !iso8601String || iso8601Date(iso8601String) === EMPTY_ISO8601_DATE
);

export const isEmptyOrIncompleteIso8601Date = (iso8601String) => (
  !iso8601String || (
    typeof iso8601String === 'string' &&
    (/^[ \d]{4}-[ \d]{2}-[ \d]{2}$/).test(iso8601String) &&
    (/ /).test(iso8601String)
  )
);

export const isValidIso8601Date = (iso8601String) => (
  typeof iso8601String === 'string' && iso8601String.length === 10 && DateTime.fromISO(iso8601String).isValid
);

export const formatIso8601Date = (iso8601String, timeZone) => (
  iso8601Date(DateTime.fromISO(iso8601String).setZone(timeZone).toISO())
);

export const iso8601Now = () => (
  DateTime.local().toISO()
);

export const iso8601Today = (timeZone) => (
  iso8601Date(DateTime.local().setZone(timeZone).toISO())
);

export const iso8601CurrentYear = () => (
  iso8601Year(iso8601Now())
);

export const iso8601BodInUTC = (iso8601Date_, timeZone) => (
  DateTime.fromISO(iso8601Date_, { zone: timeZone }).startOf('day').setZone('UTC').toISO()
);

export const iso8601EodInUTC = (iso8601Date_, timeZone) => (
  DateTime.fromISO(iso8601Date_, { zone: timeZone }).endOf('day').setZone('UTC').toISO()
);

export const iso8601BomInUTC = (iso8601Date_, timeZone) => (
  DateTime.fromISO(iso8601Date_, { zone: timeZone }).startOf('month').setZone('UTC').toISO()
);

export const iso8601EomInUTC = (iso8601Date_, timeZone) => (
  DateTime.fromISO(iso8601Date_, { zone: timeZone }).endOf('month').setZone('UTC').toISO()
);

export const iso8601AddDays = (iso8601Date_, days) => (
  iso8601Date(DateTime.fromISO(iso8601Date_).plus({ days }).toISO())
);

export const iso8601AddWeeks = (iso8601Date_, weeks) => (
  iso8601Date(DateTime.fromISO(iso8601Date_).plus({ weeks }).toISO())
);

export const iso8601AddMonths = (iso8601Date_, months) => (
  iso8601Date(DateTime.fromISO(iso8601Date_).plus({ months }).toISO())
);

// age = the difference in years between a YYYY-MM-DD date and today
export const age = (iso8601Date_) => (
  Math.floor(getLuxon(null).diff(getLuxon(iso8601Date_), 'years').years)
);

// By "isSameDate" it is meant "do these two moments fall on the same YYYY-MM-DD date
// for the specified timezone"
export const isSameDate = (iso8601String1, iso8601String2, timeZone) => (
  DateTime.fromISO(iso8601String1).setZone(timeZone).hasSame(
    DateTime.fromISO(iso8601String2).setZone(timeZone),
    'day'
  )
);

// By "isSameMonth" it is meant "do these two moments fall on the same YYYY-MM month
// for the specified timezone"
export const iso8601DatesAreSameMonth = (iso8601Date1, iso8601Date2) => (
  DateTime.fromISO(iso8601Date1).hasSame(
    DateTime.fromISO(iso8601Date2),
    'month',
  )
);

export const isInThePast = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone) < getLuxon(null, timeZone)
);


export const formatShortDate = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString(DateTime.DATE_SHORT)
);

export const formatMedDate = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString(DateTime.DATE_MED)
);

export const formatFullDate = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString(DateTime.DATE_FULL)
);

export const formatHugeDate = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString({ month: 'long', day: 'numeric', weekday: 'long' })
);

export const formatShortDateTime = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString(DateTime.DATETIME_SHORT)
);

export const fullDateInterval = (iso8601String1, iso8601String2, timeZone) => {
  const s1 = formatFullDate(iso8601String1, timeZone);
  const s2 = formatFullDate(iso8601String2, timeZone);

  return dashJoin([s1, s2]);
};

export const formatDay = (iso8601Date_) => (
  DateTime.fromISO(iso8601Date_).toLocaleString({ day: 'numeric' })
);

export const formatDayMonth = (iso8601Date_) => (
  DateTime.fromISO(iso8601Date_).toLocaleString({ month: 'short', day: 'numeric' })
);

export const formatSimpleTime = (iso8601String, timeZone) => (
  DateTime.fromISO(iso8601String).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)
);

export const timeInterval = (iso8601String1, iso8601String2, timeZone) => {
  const s1 = formatSimpleTime(iso8601String1, timeZone);
  const s2 = formatSimpleTime(iso8601String2, timeZone);

  return dashJoin([s1, s2]);
};

// formats an ISO8601 string as a full datetime, e.g. "October 14, 1983, 1:30 PM"
export const formatFullDateTime = (iso8601String, timeZone) => {
  const { timeZoneName, ...DATETIME_FULL_NO_OFFSET } = DateTime.DATETIME_FULL;

  return getLuxon(iso8601String, timeZone).toLocaleString(DATETIME_FULL_NO_OFFSET);
};

export const fullDateTimeInterval = (iso8601String1, iso8601String2, timeZone) => {
  const s1 = formatFullDateTime(iso8601String1, timeZone);
  const s2 = formatFullDateTime(iso8601String2, timeZone);

  return dashJoin([s1, s2]);
};


// takes a Date() and returns the YYYY-MM-DD part of it.
// No timezone, used for DatePickers that work with a Date()
export const dateYearMonthDay = (date) => (
  `${date.getFullYear()}-${leftPadZero(date.getMonth() + 1, 2)}-${leftPadZero(date.getDate(), 2)}`
);

export const buildIso8601Date = (day, month, year) => (
  [
    year === null ? '    ' : leftPadZero(year, 4),
    month === null ? '  ' : leftPadZero(month, 2),
    day === null ? '  ' : leftPadZero(day, 2),
  ].join('-')
);

export const addHoursAndMinutes = (iso8601String, hours, minutes) => (
  DateTime.fromISO(iso8601String).plus({ hours, minutes }).toISO()
);

// Takes a YYYY-MM-DD Date string and computes a Date() in the local timezone at midnight
export const localDateFromIso8601Date = (iso8601String) => (
  DateTime.fromISO(iso8601Date(iso8601String)).toJSDate()
);

// Takes a YYYY-MM-DDTHH:mm:ss DateTime string and computes a Date() in the local timezone
export const localDateFromIso8601DateTime = (iso8601String) => (
  DateTime.fromISO(iso8601DateTime(iso8601String)).toJSDate()
);

export const getTimestamp = (iso8601String) => (
  DateTime.fromISO(iso8601String).valueOf()
);
