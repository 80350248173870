import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from 'tss-react/mui';
import { Dialog, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import LOADER from 'assets/loader.gif';


const SignWaiverDialog = ({ classes, onClose, waiver }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      scroll="paper"
      classes={{ paper: classes.dialogPaper }}
      onClose={onClose}
    >
      <div className="w-100 p-3 d-flex flex-row justify-content-end">
        <Button
          onClick={onClose}
          endIcon={<CloseIcon />}
        >
          {t('common.Close')}
        </Button>
      </div>
      <div className={classes.iframeContainer}>
        <iframe
          src={waiver}
          frameBorder="0"
          width="100%"
          height="100%"
          title="Waiver Document"
        />
      </div>
    </Dialog>
  );
};

SignWaiverDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  waiver: PropTypes.string.isRequired,
};


const styles = {
  dialogPaper: {
    height: 'calc(100% - 64px)',
    margin: '32px auto',
    display: 'flex',
    flexDirection: 'column',
  },
  iframeContainer: {
    flex: 1,
    backgroundImage: `url(${LOADER})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
};

const SignWaiverDialog2 = withStyles(SignWaiverDialog, styles);


export default SignWaiverDialog2;
