import { call, select, take } from 'redux-saga/effects';

import { updateCartItems } from './cartSagas';
import { UPDATE_CART_ITEMS } from '../actions';
import { cartIdSelector } from '../selectors';


export function *updateCartItemsSaga() {
  while (true) {
    const { items, silent } = yield take(UPDATE_CART_ITEMS);
    const cartId = yield select(cartIdSelector);

    if (!cartId || !items) return null;

    yield call(updateCartItems, cartId, items, silent);
  }
}
