export const account = (state) => state.app.account;
export const confirmActionDialog = (state) => state.app.confirmActionDialog;
export const confirmProfileDialog = (state) => state.app.confirmProfileDialog;
export const customer = (state) => state.app.customer;
export const customerUUIDSelector = (state) => state.app.customer?.UUID ?? null;
export const font = (state) => state.app.font;
export const household = (state) => state.app.household;
export const landingPage = (state) => state.app.landingPage;
export const lang = (state) => state.app.lang;
export const loadingCustomer = (state) => state.app.loadingCustomer;
export const loadingLocationsFinished = (state) => state.app.loadingLocationsFinished;
export const location = (state) => state.app.location;
export const locations = (state) => state.app.locations;
export const purchaseDialog = (state) => state.app.purchaseDialog;
export const region = (state) => state.app.region;
export const serviceWorker = (state) => state.app.serviceWorker;
export const submittingProfile = (state) => state.app.submittingProfile;
export const submittingPurchase = (state) => state.app.submittingPurchase;
export const settingsSelector = (state) => state.app.settings || state.app.account;
