export const HOME = '/';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const RESET_PASSWORD = '/reset-password';
export const CREATE_PROFILE = '/create-profile';
export const CART = '/cart';
export const PROFILE = '/profile';
export const CONTACT = `${PROFILE}/contact`;
export const SIGN_WAIVER = `${PROFILE}/sign-waiver`;
export const SUPPORT = `${PROFILE}/support`;
export const HOUSEHOLD = `${PROFILE}/household`;
export const MEMBERSHIPS = `${PROFILE}/memberships`;
export const PASSES = `${PROFILE}/passes`;
export const RESERVATIONS = `${PROFILE}/reservations`;
export const MY_GIFT_CARDS = `${PROFILE}/giftcards`;
export const BILLING_METHOD = `${PROFILE}/billing-method`;
export const POINTS = `${PROFILE}/points`;
export const SCHEDULE = '/schedule';
export const GIFT_CARDS = '/gift-cards';
export const GIFT_CARD_TYPES = '/gift-card-types';
export const GIFT_CARD_TYPE_DETAIL = '/gift-card-type/:giftCardTypeId';
export const PARTIES = '/parties';
export const CHECKOUT = '/checkout';
export const THANK_YOU = '/thank-you';
export const EVENT_DETAIL = '/event/:eventId';
export const BOOKING_DETAIL = `${EVENT_DETAIL}/booking/:bookingId`;
export const BOOKING_DETAIL_RESERVATIONS = `${BOOKING_DETAIL}/reservations`;
export const BOOKING_DETAIL_PURCHASE = `${BOOKING_DETAIL}/purchase`;
export const MEMBERSHIP_TYPES = '/membership-types';
export const MEMBERSHIP_TYPE_DETAIL = '/membership-type/:membershipTypeId';
export const PASS_TYPES = '/pass-types';
export const PASS_TYPE_DETAIL = '/pass-type/:passTypeId';
export const ME = '/me';
export const QRCODE = '/qrcode';
export const SURVEY = '/survey';
export const SIGN_DOCUMENT = '/sign-document';
export const MANAGE_SUBSCRIPTION = '/manage-subscription';
