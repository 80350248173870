import { all, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { v1 as uuidv1 } from 'uuid';

import { authUser as authUserSelector } from 'app/auth/redux/selectors';
import { setLocation } from 'app/redux/actions';
import {
  account as accountSelector,
  customer as customerSelector,
  location as locationSelector,
} from 'app/redux/selectors';
import i18n from 'config/i18n';
import { cartIdProp } from 'config/models';
import * as API from 'service/api';
import * as GTM from 'service/googleTagManager';
import { getErrorMessage, getRandomUInt16 } from 'service/utility';
import { getPriceProp, getSignupFeeProp } from 'service/utility/pricing';
import { getPayloadPriceField, getDeposit } from 'service/utility/pricingTier';

import { setCart, setCartLoading, setInitialAddToCart, setInitialAddToCartFinished } from '../actions';
import { cartSelector, cartIdSelector, cart2ItemsSelector } from '../selectors';


// If the current cart is not assigned to a customer,
// assign it to the current user and return the new cart.
// Otherwise, just return cart.
export function *assignCart(cart) {
  try {
    const user = yield select(authUserSelector);
    const customer = yield select(customerSelector);

    if (user && !cart.customerId && customer.source) {
      console.log('*assignCart; Cart is unassigned; cart = ', cart);
      const cartId = cart[cartIdProp];

      try {
        console.log(`Assigning cart with ${cartIdProp} = ${cartId} to the current user`);
        yield call(API.assignCart, cartId);

        console.log('Reloading cart');
        const { data: newCart } = yield call(API.getCartById, cartId);

        console.log('Retrieved the following cart:', newCart);

        return newCart;
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        console.log('API.assignCart error: ');
        console.log(errorMessage);

        toast.error(errorMessage);

        return cart;
      }
    }

    return cart;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('API.getCart error: ');
    console.log(errorMessage);

    toast.error(errorMessage);

    return cart;
  }
}

// Loads a cart with a UUID = {{cartId}} and sets it as the current cart.
export function *loadCart(cartId, setLocationToCarts) {
  console.log(`*loadCart; Loading cart with ${cartIdProp} = ${cartId}`);

  try {
    const { data: cart_ } = yield call(API.getCartById, cartId);

    console.log('Retrieved the following cart:', cart_);
    const cart = yield call(assignCart, cart_);

    yield put(setCart(cart));

    if (setLocationToCarts) {
      const { locationId } = cart;

      console.log('Loading location with id = ', locationId);
      const { data: location } = yield call(API.getLocationById, locationId);

      yield put(setLocation(location));
    }

    return cart;
  } catch (error) {
    if (error.response.status === 404) {
      // For whatever reason, the cart with that id doesn't exist anymore.
      yield put(setCart(null));
    } else {
      const errorMessage = getErrorMessage(error);

      console.log('API.getCart error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    }

    return null;
  }
}

// Creates a cart for a certain location.
export function *createCart(locationId) {
  try {
    const payload = {
      locationId,
      source: 'PORTAL',
    };

    console.log(`Creating cart at location with id = ${locationId}`);

    const { data: cart } = yield call(API.putCart, payload);
    const cartId = cart[cartIdProp];

    console.log(`Created the following cart with ${cartIdProp} = ${cartId}`);
    console.log(cart);

    return cart;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('API.putCart error: ');
    console.log(errorMessage);

    toast.error(errorMessage);

    return null;
  }
}

// Creates a new cart for the current location and sets it as the current cart.
export function *createNewCart() {
  const currentLocation = yield select(locationSelector);
  const currentLocationId = currentLocation ? currentLocation.id : null;

  if (!currentLocationId) {
    return null;
  }

  console.log(`Creating new cart at location with id = ${currentLocationId}`);
  const toast1 = toast('Creating cart...');

  try {
    const newCart = yield call(createCart, currentLocationId);

    if (!newCart) return null;

    const assignedCart = yield call(assignCart, newCart);

    yield put(setCart(assignedCart));
    toast.dismiss(toast1);

    return assignedCart;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('createCartSaga error: ');
    console.log(errorMessage);

    toast.dismiss(toast1);
    toast.error(errorMessage);

    return null;
  }
}

// Removes an item from the cart.
export function *removeItemsFromCart(cartId, itemUUIDsToRemove) {
  console.log(`Removing the items with the following UUIDs from the cart with ${cartIdProp} = ${cartId}`);
  console.log(itemUUIDsToRemove);

  const toast1 = toast('Removing item(s) from cart...');

  try {
    const cart = yield select(cartSelector);
    const items = cart2ItemsSelector(cart);
    for (let i = 0, l = itemUUIDsToRemove.length; i < l; i += 1) {
      const itemUUID = itemUUIDsToRemove[i];
      const idx = items.findIndex((e) => e.UUID === itemUUID);
      if (idx > -1) {
        items.splice(idx, 1);
      }
    }
    yield put(setCart(cart));

    yield put(setCartLoading(true));
    yield all(itemUUIDsToRemove.map((itemId) => call(API.removeCartItem, cartId, itemId)));
    yield call(loadCart, cartId);

    toast.dismiss(toast1);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('*removeItemsFromCart error: ');
    console.log(errorMessage);

    toast.dismiss(toast1);
    toast.error(errorMessage);
  } finally {
    yield put(setCartLoading(false));
  }
}

// Removes an item from the cart.
export function *updateCartItems(cartId, items, silent = false) {
  console.log(`Updating the following items in the cart with ${cartIdProp} = ${cartId}`);
  console.log(items);

  try {
    if (!silent) {
      yield put(setCartLoading(true));
    }
    //
    // const cart = yield select(cartSelector);
    // const items2 = cart2ItemsSelector(cart);
    // for (let i = 0, l = items.length; i < l; i += 1) {
    //   const item = items[i];
    //   const idx = items2.findIndex((e) => e.UUID === item.UUID);
    //   if (idx > -1) {
    //     items2[idx] = item;
    //   }
    // }
    // yield put(setCart(cart));
    //
    yield all(items.map((item) => call(API.updateCartItem, cartId, item.UUID, item)));
    yield call(loadCart, cartId);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('*removeItemsFromCart error: ');
    console.log(errorMessage);

    toast.error(errorMessage);
  } finally {
    if (!silent) {
      yield put(setCartLoading(false));
    }
  }
}

export function *createCartSession(cart) {
  try {
    const payload = {
      cartUUID: cart[cartIdProp],
      sessionId: `guest_${getRandomUInt16()}:${uuidv1()}`,
    };

    console.log('*createCartSession: creating session for cart; payload:');
    console.log(payload);

    const { data } = yield call(API.createCartSession, payload);
    const { sessionId, sessionStart, sessionEnd, ipAddress } = data;
    const updatedCart = {
      ...cart,
      sessionId,
      sessionStart,
      sessionEnd,
      ipAddress,
    };

    console.log('*createCartSession: updated cart with session information:');
    console.log(updatedCart);

    yield put(setCart(updatedCart));
    return updatedCart;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('API.createCartSession error: ');
    console.log(errorMessage);

    toast.error(errorMessage);
    return null;
  }
}

export function *createNewCartAndSession() {
  console.log('*createNewCartAndSession');

  const cart = yield call(createNewCart);
  const updatedCart = yield call(createCartSession, cart);

  return updatedCart;
}

export function *putItemsInCart(cartId, items) {
  console.log(`Adding the following items to the cart with ${cartIdProp} = ${cartId} :`);
  console.log(items);

  const account = yield select(accountSelector);
  const currentLocation = yield select(locationSelector);
  try {
    const { data: recentlyAddedItems } = yield call(API.addCartItems, cartId, items);
    const recentlyAddedUUIDs = recentlyAddedItems.map((e) => e.UUID);

    const { totals: { items: cartItems } } = yield call(loadCart, cartId);
    const cartItems2 = cartItems.filter(
      (cartItem) => recentlyAddedUUIDs.includes(cartItem.UUID)
    );

    GTM.addAddToCartTag(cartItems2, currentLocation, account);

    return true;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    console.log('putItemsInCartSaga error: ');
    console.log(errorMessage);

    toast.error(errorMessage);
    return false;
  }
}

/* eslint-disable no-param-reassign */
export function *getCustomerCartAtLocation(locationId, aaPayload) {
  console.log(`*getCustomerCartAtLocation; locationId = ${locationId}`);

  if (aaPayload) {
    yield put(setInitialAddToCart(true));
  }

  const account = yield select(accountSelector);
  const customer = yield select(customerSelector);
  let cart;

  if (customer.source) {
    // Existing customer
    console.log(`*getCustomerCartAtLocation; existing customer with id = ${customer.id}`);

    try {
      yield put(setCartLoading(true));
      // Look for existing cart at that location.
      ({ data: cart } = yield call(API.getCustomerCartAtLocation, customer.id, locationId));

      // Cart found.
      console.log('*getCustomerCartAtLocation; cart found:', cart);
      yield put(setCart(cart));
    } catch (error) {
      if (error.response.status === 404) {
        // No cart for that customer at that location. Create a new one.
        cart = yield call(createNewCart);
      } else {
        const errorMessage = getErrorMessage(error);

        console.log('API.getCustomerCartAtLocation error: ');
        console.log(errorMessage);

        toast.error(errorMessage);
      }
    } finally {
      yield put(setCartLoading(false));
    }
  } else {
    // Guest
    console.log('*getCustomerCartAtLocation; customer is a guest');

    try {
      yield put(setCartLoading(true));
      const cartId = yield select(cartIdSelector);
      cart = yield select(cartSelector);

      // If there is no current cartId, it's probably because one hasn't been created yet.
      // We create one. This should be enough.
      if (!cartId) {
        cart = yield call(createNewCart);
      } else {
        // There is a cartId; check if the cart has been loaded
        // eslint-disable-next-line no-lonely-if
        if (!cart) {
          // cartId only existed in local storage; load the cart with that id
          cart = yield call(loadCart, cartId);

          if (!cart) {
            // For whatever reason, the cart with that id doesn't exist anymore; create a new one
            cart = yield call(createNewCart);
          }
        }
        // At this point there should be a cart.
        // Check to see if the current cart is valid for the current location
        // eslint-disable-next-line no-lonely-if
        if (cart.locationId !== locationId) {
          // The location has changed; the cart is no longer valid.
          // Create a new session and cart;
          cart = yield call(createNewCartAndSession);
        }
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('*getCustomerCartAtLocation error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      yield put(setCartLoading(false));
    }
  }

  if (aaPayload) {
    const {
      itemType, ticketTypeId, eventId, bookingId, pricingTierId, startDT, membershipTypeId, passTypeId,
    } = aaPayload;
    const items = [];

    if (itemType === 'EVENT_BOOKING_TICKET') {
      const item = {
        type: itemType,
        quantity: 1,
        ticketTypeId,
        bookingId,
      };

      if (customer.id) {
        item.assignedCustomerId = customer.id;
      }

      items.push(item);
    }

    if (itemType === 'EVENT_SERIES_TICKET') {
      const item = {
        type: itemType,
        quantity: 1,
        ticketTypeId,
        eventId,
      };

      if (customer.id) {
        item.assignedCustomerId = customer.id;
      }

      items.push(item);
    }

    if (itemType === 'EVENT_BOOKING_INVOICE') {
      const { data: pricingTier } = yield call(API.getPricingTierById, pricingTierId);

      const payloadPriceField = getPayloadPriceField(pricingTier);
      const deposit = getDeposit(pricingTier);

      const item = {
        type: itemType,
        [payloadPriceField]: deposit,
        pricingTierId,
      };

      if (bookingId) {
        item.bookingId = bookingId;
      } else {
        item.eventId = eventId;
        item.startDT = startDT;
      }

      items.push(item);
    }

    if (itemType === 'MEMBERSHIP') {
      const { data: membershipType } = yield call(API.getMembershipTypeById, membershipTypeId);

      const item = {
        type: itemType,
        membershipTypeId,
        quantity: 1,
      };

      if (customer.id) {
        item.assignedCustomerId = customer.id;
      }

      if (bookingId) {
        item.bookingId = bookingId;
        item.reserveAfterPurchase = true;
      }

      if (membershipType[getSignupFeeProp(account)]) {
        const relatedUUID = uuidv1();

        item.relatedUUID = relatedUUID;

        const feeOrderItem = {
          type: 'FEE',
          [getPriceProp(account)]: membershipType[getSignupFeeProp(account)],
          description: i18n.t('common.MembershipSignupFee'),
          relatedUUID,
          membershipTypeId,
        };

        if (membershipType.isTaxExempt) {
          feeOrderItem.isTaxExempt = true;
        }

        items.push(item);
        items.push(feeOrderItem);
      } else {
        items.push(item);
      }
    }

    if (itemType === 'PASS') {
      const item = {
        type: itemType,
        passTypeId,
        quantity: 1,
      };

      if (customer.id) {
        item.assignedCustomerId = customer.id;
      }

      if (bookingId) {
        item.bookingId = bookingId;
        item.reserveAfterPurchase = true;
      }

      items.push(item);
    }

    yield call(putItemsInCart, cart[cartIdProp], items);
    yield put(setInitialAddToCartFinished(true));
  }
}
/* eslint-enable no-param-reassign */
