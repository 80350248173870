import { createReducer } from 'service/utility';

import { SET_CONFIRM_PROFILE_DIALOG } from '../actions';

const initialState = {
  isOpen: false,
  customer: null,
};

export const confirmProfileDialog = createReducer(initialState, {
  [SET_CONFIRM_PROFILE_DIALOG]: (state, { isOpen, customer }) => ({
    isOpen,
    customer,
  }),
});
