import React from 'react';
import { PropTypes } from 'prop-types';
import { Radio, ListItem } from '@mui/material';

import LocationDetails from 'common/LocationDetails';


class Location extends React.Component {
  handleSelect = () => {
    const { onSelect, location } = this.props;

    onSelect(location.id);
  };

  render() {
    const { divider, location, selected } = this.props;

    return (
      <ListItem
        button
        divider={divider}
        onClick={this.handleSelect}
      >
        <LocationDetails location={location} />
        <Radio
          checked={selected}
          onChange={this.handleSelect}
          value={location.id}
        />
      </ListItem>
    );
  }
}

Location.propTypes = {
  divider: PropTypes.bool,
  location: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};


export default Location;
