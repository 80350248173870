import { changeLocationSaga, changeLocationByIdSaga } from './changeLocationSaga';
import { confirmActionSaga } from './confirmActionSaga';
import { confirmProfileSaga } from './confirmProfileSaga';
import { purchaseSaga } from './purchaseSaga';

const appSagas = [
  changeLocationSaga,
  changeLocationByIdSaga,
  confirmActionSaga,
  confirmProfileSaga,
  purchaseSaga,
];

export default appSagas;
