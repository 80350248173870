import { combineReducers } from 'redux';

import cart from './cart';
import cartId from './cartId';
import initialAddToCart from './initialAddToCart';
import initialAddToCartFinished from './initialAddToCartFinished';
import loading from './loading';
import paymentStatus from './paymentStatus';
import temporaryCustomer from './temporaryCustomer';


const cartReducer = combineReducers({
  cart,
  cartId,
  initialAddToCart,
  initialAddToCartFinished,
  loading,
  paymentStatus,
  temporaryCustomer,
});

export default cartReducer;
