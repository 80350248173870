import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ordinal } from 'service/utility/numbers';

import dictionary from './dictionary.json';


export const SUPPORTED_LOCALES = ['en-US', 'de-DE', 'fr-CA', 'es-ES'];
export const DEFAULT_LOCALE = 'en-US';

i18n
  .use(initReactI18next)
  .init({
    resources: dictionary,
    lng: SUPPORTED_LOCALES,
    fallbackLng: DEFAULT_LOCALE,
    interpolation: {
      escapeValue: false,
      // eslint-disable-next-line no-unused-vars
      format: (value, format, lng) => {
        if (format === 'ordinal_en') {
          if (value === -1) {
            return 'last';
          }

          return ordinal(value);
        }

        if (format === 'ordinal_de') {
          if (value === -1) {
            return 'letzten';
          }

          return `${value}.`;
        }

        if (format === 'ordinal_fr') {
          if (value === -1) {
            return 'dernier';
          }

          return `${value}ième`;
        }

        if (format === 'ordinal_es') {
          if (value === -1) {
            return 'último';
          }

          return `${value}`;
        }

        return value;
      },
    },
  });

export default i18n;
