import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, Button } from '@mui/material';

import {
  actionCancelled as actionCancelledAction,
  actionConfirmed as actionConfirmedAction,
  actionDenied as actionDeniedAction,
} from 'app/redux/actions';
import { confirmActionDialog as confirmActionDialogSelector } from 'app/redux/selectors';


const ConfirmActionDialog = (props) => {
  const { t } = useTranslation();

  const { confirmActionDialog, actionConfirmed, actionDenied, actionCancelled } = props;
  const {
    isOpen, title, message, cancelLabel, confirmLabel, denyLabel, hideConfirm, hideDeny, hideCancel,
  } = confirmActionDialog;

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="confirm"
      onClose={actionCancelled}
    >
      <DialogTitle>
        {title || t('common.AreYouSure')}
      </DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {!hideConfirm && (
          <Button
            color="primary"
            onClick={actionConfirmed}
          >
            {confirmLabel || t('common.Yes')}
          </Button>
        )}
        {!hideDeny && (
          <Button
            color="secondary"
            onClick={actionDenied}
          >
            {denyLabel || t('common.No')}
          </Button>
        )}
        {!hideCancel && (
          <Button onClick={actionCancelled}>
            {cancelLabel || t('common.Cancel')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmActionDialog.propTypes = {
  actionCancelled: PropTypes.func.isRequired,
  actionConfirmed: PropTypes.func.isRequired,
  actionDenied: PropTypes.func.isRequired,
  confirmActionDialog: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  confirmActionDialog: confirmActionDialogSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionCancelled: () => dispatch(actionCancelledAction()),
  actionConfirmed: () => dispatch(actionConfirmedAction()),
  actionDenied: () => dispatch(actionDeniedAction()),
});

const ConfirmActionDialog2 = connect(mapStateToProps, mapDispatchToProps)(ConfirmActionDialog);


export default ConfirmActionDialog2;
