import { put, take, call, race, select } from 'redux-saga/effects';

import { cartSelector } from 'app/cart/redux/selectors';
// import { isAssignableItemType } from 'config/itemTypes';

import {
  CONFIRM_PURCHASE, PURCHASE_CONFIRMED, PURCHASE_CANCELLED,
  setPurchaseDialog, setPurchaseSubmitting,
} from '../actions';
import { customer as customerSelector } from '../selectors';
import { changeLocation } from './changeLocationSaga';


function *awaitConfirmation(callback) {
  const { choices } = yield take(PURCHASE_CONFIRMED);
  const { selectedCustomerId, selectedLocation } = choices;

  yield put(setPurchaseSubmitting(true));
  const cart = yield select(cartSelector);
  const cartLocationId = cart ? cart.locationId : null;

  if (selectedLocation.id !== cartLocationId) {
    yield call(changeLocation, selectedLocation);
  }

  yield call(callback, selectedCustomerId);
  yield put(setPurchaseSubmitting(false));
}

export function *purchaseSaga() {
  while (true) {
    const { callback, options } = yield take(CONFIRM_PURCHASE);
    const { /*itemType, */validLocations } = options;

    const customer = yield select(customerSelector);
    const customerId = customer.id || null;

    const cart = yield select(cartSelector);
    const cartLocationId = cart ? cart.locationId : null;

    if (
      // (isAssignableItemType(itemType) && Boolean(customerId)) ||
      (validLocations.length > 1 && cartLocationId === null) ||
      (validLocations.length === 1 && cartLocationId !== null && validLocations[0].id !== cartLocationId)
    ) {
      try {
        yield put(setPurchaseDialog(true, options));

        if (callback) {
          yield race({
            awaitConfirmation: call(awaitConfirmation, callback),
            cancel: take(PURCHASE_CANCELLED),
          });
        } else {
          yield take(PURCHASE_CANCELLED);
        }
        yield put(setPurchaseDialog(false));
      } catch (error) {
        console.log('purchaseSaga error: ', error);
      }
    } else if (callback) {
      yield call(callback, customerId);
    }
  }
}
