import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog, DialogContent } from '@mui/material';

import { location as locationSelector } from 'app/redux/selectors';
import { SuspenseFallback, Spinner } from 'common/statusIndicators';
import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';


const EmergePayForm = React.lazy(() => import('./EmergePayForm'));
const ClearentForm = React.lazy(() => import('./ClearentForm'));
const StripeForm = React.lazy(() => import('./StripeForm'));
const AdyenForm = React.lazy(() => import('./AdyenForm'));
const FullSteamForm = React.lazy(() => import('./FullSteamForm'));


class AddCardDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      merchantLoading: true,
      merchant: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.loadMerchant();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentLocation.id !== this.props.currentLocation.id) {
      this.loadMerchant();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadMerchant = async () => {
    const { currentLocation } = this.props;

    try {
      console.log('AddCardDialog: querying merchant and merchant-related information');
      const { data } = await API.getMerchantPrivateKey(currentLocation.id);

      console.log('AddCardDialog: received the following merchant and merchant-related information:');
      console.log(data);

      const {
        merchant, privatekey, token, publicKey, clientId, clientKey,
        paymentMethods, public: publicInfo, authkey,
      } = data;

      if (this._isMounted) {
        this.setState({
          merchant,
          token,
          privatekey,
          publicKey,
          clientId,
          clientKey,
          paymentMethods,
          publicInfo,
          authkey,
          merchantLoading: false,
        });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('getMerchantPrivateKey error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      if (this._isMounted) {
        this.setState({
          merchantLoading: false,
          merchant: null,
        });
      }
    }
  };

  saveCard = async (merchantPayload) => {
    const { currentLocation } = this.props;

    try {
      const payload = {
        locationId: currentLocation.id,
        ...merchantPayload,
        isPrimary: 1,
      };

      console.log('Payment: saving new card; payload: ', payload);
      const { data: savedCreditCard } = await API.putPaymentCard(payload);

      console.log('Payment: card saved successfully');
      console.log(savedCreditCard);

      return savedCreditCard;
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('putPaymentCard error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      return null;
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'backdropClick') return;

    this.props.onClose();
  };

  render() {
    const { open, onClose, onCloseAndReload } = this.props;
    const {
      merchantLoading, merchant, token, privatekey, publicKey, clientId,
      clientKey, paymentMethods, publicInfo, authkey,
    } = this.state;

    return (
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={this.handleClose}
      >
        <DialogContent>
          {merchantLoading ? (
            <Spinner color="text" />
          ) : merchant === 'emergepay' ? (
            <React.Suspense fallback={<SuspenseFallback />}>
              <EmergePayForm
                token={token}
                saveCard={this.saveCard}
                closeAndReload={onCloseAndReload}
                onCancel={onClose}
              />
            </React.Suspense>
          ) : merchant === 'clearent' ? (
            <React.Suspense fallback={<SuspenseFallback />}>
              <ClearentForm
                privatekey={privatekey}
                saveCard={this.saveCard}
                closeAndReload={onCloseAndReload}
                onCancel={onClose}
              />
            </React.Suspense>
          ) : merchant === 'stripe' ? (
            <React.Suspense fallback={<SuspenseFallback />}>
              <StripeForm
                publicKey={publicKey}
                clientId={clientId}
                saveCard={this.saveCard}
                closeAndReload={onCloseAndReload}
                onCancel={onClose}
              />
            </React.Suspense>
          ) : merchant === 'adyen' ? (
            <React.Suspense fallback={<SuspenseFallback />}>
              <AdyenForm
                clientKey={clientKey}
                paymentMethods={paymentMethods}
                publicInfo={publicInfo}
                saveCard={this.saveCard}
                closeAndReload={onCloseAndReload}
                onCancel={onClose}
              />
            </React.Suspense>
          ) : merchant === 'fullsteam' ? (
            <React.Suspense fallback={<SuspenseFallback />}>
              <FullSteamForm
                authkey={authkey}
                saveCard={this.saveCard}
                closeAndReload={onCloseAndReload}
                onCancel={onClose}
              />
            </React.Suspense>
          ) : null}
        </DialogContent>
      </Dialog>
    );
  }
}

AddCardDialog.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseAndReload: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocation: locationSelector(state),
});

const AddCardDialog2 = connect(mapStateToProps)(AddCardDialog);


export default AddCardDialog2;
