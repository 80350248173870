import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { withStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';


const Note = ({ classes, className, text }) => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="body2"
      component="span"
      className={classnames({ [className]: className })}
    >
      <Typography
        variant="body2"
        component="span"
        className={classes.mediumWeight}
      >
        {`${t('common.Note')}: `}
      </Typography>
      {text}
    </Typography>
  );
};

Note.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};


const styles = (theme) => ({
  mediumWeight: {
    fontWeight: theme.typography.fontWeightMedium,
  },
});

const Note2 = withStyles(Note, styles);


export default Note2;
