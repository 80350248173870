import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import classnames from 'classnames';

const ButtonsContainer = ({ children, classes, className, ...rest }) => (
  <div
    className={classnames(classes.buttonsContainer, className)}
    {...rest}
  >
    {children}
  </div>
);

ButtonsContainer.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};


const styles = (theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
});

const ButtonsContainer2 = withStyles(ButtonsContainer, styles);


export default ButtonsContainer2;
