import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  customer as customerSelector,
  household as householdSelector,
} from 'app/redux/selectors';
import { formatName } from 'service/utility/customer';

import PicklistField from './PicklistField';


const AssignedToField = (props) => {
  const { t } = useTranslation();

  const { className, label = t('common.AssignedTo'), customer, household, ...rest } = props;
  const disabled = !customer.source;

  return (
    <PicklistField
      {...rest}
      label={label}
      disabled={disabled}
      picklistValues={household.map((option) => ({ label: formatName(option), value: option.id }))}
      className={classnames('assigned-to-field', { [className]: className })}
    />
  );
};

AssignedToField.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
  household: PropTypes.array.isRequired,
  label: PropTypes.string,
};


const mapStateToProps = (state) => ({
  customer: customerSelector(state),
  household: householdSelector(state),
});

const AssignedToField2 = connect(mapStateToProps, {})(AssignedToField);


export default AssignedToField2;
