import { put, take, call, select, race } from 'redux-saga/effects';

import { getCustomerCartAtLocation } from 'app/cart/redux/sagas';
import { cartSelector } from 'app/cart/redux/selectors';
import i18n from 'config/i18n';
import * as API from 'service/api';

import {
  CHANGE_LOCATION, CHANGE_LOCATION_BY_ID, ACTION_CONFIRMED, ACTION_CANCELLED,
  setConfirmActionDialog, setLocation,
} from '../actions';
import {
  customer as customerSelector,
  location as locationSelector,
} from '../selectors';


export function *changeLocation(location) {
  const oldLocation = yield select(locationSelector);

  console.log('*changeLocation; old location:', oldLocation);
  console.log('*changeLocation; new location:', location);

  const customer = yield select(customerSelector);
  const cart = yield select(cartSelector);
  let itsOkToContinue = true;

  if (!customer.source && Boolean(cart) && cart.locationId !== location.id) {
    // If the location changes, the cart will no longer be valid.
    // Display a confirmation message.
    yield put(setConfirmActionDialog(true, {
      title: `${i18n.t('common.Warning')}:\u00A0`,
      message: i18n.t('common.IfYouChangeYourLocation'),
      confirmLabel: i18n.t('common.OK'),
      confirmCallback: () => {},
    }));

    // eslint-disable-next-line no-unused-vars
    const { confirmed, cancelled } = yield race({
      confirmed: take(ACTION_CONFIRMED),
      cancelled: take(ACTION_CANCELLED),
    });
    yield put(setConfirmActionDialog(false));

    if (cancelled) {
      itsOkToContinue = false;
    }
  }

  if (itsOkToContinue) {
    yield put(setLocation(location));

    if (oldLocation.id !== location.id) {
      yield call(getCustomerCartAtLocation, location.id);
    }
  }
}

export function *changeLocationSaga() {
  while (true) {
    const { location } = yield take(CHANGE_LOCATION);

    console.log('*changeLocationSaga; location = ', location);
    yield call(changeLocation, location);
  }
}

export function *changeLocationByIdSaga() {
  while (true) {
    const { locationId } = yield take(CHANGE_LOCATION_BY_ID);

    console.log(`*changeLocationByIdSaga; locationId = ${locationId}`);
    const currentLocation = yield select(locationSelector);

    if (locationId !== currentLocation.id) {
      console.log('Loading location with id = ', locationId);
      const { data: location } = yield call(API.getLocationById, locationId);

      yield call(changeLocation, location);
    }
  }
}
