import * as NAVIGATION from 'config/navigation';

export const endsWithSlash = (pathname) => (/\/$/).test(decodeURIComponent(pathname));

export const isEmbed = (location = window.location) => (/\/embed$/).test(location.pathname);

export const stripEmbed = (pathname) => pathname.replace(/\/embed$/, '');

export const getPathname = (location = window.location) => stripEmbed(location.pathname);

export const isCheckout = (location = window.location) => getPathname(location) === NAVIGATION.CHECKOUT;

export const isNotEmbeddable = (location = window.location) => {
  const pathname = getPathname(location);

  return (
    new RegExp(`^${NAVIGATION.CHECKOUT}\\b`).test(pathname) ||
    new RegExp(`^${NAVIGATION.CART}\\b`).test(pathname) ||
    new RegExp(`^${NAVIGATION.PROFILE}\\b`).test(pathname) ||
    new RegExp(`^${NAVIGATION.ME}\\b`).test(pathname)
  );
};


export const getEmbedURL = (url, withSearch = true) => {
  if (typeof url !== 'string') return null;

  const [pathname, search] = url.split('?');
  const pathname2 = stripEmbed(pathname);
  const url2 = `${pathname2}${endsWithSlash(pathname2) ? '' : '/'}embed${withSearch && search ? `?${search}` : ''}`;

  return url2;
};

export const getNewURL = (url) => {
  if (typeof url !== 'string') return null;

  const [pathname, search] = url.split('?');
  const pathname2 = stripEmbed(pathname);
  const url2 = `${pathname2}${isEmbed(window.location) ? `${endsWithSlash(pathname2) ? '' : '/'}embed` : ''}${search ? `?${search}` : ''}`;

  return url2;
};

export const getReturnToParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const returnTo = urlParams.get('returnTo');

  return returnTo;
};

export const getURLWithReturnTo = (url, returnTo) => (
  `${url}${returnTo ? `?returnTo=${encodeURIComponent(returnTo)}` : ''}`
);

export const getReturnToURL = (url) => {
  const url2 = getNewURL(url);
  const returnTo2 = getReturnToParam() || `${window.location.pathname}${window.location.search}`;

  return getURLWithReturnTo(url2, returnTo2);
};

export const getSignUpInURL = (url) => {
  const url2 = getNewURL(url);
  const currentPathname = getPathname();

  if (
    currentPathname === NAVIGATION.LOGIN ||
    currentPathname === NAVIGATION.SIGNUP ||
    currentPathname === NAVIGATION.RESET_PASSWORD
  ) {
    return getURLWithReturnTo(url2, getReturnToParam());
  }

  return getReturnToURL(url2);
};

export const navigateTo = (history, to, state) => {
  if (state) {
    history.push(getNewURL(to), state);
  } else {
    history.push(getNewURL(to));
  }
};
