import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { location as locationSelector } from 'app/redux/selectors';
import { TIMEZONE_PICKLIST_VALUES } from 'config/timezones';
import { getCurrentLocationTimezone } from 'service/i18n';

import PicklistField from './PicklistField';


const TimezoneField = (props) => {
  const { className, currentLocation, dispatch, ...rest } = props;
  const currentLocationTimezone = getCurrentLocationTimezone(currentLocation);

  if (currentLocationTimezone && !rest.value) {
    props.onChange({ value: currentLocationTimezone });

    return null;
  }

  return (
    <PicklistField
      {...rest}
      picklistValues={TIMEZONE_PICKLIST_VALUES}
      className={classnames('timezone-field', { [className]: className })}
    />
  );
};

TimezoneField.propTypes = {
  className: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

TimezoneField.defaultProps = {
  value: '',
};


const mapStateToProps = (state) => ({
  currentLocation: locationSelector(state),
});

const TimezoneField2 = connect(mapStateToProps)(TimezoneField);


export default TimezoneField2;
