import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';


const ErrorText = ({ children, classes, className, ...rest }) => (
  <Typography
    className={className}
    {...rest}
  >
    {children}
  </Typography>
);

ErrorText.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};


const styles = (theme) => ({
  root: {
    fontStyle: 'italic',
    color: theme.palette.error.main,
  },
});

const ErrorText2 = withStyles(ErrorText, styles);


export default ErrorText2;
