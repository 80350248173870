import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import Color from 'color';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';

import { getPaletteColor } from 'service/utility/styles';


const LinkButton = ({ classes, children, className, color, inParagraph, ...rest }) => (
  <Button
    className={classnames('link-button', className, { [classes.inParagraph]: inParagraph })}
    {...rest}
  >
    {children}
  </Button>
);

LinkButton.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
  inParagraph: PropTypes.bool,
};

LinkButton.defaultProps = {
  color: 'primary',
};


const styles = (theme, props) => {
  const color = getPaletteColor(theme, props.color);

  return ({
    root: {
      color,
      '&:hover': {
        color: Color(color).darken(theme.palette.action.hoverEmphasis).hex(),
      },
    },
    inParagraph: {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      verticalAlign: 'baseline',
    },
  });
};

const LinkButton2 = withStyles(LinkButton, styles);


export default LinkButton2;
