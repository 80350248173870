import { createReducer } from 'service/utility';

import { SET_PURCHASE_DIALOG } from '../actions';

const initialState = {
  isOpen: false,
  options: {
    itemType: '',
    validLocations: [],
  },
};

export const purchaseDialog = createReducer(initialState, {
  [SET_PURCHASE_DIALOG]: (state, { isOpen, options }) => (
    isOpen ? ({
      isOpen,
      options,
    }) : ({
      ...state,
      isOpen,
    })
  ),
});
