import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { TextField } from '@mui/material';
import Portal from '@mui/material/Portal';

import { isRequired } from 'service/utility/errorMessages';
import * as timeUtils from 'service/utility/timeUtils';


const DateFormatCustom = React.forwardRef((props, ref) => {
  const onValueChange = (values) => {
    props.onChange({
      target: {
        value: values.formattedValue,
      },
    });
  };

  const { onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={ref}
      onValueChange={onValueChange}
      decimalScale={0}
      type="tel"
      format={timeUtils.getShortDateFormat()}
      placeholder={timeUtils.getShortDatePlaceholder()}
      mask={timeUtils.getShortDateMask()}
    />
  );
});

DateFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
};

DateFormatCustom.displayName = 'DateFormatCustom';


const DatePickerWrapper = ({ children }) => (
  <Portal>
    <div>
      {children}
    </div>
  </Portal>
);

DatePickerWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
};


const DatePickerContainer = ({ className, children }) => (
  <div className="airbnb-datepicker-container">
    <div className="datepicker-container-inner">
      <div className={className}>
        <div className="position-relative">
          {children}
        </div>
      </div>
    </div>
  </div>
);

DatePickerContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
  className: PropTypes.string,
};


class DateField extends React.Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: stringValue, onChange } = this.props;
    const value = !stringValue || timeUtils.isEmptyIso8601Date(stringValue) ? null : stringValue;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }


  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value && required) return isRequired(label);

    return null;
  };


  handleLeaveFocus = () => {
    const { value: stringValue, onChange } = this.props;
    const value = stringValue || null;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (dateValue) => {
    const { onChange } = this.props;
    const value = dateValue ? timeUtils.dateYearMonthDay(dateValue) : null;
    const error = this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      setTimeout(() => {
        if (this.myRef.current && this.myRef.current.isCalendarOpen()) {
          this.myRef.current.setOpen(false);
        }
      }, 0);
    }
  };


  render() {
    const {
      autoFocus, className, DatePickerProps, disabled, error, fullWidth, label, narrow,
      onChange, required, useAirbnbStyle, value: stringValue, ...rest
    } = this.props;
    const { blurred } = this.state;

    const dateValue = (
      !stringValue || timeUtils.isEmptyIso8601Date(stringValue)
        ? ''
        : timeUtils.localDateFromIso8601DateTime(stringValue)
    );

    return (
      <div className={classnames('date-field', { [className]: className, narrow })}>
        <DatePicker
          ref={this.myRef}
          dateFormat={timeUtils.getShortDateDatePickerFormat()}
          selected={dateValue}
          onChange={(date) => this.handleChangeSelf(date)}
          required={required}
          autoFocus={autoFocus}
          monthsShown={useAirbnbStyle ? 2 : 1}
          dropdownMode="select"
          customInput={
            <TextField
              error={blurred && Boolean(error)}
              helperText={error}
              required={required}
              fullWidth={fullWidth}
              label={label}
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              variant="outlined"
              InputProps={{
                inputComponent: DateFormatCustom,
                onBlur: this.handleLeaveFocus,
                onKeyDown: this.handleKeyDown,
              }}
              {...rest}
            />
          }
          disabled={disabled}
          popperContainer={DatePickerWrapper}
          calendarContainer={useAirbnbStyle ? DatePickerContainer : null}
          {...DatePickerProps}
        />
      </div>
    );
  }
}

DateField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  DatePickerProps: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  narrow: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  useAirbnbStyle: PropTypes.bool,
  value: PropTypes.string,
};

DateField.defaultProps = {
  autoFocus: false,
  DatePickerProps: {},
  error: '',
  fullWidth: false,
  label: '',
  narrow: false,
  required: false,
  useAirbnbStyle: false,
  value: '',
};


export default DateField;
