import React from 'react';
import { PropTypes } from 'prop-types';
import { components } from 'react-select';
import { withStyles } from 'tss-react/mui';


const Control_ = ({ children, classes, ...rest }) => (
  <components.Control
    {...rest}
    className={classes.control}
  >
    {children}
    <fieldset className={classes.fieldset}>
      <legend className={classes.legend}>
        <span className={classes.span}>
          {'State *'}
        </span>
      </legend>
    </fieldset>
  </components.Control>
);

Control_.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
};


const styles = (theme) => ({
  control: {
    borderWidth: '0 !important',
    cursor: 'pointer !important',
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',

    '&:hover > fieldset': {
      borderColor: theme.palette.text.primary,
    },

    '&.react-select__control--is-focused > fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
  fieldset: {
    top: -5,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    padding: '0 8px',
    overflow: 'hidden',
    position: 'absolute',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 'inherit',
    pointerEvents: 'none',
    borderColor: theme.palette.outline,
  },
  legend: {
    width: 'auto',
    height: 11,
    display: 'block',
    padding: 0,
    fontSize: '0.75em',
    textAlign: 'left',
    visibility: 'hidden',
    maxWidth: 1000,
    transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
  },
  span: {
    display: 'inline-block',
    paddingLeft: 5,
    paddingRight: 5,
  },
});

const Control = withStyles(Control_, styles);


export default Control;
