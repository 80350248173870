import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';

import {
  setAuthFinished as setAuthFinishedAction,
  signIn as signInAction,
  signOut as signOutAction,
} from 'app/auth/redux/actions';
import { authFinished as authFinishedSelector } from 'app/auth/redux/selectors';
import {
  getCustomerCartAtLocation as getCustomerCartAtLocationAction,
  loadCart as loadCartAction,
  setInitialAddToCart as setInitialAddToCartAction,
} from 'app/cart/redux/actions';
import {
  initialAddToCartSelector,
  initialAddToCartFinishedSelector,
} from 'app/cart/redux/selectors';
import {
  changeLocation as changeLocationAction,
  setAccount as setAccountAction,
  setLandingPage as setLandingPageAction,
  setLang as setLangAction,
  setLocation as setLocationAction,
  setLocations as setLocationsAction,
} from 'app/redux/actions';
import { ALL_LOCATIONS } from 'app/redux/reducers/location';
import {
  account as accountSelector,
  loadingLocationsFinished as loadingLocationsFinishedSelector,
  location as locationSelector,
  locations as locationsSelector,
} from 'app/redux/selectors';
import { LocationDialog } from 'common/dialogs';
import { isAutoAddToCartItemType } from 'config/itemTypes';
import * as NAVIGATION from 'config/navigation';
import { loadGoogleAnalytics, loadMetaPixel } from 'service/analytics';
import * as API from 'service/api';
import { getNamespace } from 'service/axios';
import { loadGoogleTagManager } from 'service/googleTagManager';
import { isEmbed, navigateTo } from 'service/navigation';
import { getErrorMessage, fieldSort, somePropsChanged, copyProps } from 'service/utility';


class Initializer extends React.Component {
  componentDidMount() {
    if (isEmbed()) {
      this.props.setLandingPage(window.location.pathname);
    }

    this.loadUser();
    this.loadAccount();
    this.parseURLParams();
  }

  componentDidUpdate(prevProps) {
    if (somePropsChanged(prevProps, this.props, ['account', 'authFinished', 'loadingLocationsFinished'])) {
      if (this.props.account && this.props.authFinished && this.props.loadingLocationsFinished) {
        document.getElementById('loader-container').className = 'd-none';
      }
    }

    if (
      this.props.initialAddToCart &&
      !prevProps.initialAddToCartFinished && this.props.initialAddToCartFinished
    ) {
      window.setTimeout(() => {
        navigateTo(this.props.history, NAVIGATION.CART);
        this.props.setInitialAddToCart(false);
      }, 1000);
    }
  }


  loadUser = async () => {
    const { history, signIn, signOut, setAuthFinished } = this.props;

    try {
      console.log('Initializer: getting current authenticated User');
      const user = await Auth.currentAuthenticatedUser();

      console.log('Initializer: current authenticated User: ', user);
      // const { attributes } = user;
      // const user2 = { attributes };
      const user2 = copyProps(user, ['attributes']);

      signIn(user2, history);
    } catch (error) {
      console.log('No authenticated user');

      signOut(true);
      setAuthFinished();
    }
  };

  loadAccount = async () => {
    const { setAccount } = this.props;

    let manifestSet = false;

    try {
      console.log('Initializer: getting Account');
      const { data: account } = await API.getAccount(getNamespace());

      console.log('Initializer: Account: ', account);
      setAccount(account || {});

      if (account?.manifest) {
        const blob = new Blob([account.manifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);

        document.getElementById('portal-manifest').setAttribute('href', manifestURL);
        manifestSet = true;
      }

      if (account?.pwaIcons) {
        const pwaIcons = JSON.parse(account.pwaIcons);
        const faviconURL = pwaIcons.favicon;
        const appleTouchIconURL = pwaIcons.appleTouchIcon;

        document.getElementById('portal-favicon').setAttribute('href', faviconURL);
        document.getElementById('portal-apple-touch-icon').setAttribute('href', appleTouchIconURL);
      }

      if (account?.googleAnalytics?.isActive && account?.googleAnalytics?.id) {
        loadGoogleAnalytics(account.googleAnalytics.id);
      }

      if (account?.googleTagManager?.isActive && account?.googleTagManager?.apiKey) {
        loadGoogleTagManager(account?.googleTagManager?.apiKey);
      }

      if (account?.metaPixel?.isActive && account?.metaPixel?.id) {
        loadMetaPixel(account.metaPixel.id);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.getAccount error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    }

    if (!manifestSet) {
      document.getElementById('portal-manifest').setAttribute('href', `${process.env.PUBLIC_URL}/manifest.json`);
    }
  };

  parseURLParams = () => {
    const { history, loadCart, setLang } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const urlParamMap = Object.fromEntries(urlParams);
    const urlParamsToDelete = [
      'cartId', 'lang', 'availableLocationIds',
      'aa', 'aaItemType', 'aaTicketTypeId', 'aaBookingId', 'aaEventId', 'aaStartDT',
      'aaPricingTierId', 'aaMembershipTypeId', 'aaPassTypeId',
    ];

    if (urlParamMap.cartId) {
      // The ONLY reason to go down this branch is if coming here from the widgets project
      // In this case:
      // 1. There will be no other URL params provided.
      // 2. We load this cart and explicitly set the location to be the cart's location
      console.log(`Initializer: getting Cart${urlParamMap.cartId ? ` with id = ${urlParamMap.cartId}` : ''}`);
      loadCart(urlParamMap.cartId);
    }

    if (urlParamMap.lang) {
      setLang(urlParamMap.lang);
    }

    const availableLocationIds2 = (
      urlParamMap.availableLocationIds
        ? urlParamMap.availableLocationIds.split(',').map(Number)
        : []
    );

    let aaPayload = null;
    if (urlParamMap.aa) {
      const {
        aaItemType: itemType,
        aaTicketTypeId: ticketTypeId, aaBookingId: bookingId, aaEventId: eventId, aaStartDT: startDT,
        aaPricingTierId: pricingTierId, aaMembershipTypeId: membershipTypeId, aaPassTypeId: passTypeId,
      } = urlParamMap;

      if (isAutoAddToCartItemType(itemType)) {
        aaPayload = {
          itemType,
        };

        if (itemType === 'EVENT_BOOKING_TICKET') {
          aaPayload.ticketTypeId = Number(ticketTypeId);
          aaPayload.bookingId = Number(bookingId);
        }

        if (itemType === 'EVENT_SERIES_TICKET') {
          aaPayload.ticketTypeId = Number(ticketTypeId);
          aaPayload.eventId = Number(eventId);
        }

        if (itemType === 'EVENT_BOOKING_INVOICE') {
          aaPayload.pricingTierId = Number(pricingTierId);

          if (bookingId) {
            aaPayload.bookingId = Number(bookingId);
          } else {
            aaPayload.eventId = Number(eventId);
            aaPayload.startDT = startDT;
          }
        }

        if (itemType === 'MEMBERSHIP') {
          aaPayload.membershipTypeId = Number(membershipTypeId);

          if (bookingId) {
            aaPayload.bookingId = Number(bookingId);
          }
        }

        if (itemType === 'PASS') {
          aaPayload.passTypeId = Number(passTypeId);

          if (bookingId) {
            aaPayload.bookingId = Number(bookingId);
          }
        }
      }
    }

    this.loadLocations(availableLocationIds2, aaPayload);

    urlParamsToDelete.forEach((param) => urlParams.delete(param));
    const search = urlParams.toString();
    history.replace(`${window.location.pathname}${search === '' ? '' : '?'}${search}`);
  };

  loadLocations = async (availableLocationIds, aaPayload) => {
    const {
      setLocations, setLocation, changeLocation, currentLocation, getCustomerCartAtLocation,
    } = this.props;

    try {
      console.log('Initializer: getting available locations');
      const { data: allLocations } = await API.getLocations();

      const allLocations2 = allLocations.sort(fieldSort('name'));
      const availableLocations = (
        availableLocationIds.length
          ? allLocations2.filter((e) => availableLocationIds.includes(e.id))
          : allLocations2
      );
      const availableLocationIds2 = availableLocations.map((e) => e.id);
      const willAddToCart = (
        aaPayload && availableLocationIds.length === 1 && availableLocations.length === 1
      );

      if (willAddToCart) {
        setLocation(availableLocations[0]);
        getCustomerCartAtLocation(availableLocations[0].id, aaPayload);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (availableLocations.length === 1) {
          changeLocation(availableLocations[0]);
        } else if (Boolean(currentLocation.id) && !availableLocationIds2.includes(currentLocation.id)) {
          setLocation(ALL_LOCATIONS);
        } else if (currentLocation.id) {
          getCustomerCartAtLocation(currentLocation.id);
        }
      }

      console.log('Initializer; available locations: ', availableLocations);
      setLocations(availableLocations);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('Initializer; getLocations error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    }
  };


  render() {
    const {
      account, authFinished, loadingLocationsFinished,
      currentLocation, locations, children,
    } = this.props;

    if (!account || !authFinished || !loadingLocationsFinished) return null;

    if (locations.length > 1 && !currentLocation.id) {
      return (
        <LocationDialog open />
      );
    }

    return children;
  }
}

Initializer.propTypes = {
  account: PropTypes.object,
  authFinished: PropTypes.bool.isRequired,
  changeLocation: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
  currentLocation: PropTypes.object.isRequired,
  getCustomerCartAtLocation: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialAddToCart: PropTypes.bool.isRequired,
  initialAddToCartFinished: PropTypes.bool.isRequired,
  loadCart: PropTypes.func.isRequired,
  loadingLocationsFinished: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  setAccount: PropTypes.func.isRequired,
  setAuthFinished: PropTypes.func.isRequired,
  setInitialAddToCart: PropTypes.func.isRequired,
  setLandingPage: PropTypes.func.isRequired,
  setLang: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  setLocations: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  account: accountSelector(state),
  authFinished: authFinishedSelector(state),
  currentLocation: locationSelector(state),
  initialAddToCart: initialAddToCartSelector(state),
  initialAddToCartFinished: initialAddToCartFinishedSelector(state),
  loadingLocationsFinished: loadingLocationsFinishedSelector(state),
  locations: locationsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeLocation: (location) => dispatch(changeLocationAction(location)),
  getCustomerCartAtLocation: (locationId, aaPayload) => dispatch(
    getCustomerCartAtLocationAction(locationId, aaPayload)
  ),
  loadCart: (cartId) => dispatch(loadCartAction(cartId)),
  setAccount: (account) => dispatch(setAccountAction(account)),
  setAuthFinished: () => dispatch(setAuthFinishedAction()),
  setInitialAddToCart: (loading) => dispatch(setInitialAddToCartAction(loading)),
  setLandingPage: (location) => dispatch(setLandingPageAction(location)),
  setLang: (lang) => dispatch(setLangAction(lang)),
  setLocation: (location) => dispatch(setLocationAction(location)),
  setLocations: (locations) => dispatch(setLocationsAction(locations)),
  signIn: (user, history) => dispatch(signInAction(user, history)),
  signOut: (initializer) => dispatch(signOutAction(initializer)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Initializer));
