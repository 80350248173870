import { createReducer } from 'service/utility';

import { SET_CURRENT_USER, SIGN_OUT } from '../actions';

const initialState = null;

export const user = createReducer(initialState, {
  [SET_CURRENT_USER]: (state, { user: user_ }) => user_,
  [SIGN_OUT]: () => initialState,
});
