import { createReducer } from 'service/utility';

import { SIGN_OUT } from 'app/auth/redux/actions';
import { SET_HOUSEHOLD } from '../actions';

const initialState = [];

export const household = createReducer(initialState, {
  [SET_HOUSEHOLD]: (state, { household: household_ }) => household_,
  [SIGN_OUT]: () => initialState,
});
