import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { FormControl, FormLabel, FormGroup, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';

import { Spinner } from 'common/statusIndicators';
import { isRequired } from 'service/utility/errorMessages';


class Checkboxes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      blurred: false,
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const { getOptionsAPI, getParams, value, onChange } = this.props;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }

    getOptionsAPI(getParams).then((response) => {
      const options = response.data || response;

      if (this._isMounted) {
        this.setState({
          loading: false,
          options,
        });
      }
    });
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }

    this._isMounted = false;
  }


  isChecked = (id) => this.props.value.indexOf(id) !== -1;

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value.length && required) return isRequired(label);

    return null;
  };


  handleChangeSelf = (id) => {
    const { value: oldValue, onChange } = this.props;
    const { blurred } = this.state;

    const idx = oldValue.indexOf(id);

    const value = idx === -1 ? oldValue.concat(id) : oldValue.filter((e, i) => i !== idx);
    const error = this.validateSelf(value);

    if (!blurred) this.setState({ blurred: true });

    if (onChange) {
      onChange({ value, error });
    }
  };


  render() {
    const { className, error, label, required } = this.props;
    const { loading, options, blurred } = this.state;

    return !loading ? (
      <FormControl
        classes={{ root: classnames('checkboxes', { [className]: className }) }}
        component="fieldset"
        required={required}
        error={blurred && Boolean(error)}
      >
        <FormLabel component="legend">
          {label}
        </FormLabel>
        <FormGroup classes={{ root: 'form-group' }}>
          {options.map((item, idx) => (
            <FormControlLabel
              key={item.id || idx}
              control={
                <Checkbox
                  checked={this.isChecked(item.id)}
                  onChange={() => this.handleChangeSelf(item.id)}
                  value={item.id.toString()}
                />
              }
              label={item.name}
            />
          ))}
        </FormGroup>
        <FormHelperText>
          {error}
        </FormHelperText>
      </FormControl>
    ) : (
      <Spinner />
    );
  }
}

Checkboxes.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  getOptionsAPI: PropTypes.func.isRequired,
  getParams: PropTypes.any,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.array,
};

Checkboxes.defaultProps = {
  error: '',
  required: false,
  value: [],
};


export default Checkboxes;
