import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { TextField, MenuItem } from '@mui/material';

import { isRequired } from 'service/utility/errorMessages';


class PicklistField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue === null ? '' : rawValue;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value: rawValue, onChange } = this.props;
      const value = rawValue === null ? '' : rawValue;
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }


  validateSelf = (value) => {
    const { required, label } = this.props;

    if ((value === '' || value === null) && required) return isRequired(label);

    return null;
  };


  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue === null ? '' : rawValue;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleChangeSelf = (event) => {
    const { onChange } = this.props;
    const { value } = event.target;

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const { className, error, onChange, picklistValues, value: rawValue, ...rest } = this.props;
    const { blurred } = this.state;

    const value = rawValue === null ? '' : rawValue;
    const picklistFieldClassName = classnames('picklist-field', { [className]: Boolean(className) });

    return (
      <TextField
        select
        error={blurred && Boolean(error)}
        helperText={error}
        value={value}
        onChange={this.handleChangeSelf}
        onBlur={this.handleLeaveFocus}
        variant="outlined"
        className={picklistFieldClassName}
        InputLabelProps={{
          shrink: true,
        }}
        margin="dense"
        {...rest}
      >
        {picklistValues.map((picklistValue) => (
          <MenuItem
            key={picklistValue.value}
            value={picklistValue.value}
          >
            {picklistValue.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

PicklistField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func,
  picklistValues: PropTypes.array.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  variant: PropTypes.string,
};

PicklistField.defaultProps = {
  error: '',
  fullWidth: false,
  label: '',
  margin: 'dense',
  required: false,
  value: '',
  variant: 'outlined',
};


export default PicklistField;
