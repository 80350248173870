import { call, put, select, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { cartIdProp } from 'config/models';
import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { loadCart } from './cartSagas';
import { APPLY_PROMO_CODE, setCartLoading } from '../actions';
import { cartIdSelector } from '../selectors';


export function *applyPromoCodeSaga() {
  while (true) {
    const { promoCode } = yield take(APPLY_PROMO_CODE);

    if (!promoCode) return null;

    const cartId = yield select(cartIdSelector);
    if (!cartId) return null;

    console.log(`Applying promo code ${promoCode} to the cart with ${cartIdProp} = ${cartId}`);

    const toast1 = toast('Applying promo code...');

    try {
      yield put(setCartLoading(true));
      const { data } = yield call(API.applyPromoCode, cartId, promoCode);

      toast.dismiss(toast1);
      if (data.success) {
        yield call(loadCart, cartId);
      } else {
        toast.error(data.errorMessage);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('applyPromoCodeSaga error: ');
      console.log(errorMessage);

      toast.dismiss(toast1);
      toast.error(errorMessage);
    } finally {
      yield put(setCartLoading(false));
    }
  }
}
