import { accordionClasses } from '@mui/material/Accordion';
import { checkboxClasses } from '@mui/material/Checkbox';
import { formLabelClasses } from '@mui/material/FormLabel';
import styles from 'styles/export.module.scss';

const THEME_CONFIG = {
  palette: {
    common: {
      grey: styles.grey,
    },
    primary: {
      main: styles.primary,
      contrastText: styles.contrastTextLight,
    },
    secondary: {
      main: styles.secondary,
    },
    success: {
      main: styles.success,
    },
    error: {
      main: styles.error,
    },
    text: {
      primary: styles.textPrimary,
      secondary: styles.textSecondary,
      disabled: styles.textDisabled,
    },
    divider: styles.dividerColor,
    outline: styles.inputBorderColor,
    checkbox: styles.checkboxColor,
    action: {
      active: styles.themeActionActive,
      disabled: styles.themeActionDisabled,
      disabledBackground: styles.themeActionDisabledBackground,
      hover: styles.themeActionHover,
      hoverOpacity: styles.hoverOpacity,
      hoverEmphasis: styles.hoverEmphasis,
      selected: styles.themeActionSelected,
      selectedOpacity: styles.themeActionSelectedOpacity,
    },
    background: {
      paper: styles.paperColor,
      default: styles.paperColor,
      header: styles.headerBackgroundColor,
      sectionIcon: styles.sectionIconBackgroundColor,
    },
  },
  spacing: Number(styles.spacer),
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          padding: `${styles.buttonPaddingY} ${styles.buttonPaddingX}`,
          borderRadius: styles.buttonBorderRadius,

          '@media (max-width: 575.95px)': {
            padding: '8px 16px !important',
          },
        },
        contained: {
          // color: styles.textPrimary,
          boxShadow: 'none',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: styles.paperColor,
          },
        },
        loadingIndicator: {
          color: styles.textPrimary,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        // input: {
        //   paddingLeft: '12px',
        //   paddingRight: '12px',
        // },
        notchedOutline: {
          borderColor: styles.inputBorderColor,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: styles.listHeaderColor,
          fontWeight: styles.listHeaderFontWeight,
          fontSize: styles.listHeaderFontSize,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: styles.listItemPrimaryTextColor,
          fontSize: styles.listItemPrimaryTextSize,
          fontWeight: 500,
        },
        secondary: {
          color: styles.listItemSecondaryTextColor,
          fontSize: styles.listItemSecondaryTextSize,
        },
        inset: {
          paddingLeft: 30,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: styles.textPrimary,
        },
        underlineHover: {
          textDecoration: styles.linkDecoration,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: styles.checkboxColor,
        },
        colorSecondary: {
          [`&.${checkboxClasses.checked}`]: {
            color: styles.checkboxColor,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: styles.fontSize,
          borderBottom: `1px solid ${styles.dividerColor}`,
          padding: styles.tableCellPadding,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px',
        },
        spacing: {
          '& > :not(:first-child)': {
            marginLeft: 16,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          [`&.${accordionClasses.expanded}`]: {
            margin: '2px 0',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: styles.textPrimary,
          [`&.${formLabelClasses.focused}`]: {
            color: 'inherit',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginBottom: 0,
        },
      },
    },
  },
  shadows: [
    styles.themeShadow0,
    styles.themeShadow1,
    styles.themeShadow2,
    styles.themeShadow3,
    styles.themeShadow4,
    styles.themeShadow5,
    styles.themeShadow6,
    styles.themeShadow7,
    styles.themeShadow8,
    styles.themeShadow9,
    styles.themeShadow10,
    styles.themeShadow11,
    styles.themeShadow12,
    styles.themeShadow13,
    styles.themeShadow14,
    styles.themeShadow15,
    styles.themeShadow16,
    styles.themeShadow17,
    styles.themeShadow18,
    styles.themeShadow19,
    styles.themeShadow20,
    styles.themeShadow21,
    styles.themeShadow22,
    styles.themeShadow23,
    styles.themeShadow24,
  ],
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: styles.fontFamily,
    fontWeightLight: styles.fontWeightLight,
    fontWeightRegular: styles.fontWeightRegular,
    fontWeightMedium: styles.fontWeightMedium,
    fontWeightBold: styles.fontWeightBold,
    button: {
      fontSize: styles.buttonFontSize,
      fontWeight: styles.buttonFontWeight,
      lineHeight: styles.buttonLineHeight,
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: Number(styles.gridBreakpointxs),
      sm: Number(styles.gridBreakpointsm),
      md: Number(styles.gridBreakpointmd),
      lg: Number(styles.gridBreakpointlg),
      xl: Number(styles.gridBreakpointxl),
    },
  },
  sizing: {
    header: {
      height: styles.headerHeight,
      heightSm: styles.headerHeightSm,
    },
    logo: {
      spacing: 10,
      spacingSm: 8,
    },
  },
  shape: {
    borderRadius: 4,
    buttonBorderRadius: styles.buttonBorderRadius,
    buttonPadding: `${styles.buttonPaddingY} ${styles.buttonPaddingX}`,
  },
  zIndex: {
    mobileStepper: styles.zIndexMobileStepper,
    speedDial: styles.zIndexSpeedDial,
    appBar: styles.zIndexAppBar,
    drawer: styles.zIndexDrawer,
    modal: styles.zIndexModal,
    snackbar: styles.zIndexSnackbar,
    tooltip: styles.zIndexTooltip,
  },
};

export default THEME_CONFIG;
