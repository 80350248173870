import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import TextField from './TextField';


const PasswordField = (props) => {
  const { canShowPassword, className, ...rest } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (canShowPassword) {
    rest.InputProps = {
      ...(rest.InputProps || {}),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </InputAdornment>
      ),
    };
  }

  return (
    <TextField
      {...rest}
      type={showPassword ? 'text' : 'password'}
      className={classnames('password-field', { [className]: className })}
    />
  );
};

PasswordField.propTypes = {
  canShowPassword: PropTypes.bool,
  className: PropTypes.string,
};


export default PasswordField;
