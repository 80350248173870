import { pick, mapValues } from 'lodash';

import { requiresEmergencyContact } from 'service/i18n';

import { DATATYPES } from './DataTypes';


export const FIELD_GROUP_HEADER = 'FIELD_GROUP_HEADER';
export const FIELD_GROUP_DIVIDER = 'FIELD_GROUP_DIVIDER';
export const CUSTOM_FIELD = 'CUSTOM_FIELD';

export const cartIdProp = 'UUID';


export const ADDRESS_SUBFIELDS = ['countryCode', 'address1', 'address2', 'city', 'state', 'postalCode'];

const getAddressField = (model_) => ({
  type: DATATYPES.address,
  subFields: ADDRESS_SUBFIELDS.map((e) => model_[e]),
  isRequired: () => mapValues(pick(model_, ADDRESS_SUBFIELDS), (e) => Boolean(e.isRequired)),
});


const Customer_ = {
  id: { name: 'id', type: DATATYPES.id },
  firstName: { name: 'firstName', type: DATATYPES.name, isRequired: true },
  lastName: { name: 'lastName', type: DATATYPES.name, isRequired: true },
  birthdate: { name: 'birthdate', type: DATATYPES.birthdate, isRequired: true },
  company: { name: 'company', type: DATATYPES.string },
  isMember: { name: 'isMember', type: DATATYPES.boolean },
  countryCode: { name: 'countryCode', type: DATATYPES.country },
  address1: { name: 'address1', type: DATATYPES.string, isRequired: true },
  address2: { name: 'address2', type: DATATYPES.string },
  city: { name: 'city', type: DATATYPES.string, isRequired: true },
  state: { name: 'state', type: DATATYPES.state, isRequired: true },
  postalCode: { name: 'postalCode', type: DATATYPES.postalCode, isRequired: true },
  billingAddress1: { name: 'billingAddress1', type: DATATYPES.string },
  billingAddress2: { name: 'billingAddress2', type: DATATYPES.string },
  billingCity: { name: 'billingCity', type: DATATYPES.string },
  billingState: { name: 'billingState', type: DATATYPES.string },
  billingPostalCode: { name: 'billingPostalCode', type: DATATYPES.string },
  email: { name: 'email', type: DATATYPES.email, isRequired: true },
  secondaryEmail: { name: 'secondaryEmail', type: DATATYPES.email },
  mobile: { name: 'mobile', type: DATATYPES.phone, isRequired: true },
  home: { name: 'home', type: DATATYPES.phone },
  metadata: { name: 'metadata', type: DATATYPES.string },
  createdAt: { name: 'createdAt', type: DATATYPES.datetime },
  updatedAt: { name: 'updatedAt', type: DATATYPES.datetime },
  addtionalInfo: { name: 'addtionalInfo', type: DATATYPES.string },
  notes: { name: 'notes', type: DATATYPES.string },
  isDependant: { name: 'isDependant', type: DATATYPES.boolean },
  climingStartDate: { name: 'climingStartDate', type: DATATYPES.datetime },
  UUID: { name: 'UUID', type: DATATYPES.string },
  emergencyName: {
    name: 'emergencyName',
    type: DATATYPES.string,
    isRequired: (customer, options) => requiresEmergencyContact(options.account),
  },
  emergencyNumber: {
    name: 'emergencyNumber',
    type: DATATYPES.phone,
    isRequired: (customer, options) => requiresEmergencyContact(options.account),
  },
  emergencyRelation: {
    name: 'emergencyRelation',
    type: DATATYPES.string,
    isRequired: (customer, options) => requiresEmergencyContact(options.account),
  },
  isCorporate: { name: 'isCorporate', type: DATATYPES.boolean },
};

Customer_.address = getAddressField(Customer_);

export const Customer = Customer_;


export const CUSTOMER_PROFILE_FIELDS = ['firstName', 'lastName', 'birthdate', ...ADDRESS_SUBFIELDS];

export const EMERGENCY_CONTACT_FIELDS = ['emergencyName', 'emergencyNumber', 'emergencyRelation'];
