import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { TextField, MenuItem } from '@mui/material';


class DayField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.DAYS = [...Array(32).keys()].slice(1);
  }

  handleChangeSelf = (event) => {
    const { onChange } = this.props;

    if (onChange) {
      const value = Number(event.target.value);

      onChange({ value });
    }
  }

  render() {
    const {
      t, tReady, i18n, onChange, label = this.props.t('common.Day'), value: rawValue, className, ...rest
    } = this.props;

    const value = rawValue === null ? '' : rawValue;

    return (
      <TextField
        select
        label={label}
        value={value}
        onChange={this.handleChangeSelf}
        variant="outlined"
        margin="dense"
        InputLabelProps={{
          shrink: true,
        }}
        className={classnames('day-field', { [className]: className })}
        {...rest}
      >
        {this.DAYS.map((day) => (
          <MenuItem
            key={day}
            value={day}
          >
            {day}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

DayField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  i18n: PropTypes.object,
  label: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func,
  t: PropTypes.func,
  tReady: PropTypes.bool,
  value: PropTypes.number,
  variant: PropTypes.string,
};

DayField.defaultProps = {
  margin: 'dense',
  variant: 'outlined',
};


export default withTranslation()(DayField);
