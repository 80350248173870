import { put, take, call, race } from 'redux-saga/effects';

import { CONFIRM_PROFILE, PROFILE_CONFIRMED, PROFILE_CANCELLED, setConfirmProfileDialog } from '../actions';


function *awaitConfirmation(callback, customer, history) {
  yield take(PROFILE_CONFIRMED);
  yield call(callback, customer, history);
}

export function *confirmProfileSaga() {
  while (true) {
    const { customer, confirmCallback, history } = yield take(CONFIRM_PROFILE);

    try {
      yield put(setConfirmProfileDialog(true, customer));

      yield race({
        awaitConfirmation: call(awaitConfirmation, confirmCallback, customer, history),
        cancel: take(PROFILE_CANCELLED),
      });

      yield put(setConfirmProfileDialog(false));
    } catch (error) {
      console.log('confirmProfileSaga error: ', error);
    }
  }
}
