import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { US_STATES } from 'config/usStates';

import PicklistField from '../PicklistField';


const StateField = (props) => {
  const { t } = useTranslation();

  const { className, label = t('common.State'), ...rest } = props;

  return (
    <PicklistField
      {...rest}
      label={label}
      picklistValues={US_STATES.map((option) => ({ label: option, value: option }))}
      className={classnames('state-field', { [className]: className })}
    />
  );
};

StateField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};


export default StateField;
