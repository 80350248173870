import Color from 'color';
import { isEqual } from 'lodash';

export { createReducer } from './createReducer';
export { makeActionCreator } from './makeActionCreator';
export { useEffectAsync } from './useEffectAsync';


export const getArrayOfSize = (n) => Array.from(new Array(n));

export const getArrayOfNumbers = (n) => [...getArrayOfSize(n).keys()];

export const getFakeObjects = (n) => getArrayOfSize(n).map((e, idx) => ({ id: -idx }));

export const scrollToBottom = () => {
  const el = document.getElementById('content');

  el.scrollTo(0, el.scrollHeight);
};

export const getErrorMessage = (error) => {
  if (typeof error === 'string') return error;
  if (typeof error !== 'object') return JSON.stringify(error);

  const { message: regularErrorMessage, response } = error;

  if (typeof response === 'string') return response;
  if (typeof response === 'object') {
    const { data } = response;

    if (typeof data === 'string') return data;
    if (typeof data === 'object') {
      const { message } = data;

      if (typeof message === 'string') return message;
    }
  }

  if (typeof regularErrorMessage === 'string') return regularErrorMessage;
  if (typeof response !== 'object') return JSON.stringify(response);

  return 'Unknown error';
};

export const loadDynamicScript = (scriptId, url, onLoad, onError) => {
  const existingScript = document.getElementById(scriptId);

  if (existingScript) {
    existingScript.remove();
  }

  // if (!existingScript) {
  const script = document.createElement('script');

  script.src = url;
  script.id = scriptId;
  script.defer = true;
  document.body.appendChild(script);

  script.onload = () => {
    if (onLoad) onLoad();
  };

  script.onerror = () => {
    if (onError) onError();
  };
  // }

  // if (existingScript && onLoad) onLoad();
};

export const sleep = (ms) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const objectHasErrors = (o) => Object.values(o).some((e) => e);

export const commaJoin = (arr) => arr.filter((e) => e).join(',');

export const commaSpaceJoin = (arr) => arr.filter((e) => e).join(', ');

export const dashJoin = (arr) => arr.filter((e) => e).join(' – ');

export const getAddressArray = (o) => {
  const response = [];

  if (typeof o === 'object' && Boolean(o)) {
    const { address1, address2, city, state, postalCode } = o;

    if (address1 || address2) {
      response.push(commaSpaceJoin([address1, address2]));
    }
    if (city || state || postalCode) {
      response.push(commaSpaceJoin([city, state, postalCode]));
    }
  }

  return response;
};

export const rem2px = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export const op = (o, fld, dv = null) => (o ? o[fld] || dv : dv);

export const getMap = (arr, key = 'id') => (
  arr.reduce((a, b) => {
    a[b[key]] = b;

    return a;
  }, {})
);

export const fieldSort = (field, desc) => (a, b) => {
  const nameA = a[field].toUpperCase();
  const nameB = b[field].toUpperCase();

  return (desc ? nameA > nameB : nameA < nameB) ? -1 : (desc ? nameA < nameB : nameA > nameB) ? 1 : 0;
};

export const copyProps = (source, props, a = {}) => {
  for (let i = 0, l = props.length; i < l; i += 1) {
    const key = props[i];

    a[key] = source[key];
  }

  return a;
};

export const removeProps = (source, props) => {
  const a = {};

  for (let i = 0, keys = Object.keys(source), l = keys.length; i < l; i += 1) {
    const key = keys[i];

    if (!props.includes(key)) {
      a[key] = source[key];
    }
  }

  return a;
};

export const setProps = (value, props, a = {}) => {
  for (let i = 0, l = props.length; i < l; i += 1) {
    const key = props[i];

    a[key] = value;
  }

  return a;
};

export const somePropsChanged = (o1, o2, props) => (
  props.some((prop) => !isEqual(o1[prop], o2[prop]))
);

export const routeWithParams = (route, o) => (
  Object.keys(o).reduce((s, p) => s.replace(new RegExp(`:${p}(?=/|$)`), o[p]), route)
);

export const getCheckinOptionsMap = (household, membershipTypeMap, passTypeMap) => (
  household.reduce((a, householdMember) => {
    const checkinOptions = householdMember.assignedMemberships.concat(householdMember.assignedPasses);
    const validCheckinOptions = checkinOptions.filter((checkinOption) => (
      checkinOption.membershipTypeId
        ? Object.prototype.hasOwnProperty.call(membershipTypeMap, checkinOption.membershipTypeId)
        : checkinOption.passTypeId
          ? Object.prototype.hasOwnProperty.call(passTypeMap, checkinOption.passTypeId)
          : false
    ));
    const namedCheckinOptions = validCheckinOptions.map((checkinOption) => ({
      ...checkinOption,
      name: (
        checkinOption.membershipTypeId
          ? membershipTypeMap[checkinOption.membershipTypeId].name
          : checkinOption.passTypeId
            ? passTypeMap[checkinOption.passTypeId].name
            : ''
      ),
    }));

    a[householdMember.id] = namedCheckinOptions;

    return a;
  }, {})
);

export const rgba2rgb = (rgbBackground_, rgbaColor_) => {
  const rgbBackground = Color(rgbBackground_);
  const rgbaColor = Color(rgbaColor_);

  return Color.rgb(
    Math.round((1 - rgbaColor.alpha()) * rgbBackground.red() + rgbaColor.alpha() * rgbaColor.red()),
    Math.round((1 - rgbaColor.alpha()) * rgbBackground.green() + rgbaColor.alpha() * rgbaColor.green()),
    Math.round((1 - rgbaColor.alpha()) * rgbBackground.blue() + rgbaColor.alpha() * rgbaColor.blue()),
  ).hex();
};

export const getRandomUInt16 = () => window.crypto.getRandomValues(new Uint16Array(1))[0];

export const removeDOMElement = (elementId) => {
  const existingElement = document.getElementById(elementId);

  if (existingElement) {
    existingElement.parentNode.removeChild(existingElement);
  }
};

export const isNullish = (value) => (value === null || typeof value === 'undefined');
