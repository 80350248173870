import { SIGN_OUT } from 'app/auth/redux/actions';
import { createReducer } from 'service/utility';

import { SET_CART_LOADING } from '../actions';


const initialState = false;

const loading_ = createReducer(initialState, {
  [SET_CART_LOADING]: (state, { loading }) => loading,
  [SIGN_OUT]: () => initialState,
});

export default loading_;
