import { combineReducers } from 'redux';

import { finished } from './finished';
import { temporaryEmail } from './temporaryEmail';
import { temporaryPassword } from './temporaryPassword';
import { user } from './user';

const authReducer = combineReducers({
  finished,
  temporaryEmail,
  temporaryPassword,
  user,
});

export default authReducer;
