import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { TextField as MuiTextField } from '@mui/material';

import { isRequired } from 'service/utility/errorMessages';


class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount() {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }


  validateSelf = (value) => {
    const { required, label, validate } = this.props;

    if (!value && required) return isRequired(label);

    return validate ? validate(value) : null;
  };


  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (value) => {
    const { onChange } = this.props;
    const error = this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };


  render() {
    const { error, onChange, multiline, validate, value: rawValue, ...rest } = this.props;
    const { blurred } = this.state;

    const value = rawValue || '';

    return (
      <MuiTextField
        error={blurred && Boolean(error)}
        helperText={error}
        value={value}
        onChange={(e) => this.handleChangeSelf(e.target.value)}
        onBlur={this.handleLeaveFocus}
        classes={{ root: classnames('text-field', { 'multiline-field': multiline }) }}
        multiline={multiline}
        {...rest}
      />
    );
  }
}

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  validate: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.string,
};

TextField.defaultProps = {
  autoFocus: false,
  error: '',
  fullWidth: false,
  label: '',
  margin: 'dense',
  multiline: false,
  required: false,
  value: '',
  variant: 'outlined',
};


export default TextField;
