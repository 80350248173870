import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import { invalidEmail } from 'service/utility/errorMessages';
import * as validations from 'service/utility/validations';

import TextField from './TextField';


const EmailField = (props) => {
  const { className, ...rest } = props;

  return (
    <TextField
      {...rest}
      validate={(value) => (Boolean(value) && !validations.email(value) ? invalidEmail() : null)}
      className={classnames('email-field', { [className]: className })}
    />
  );
};

EmailField.propTypes = {
  className: PropTypes.string,
};


export default EmailField;
