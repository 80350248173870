import { createReducer } from 'service/utility';

import { SET_TEMPORARY_CUSTOMER } from '../actions';


const initialState = {};

const temporaryCustomer = createReducer(initialState, {
  [SET_TEMPORARY_CUSTOMER]: (state, { customer }) => customer,
});


export default temporaryCustomer;
