import { put, take, call, race } from 'redux-saga/effects';

import {
  CONFIRM_ACTION, ACTION_CONFIRMED, ACTION_DENIED, ACTION_CANCELLED, setConfirmActionDialog,
} from '../actions';


function *awaitConfirmation(confirmCallback) {
  yield take(ACTION_CONFIRMED);
  confirmCallback();
}

function *denyConfirmation(denyCallback) {
  yield take(ACTION_DENIED);
  denyCallback();
}

export function *confirmActionSaga() {
  while (true) {
    const { options } = yield take(CONFIRM_ACTION);

    try {
      yield put(setConfirmActionDialog(true, options));

      const raceOptions = {
        cancel: take(ACTION_CANCELLED),
      };

      if (options.confirmCallback) {
        raceOptions.awaitConfirmation = call(awaitConfirmation, options.confirmCallback);
      }

      if (options.denyCallback) {
        raceOptions.denyConfirmation = call(denyConfirmation, options.denyCallback);
      }

      yield race(raceOptions);
      yield put(setConfirmActionDialog(false));
    } catch (error) {
      console.log('confirmActionSaga error: ', error);
    }
  }
}
