import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { Avatar as MuiAvatar } from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';


const Avatar = ({ alt, className, src, size, onClick }) => (
  <MuiAvatar
    className={classnames(
      'avatar',
      {
        'avatar-xs': size === 'xs',
        'avatar-sm': size === 'sm',
        'avatar-lg': size === 'lg',
        'avatar-xl': size === 'xl',
        [className]: Boolean(className),
      }
    )}
    src={src}
    onClick={onClick && onClick}
    alt="avatar"
  >
    {!src && (
      <div className="alt-wrapper">
        {alt}
      </div>
    )}
  </MuiAvatar>
);

Avatar.propTypes = {
  alt: PropTypes.element,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  src: PropTypes.string,
};

Avatar.defaultProps = {
  alt: <PhotoIcon />,
  className: '',
  size: 'md',
  src: '',
};


export default Avatar;
