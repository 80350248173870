import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import authReducer from 'app/auth/redux/reducers';
import cartReducer from 'app/cart/redux/reducers';
import { loadState } from 'service/storage';

import { initSagas } from './initSagas';
import appReducer from './reducers';


const getStore = () => {
  const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    cart: cartReducer,
  });
  const initialState = loadState();
  const sagaMiddleware = createSagaMiddleware();
  const middleWares = [sagaMiddleware];
  const composables = [applyMiddleware(...middleWares)];
  const enhancer = compose(...composables);

  const store = createStore(
    rootReducer,
    initialState,
    enhancer,
  );

  initSagas(sagaMiddleware);

  return store;
};

const AppStore = getStore();

export default AppStore;
