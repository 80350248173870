import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { TextField, MenuItem } from '@mui/material';

import * as i18nService from 'service/i18n';


class MonthField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.MONTHS = i18nService.getCalendarMonthsLong();
  }

  handleChangeSelf = (event) => {
    const { onChange } = this.props;

    if (onChange) {
      const value = Number(event.target.value);

      onChange({ value });
    }
  }

  render() {
    const {
      t, tReady, i18n, onChange, label = this.props.t('common.Month'), value: rawValue, className, ...rest
    } = this.props;

    const value = rawValue === null ? '' : rawValue;

    return (
      <TextField
        select
        label={label}
        value={value}
        onChange={this.handleChangeSelf}
        variant="outlined"
        margin="dense"
        InputLabelProps={{
          shrink: true,
        }}
        className={classnames('month-field', { [className]: className })}
        {...rest}
      >
        {this.MONTHS.map((month, idx) => (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={idx + 1}
            value={idx + 1}
          >
            {month}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

MonthField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  i18n: PropTypes.object,
  label: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func,
  t: PropTypes.func,
  tReady: PropTypes.bool,
  value: PropTypes.number,
  variant: PropTypes.string,
};

MonthField.defaultProps = {
  margin: 'dense',
  variant: 'outlined',
};


export default withTranslation()(MonthField);
