import { take, call, select } from 'redux-saga/effects';

import {
  account as accountSelector,
  location as locationSelector,
} from 'app/redux/selectors';
import * as GTM from 'service/googleTagManager';

import { removeItemsFromCart } from './cartSagas';
import { REMOVE_ITEM_FROM_CART } from '../actions';
import { cartIdSelector, cartItemsSelector } from '../selectors';


export function *removeItemFromCartSaga() {
  while (true) {
    const { itemId } = yield take(REMOVE_ITEM_FROM_CART);
    const cartId = yield select(cartIdSelector);
    const account = yield select(accountSelector);
    const currentLocation = yield select(locationSelector);

    if (!itemId || !cartId) return null;

    const cartItems = yield select(cartItemsSelector);
    const item = cartItems.find((e) => e.UUID === itemId);

    let itemsToRemove = [item];

    if (item.relatedUUID) {
      itemsToRemove = cartItems.filter((e) => e.relatedUUID === item.relatedUUID);
    }

    GTM.addRemoveFromCartTag(itemsToRemove, currentLocation, account);

    console.log('removeItemFromCartSaga; itemsToRemove = ', itemsToRemove);
    const itemUUIDsToRemove = itemsToRemove.map((e) => e.UUID);

    yield call(removeItemsFromCart, cartId, itemUUIDsToRemove);
  }
}
