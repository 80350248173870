import React from 'react';
import { PropTypes } from 'prop-types';
import { usePlacesWidget } from 'react-google-autocomplete';

import TextField from '../TextField';


const AutocompleteAddress = (props) => {
  const { countryCode, googleMapsApiKey, onSelect, ...rest } = props;
  const googleMapsApiOptions = {
    apiKey: googleMapsApiKey,
    onPlaceSelected: onSelect,
    options: {
      types: ['geocode', 'establishment'],
    },
  };

  if (countryCode) {
    googleMapsApiOptions.options.componentRestrictions = { country: countryCode };
  }

  const { ref: materialRef } = usePlacesWidget(googleMapsApiOptions);

  return (
    <div className="field-wrapper">
      <TextField
        fullWidth
        inputRef={materialRef}
        {...rest}
      />
    </div>
  );
};

AutocompleteAddress.propTypes = {
  countryCode: PropTypes.string,
  error: PropTypes.string,
  googleMapsApiKey: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
};


export default AutocompleteAddress;
