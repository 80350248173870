import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { formatPhoneNumber } from 'react-phone-number-input';

import AddressInformation from 'common/AddressInformation';


const LocationDetails = ({ location }) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 d-flex flex-column justify-content-start align-items-start">
      {!location.id ? (
        <Typography>
          {`${t('common.NoLocationSelected')}.`}
        </Typography>
      ) : (
        <>
          <Typography>
            {location.name}
          </Typography>
          <AddressInformation object={location} />
          <Typography>
            {formatPhoneNumber(location.phone)}
          </Typography>
          <Typography>
            {location.email}
          </Typography>
        </>
      )}
    </div>
  );
};

LocationDetails.propTypes = {
  location: PropTypes.object.isRequired,
};


export default LocationDetails;
