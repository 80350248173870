import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { withStyles } from 'tss-react/mui';


const StickyTopDiv = ({ classes, children, className, color, ...rest }) => (
  <div
    className={classnames('sticky-top', { [className]: className })}
    {...rest}
  >
    {children}
  </div>
);

StickyTopDiv.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};


const styles = (theme, props) => ({
  root: {
    backgroundColor: props.color || theme.palette.background.stickytop,
  },
});

const StickyTopDiv2 = withStyles(StickyTopDiv, styles);


export default StickyTopDiv2;
