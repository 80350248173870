import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from 'tss-react/mui';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Link as MuiLink, List,
} from '@mui/material';

import { setLang as setLangAction } from 'app/redux/actions';
import { lang as langSelector } from 'app/redux/selectors';
import { SUPPORTED_LOCALES } from 'config/i18n';

import Language from './Language';


class LanguageDialog extends React.Component {
  constructor(props) {
    super(props);

    this.supportedLanguageMap = SUPPORTED_LOCALES.reduce((a, lang) => {
      a[lang] = {
        value: lang,
        label: this.props.t(`lang.${lang}`),
      };

      return a;
    }, {});

    this.state = {
      selectedLang: this.props.lang,
    };
  }

  handleLangSelect = (lang) => {
    this.setState({ selectedLang: lang });
  };

  handleSaveAndClose = () => {
    const { setLang, onClose } = this.props;
    const { selectedLang } = this.state;

    setLang(selectedLang);
    onClose();
  };

  render() {
    const { t, classes, open, onClose } = this.props;
    const { selectedLang } = this.state;

    return (
      <Dialog
        open={open}
        scroll="paper"
      >
        <DialogTitle className={classes.dialogTitle}>
          {t('common.LanguageSettings')}
        </DialogTitle>
        <DialogContent classes={{ root: 'py-0' }}>
          <List disablePadding>
            {SUPPORTED_LOCALES.map((lang, idx) => (
              <Language
                key={lang}
                label={this.supportedLanguageMap[lang].label}
                value={lang}
                onSelect={this.handleLangSelect}
                selected={selectedLang === lang}
                divider={idx < SUPPORTED_LOCALES.length - 1}
              />
            ))}
          </List>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <MuiLink
            component="button"
            onClick={onClose}
          >
            {t('common.Cancel')}
          </MuiLink>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleSaveAndClose}
          >
            {t('common.Save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

LanguageDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setLang: PropTypes.func.isRequired,
  t: PropTypes.func,
};


const mapStateToProps = (state) => ({
  lang: langSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setLang: (lang) => dispatch(setLangAction(lang)),
});

const LanguageDialog2 = connect(mapStateToProps, mapDispatchToProps)(LanguageDialog);


const styles = (theme) => ({
  dialogTitle: {
    borderBottom: `2px solid ${theme.palette.divider}`,
    width: '100%',
  },
  dialogActions: {
    borderTop: `2px solid ${theme.palette.divider}`,
    width: '100%',
  },
});

const LanguageDialog3 = withStyles(LanguageDialog2, styles);


const LanguageDialog4 = withTranslation()(LanguageDialog3);


export default LanguageDialog4;
