import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';


const Span = ({ bold, children, italic, underline }) => (
  <span
    className={classnames({
      'font-weight-bold': bold,
      'font-italic': italic,
      'text-underline': underline,
    })}
  >
    {children}
  </span>
);

Span.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.any,
  italic: PropTypes.bool,
  underline: PropTypes.bool,
};


export default Span;
