import React from 'react';
import { PropTypes } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Link as MuiLink } from '@mui/material';

import { LinkButton } from 'common/styledComponents';
import AppConfig from 'config/app';


const AgreeToTAC = ({ color }) => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  return (
    <Typography
      variant="body2"
      gutterBottom
    >
      <Trans
        i18nKey="auth.ByContinuingYouAgreeToOur"
        components={[
          <LinkButton
            key={0}
            inParagraph
            component={MuiLink}
            color={color}
            href={AppConfig.termsAndConditions}
            target="_blank"
          />,
          <LinkButton
            key={1}
            inParagraph
            component={MuiLink}
            color={color}
            href={AppConfig.privacyPolicy}
            target="_blank"
          />,
        ]}
      />
    </Typography>
  );
};

AgreeToTAC.propTypes = {
  color: PropTypes.string,
};


export default AgreeToTAC;
