export const ASSIGNABLE_ITEM_TYPES = [
  'PASS', 'MEMBERSHIP', 'EVENT_BOOKING_TICKET', 'EVENT_SERIES_TICKET',
];

export const isAssignableItemType = (itemType) => ASSIGNABLE_ITEM_TYPES.includes(itemType);


export const CHECKIN_OPTION_ITEM_TYPES = [
  'PASS', 'MEMBERSHIP',
];

export const isCheckinOptionItemType = (itemType) => CHECKIN_OPTION_ITEM_TYPES.includes(itemType);


export const ITEM_TYPES_THAT_REQUIRE_CREDIT_CARD = ['MEMBERSHIP'];

export const itemTypeRequiresCreditCard = (itemType) => (
  ITEM_TYPES_THAT_REQUIRE_CREDIT_CARD.includes(itemType)
);


export const GUEST_CHECKOUT_ITEM_TYPES = [
  'GIFT_CARD', 'MEMBERSHIP', 'PASS', 'EVENT_BOOKING_TICKET', 'EVENT_SERIES_TICKET', 'FEE',
];

export const RESTRICTED_GUEST_CHECKOUT_ITEM_TYPES = [
  'GIFT_CARD',
];

export const canGuestCheckoutItemType = (itemType, enableMPT) => (
  enableMPT
    ? GUEST_CHECKOUT_ITEM_TYPES.includes(itemType)
    : RESTRICTED_GUEST_CHECKOUT_ITEM_TYPES.includes(itemType)
);


export const ITEM_TYPE_MAP = {
  // GIFT_CARD: 'Gift Card',
  // PASS: 'Pass',
  // MEMBERSHIP: 'Membership',
  // FEE: 'Fee',
  // EVENT_BOOKING_TICKET: 'Ticket',
  // EVENT_SERIES_TICKET: 'Multi-Day Ticket',
  // EVENT_BOOKING_INVOICE: 'Deposit',
  // PRODUCT: 'Product',
  EVENT_BOOKING_INVOICE: 'Deposit',
  EVENT_BOOKING_TICKET: 'Ticket',
  EVENT_SERIES_TICKET: 'MultiDayTicket',
  FEE: 'Fee',
  GIFT_CARD: 'GiftCard',
  MEMBERSHIP: 'Membership',
  PASS: 'Pass',
  PRODUCT: 'Product',
};

// export const getItemTypeName = (itemType) => (itemType ? ITEM_TYPE_MAP[itemType] : null);

export const AUTO_ADD_TO_CART_ITEM_TYPES = [
  'EVENT_BOOKING_TICKET', 'EVENT_SERIES_TICKET', 'EVENT_BOOKING_INVOICE', 'MEMBERSHIP', 'PASS',
];

export const isAutoAddToCartItemType = (itemType) => AUTO_ADD_TO_CART_ITEM_TYPES.includes(itemType);
