import { commaSpaceJoin, getAddressArray } from 'service/utility';
import {
  formatPhone as formatPhone2,
  getPhoneCountryCode as getPhoneCountryCode2,
} from 'service/utility/phoneNumber';
import * as timeUtils from 'service/utility/timeUtils';


export const formatName = (customer) => {
  const arr = [];

  if (typeof customer === 'object' && Boolean(customer)) {
    const { firstName, lastName } = customer;

    if (firstName) {
      arr.push(firstName);
    }
    if (lastName) {
      arr.push(lastName);
    }
  }

  return arr.join(' ');
};

export const formatInitials = (customer) => {
  const arr = [];

  if (typeof customer === 'object' && Boolean(customer)) {
    const { firstName, lastName } = customer;

    if (firstName) {
      arr.push(firstName[0]);
    }
    if (lastName) {
      arr.push(lastName[0]);
    }
  }

  return arr.join(' ');
};

export const formatPhone = (customer) => (
  customer && customer.mobile
    ? formatPhone2(customer.mobile)
    : '--'
);

export const getPhoneCountryCode = (customer) => (
  customer && customer.mobile
    ? getPhoneCountryCode2(customer.mobile)
    : null
);

export const formatBirthdate = (customer) => (
  customer && customer.birthdate && !timeUtils.isEmptyIso8601Date(customer.birthdate)
    ? timeUtils.formatShortDate(timeUtils.iso8601Date(customer.birthdate))
    : timeUtils.getShortDateEmpty()
);

export const formatAddress = (customer) => {
  const addressArray = getAddressArray(customer);

  return (
    addressArray.length === 0
      ? '--'
      : addressArray.length === 1
        ? addressArray[0]
        : commaSpaceJoin([addressArray[0], addressArray[1]])
  );
};

export const getAge = (customer) => (
  customer.birthdate ? timeUtils.age(customer.birthdate) : null
);

export const formatAgeAndBirthdate = (customer) => (
  customer && customer.birthdate
    ? `${getAge(customer)} (${timeUtils.formatShortDate(customer.birthdate)})`
    : '--'
);

export const isUnderage = (customer) => {
  const age = getAge(customer);

  return age !== null && age < 18;
};

export const isNewCustomer = (customer) => (
  !customer.source
);

export const isExistingNonPortalCustomer = (customer) => (
  customer.source === 'cloud' || customer.source === 'client'
);

export const isExistingPortalCustomer = (customer) => (
  Boolean(customer.source) && customer.source !== 'cloud' && customer.source !== 'client'
);

export const hasMyAddress = (householdMember, customer) => (
  ['countryCode', 'address1', 'address2', 'city', 'state', 'postalCode'].every(
    (fieldName) => householdMember[fieldName] === (customer[fieldName] ?? null)
  )
);

export const isMyEmergencyContact = (householdMember, customer) => (
  householdMember.emergencyName === formatName(customer) &&
  householdMember.emergencyNumber === customer.mobile
);
