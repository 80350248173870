import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { TextField, MenuItem } from '@mui/material';

import * as timeUtils from 'service/utility/timeUtils';


class YearField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.YEARS = [...Array(100).keys()].map((e) => (timeUtils.iso8601CurrentYear() - e));
  }

  handleChangeSelf = (event) => {
    const { onChange } = this.props;

    if (onChange) {
      const value = Number(event.target.value);

      onChange({ value });
    }
  }

  render() {
    const {
      t, tReady, i18n, onChange, label = this.props.t('common.Year'), value: rawValue, className, ...rest
    } = this.props;

    const value = rawValue === null ? '' : rawValue;

    return (
      <TextField
        select
        label={label}
        value={value}
        onChange={this.handleChangeSelf}
        variant="outlined"
        margin="dense"
        InputLabelProps={{
          shrink: true,
        }}
        className={classnames('year-field', { [className]: className })}
        {...rest}
      >
        {this.YEARS.map((year) => (
          <MenuItem
            key={year}
            value={year}
          >
            {year}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

YearField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  i18n: PropTypes.object,
  label: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  t: PropTypes.func,
  tReady: PropTypes.bool,
  value: PropTypes.number,
  variant: PropTypes.string,
};

YearField.defaultProps = {
  margin: 'dense',
  variant: 'outlined',
};


export default withTranslation()(YearField);
