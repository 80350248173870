import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { setRegion as setRegionAction } from 'app/redux/actions';
import { region as regionSelector } from 'app/redux/selectors';
import * as API from 'service/api';
import { getNamespace } from 'service/axios';


class Region extends React.Component {
  componentDidMount() {
    this.loadRegion();
  }

  loadRegion = async () => {
    const { setRegion } = this.props;

    try {
      console.log('Region: getting regions');
      const { data } = await API.getRegion(getNamespace());

      console.log('Region: received regions; response: ', data);

      setRegion(data.DEFAULT_REGION);
    } catch (error) {
      console.log('Region: API.getRegion error: ', error);
    }
  };

  render() {
    const { region, children } = this.props;

    if (!region) return null;

    return children;
  }
}

Region.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
  region: PropTypes.string,
  setRegion: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  region: regionSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRegion: (region) => dispatch(setRegionAction(region)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Region);
