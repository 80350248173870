import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
  // Divider, List,
} from '@mui/material';

// import { authUser as authUserSelector } from 'app/auth/redux/selectors';
import {
  purchaseConfirmed as purchaseConfirmedAction,
  purchaseCancelled as purchaseCancelledAction,
} from 'app/redux/actions';
import {
  customer as customerSelector,
  // household as householdSelector,
  location as locationSelector,
  purchaseDialog as purchaseDialogSelector,
  submittingPurchase as submittingPurchaseSelector,
} from 'app/redux/selectors';
import { PicklistField } from 'common/formFields';
// import Person from 'common/Person';
import { ButtonSpinner } from 'common/statusIndicators';
import { ErrorText/*, LinkButton*/ } from 'common/styledComponents';
import { /*isAssignableItemType, */ITEM_TYPE_MAP } from 'config/itemTypes';
// import * as NAVIGATION from 'config/navigation';
// import { getReturnToURL } from 'service/navigation';
import { getAddressArray, dashJoin } from 'service/utility';


const getFirstLocationId = (locations, currentLocationId) => {
  if (!locations || !Array.isArray(locations) || !locations.length) {
    return null;
  }

  if (Boolean(currentLocationId) && locations.find((e) => e.id === currentLocationId)) {
    return currentLocationId;
  }

  return locations[0].id;
};


const PurchaseDialog = (props) => {
  const { t } = useTranslation();

  const { purchaseDialog, purchaseConfirmed, purchaseCancelled, submittingPurchase } = props;
  const { isOpen, options } = purchaseDialog;
  const { itemType, validLocations } = options;
  const locationId = props.location.id;
  const firstLocationId = getFirstLocationId(validLocations, locationId);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState(props.customer.id);
  const [selectedLocationId, setSelectedLocationId] = React.useState(firstLocationId);


  React.useEffect(() => {
    setSelectedCustomerId(props.customer.id);
  }, [props.customer.id]);

  React.useEffect(() => {
    setSelectedLocationId(getFirstLocationId(validLocations, locationId));
  }, [validLocations, locationId]);


  const itemTypeKey = itemType ? ITEM_TYPE_MAP[itemType] : null;
  const itemTypeName = itemTypeKey ? t(`common:${itemTypeKey}`) : null;
  const locationPicklistValues = validLocations.map(
    (e) => ({
      label: `${e.name} (${dashJoin(getAddressArray(e)) || t('common.NoAddressInformation')})`,
      value: e.id,
    }),
  );


  const handleAddToCart = () => {
    const choices = {
      selectedCustomerId,
      selectedLocation: validLocations.find((e) => e.id === selectedLocationId),
    };

    purchaseConfirmed(choices);
  };


  // const household = props.household.filter((e) => e.parentCustomerId !== null);

  return (
    <Dialog
      open={isOpen}
      onClose={purchaseCancelled}
    >
      <DialogTitle>
        {t('common.AssignX', { item: itemTypeName })}
      </DialogTitle>
      <DialogContent className="cmt-3">
        {validLocations.length > 1 && (
          <div className="w-100">
            <Typography paragraph>
              {`${
                t('common.ThisXIsValidFor', { context: itemTypeKey })
              }. ${
                t('common.WhereDoYouWantToPurchaseX', { context: itemTypeKey })
              }.`}
            </Typography>
            <PicklistField
              fullWidth
              label={t('common.Location')}
              value={selectedLocationId}
              onChange={(update) => update.value && setSelectedLocationId(update.value)}
              picklistValues={locationPicklistValues}
            />
            {(selectedLocationId !== locationId) && (
              <div className="mt-2">
                <ErrorText variant="body2">
                  {`${
                    t('common.Warning')
                  }: ${
                    t('common.TheXYouAreAboutToAdd', { context: itemTypeKey })
                  }. ${
                    t('common.AddingThisXWillChangeLocation', { context: itemTypeKey })
                  }.`}
                </ErrorText>
              </div>
            )}
          </div>
        )}
        {validLocations.length === 1 && Boolean(locationId) && validLocations[0].id !== locationId && (
          <ErrorText variant="body2">
            {`${
              t('common.Warning')
            }: ${
              t('common.TheXYouAreAboutToAdd', { context: itemTypeKey })
            }. ${
              t('common.AddingThisXWillChangeLocation', { context: itemTypeKey })
            }.`}
          </ErrorText>
        )}
        {/*Boolean(props.authUser) && isAssignableItemType(itemType) && (
          <>
            <Divider />
            <Typography paragraph>
              {`${
                t('common.WhoYouWantToPurchaseXFor', { context: itemTypeKey })
              }. ${
                t('common.OnlyThePersonYouSelect', { context: itemTypeKey })
              }.`}
            </Typography>
            <Typography paragraph>
              {`${t('common.IfYouWouldLikeToPurchaseThis')}, ${t('common.please')} `}
              <LinkButton
                inParagraph
                color="textPrimary"
                component={Link}
                to={getReturnToURL(NAVIGATION.HOUSEHOLD)}
                onClick={purchaseCancelled}
              >
                {t('common.clickHere')}
              </LinkButton>
              {` ${t('common.ToGoToYourProfileAnd')}.`}
            </Typography>
            <List
              disablePadding
              className="w-100"
            >
              <Person
                person={props.customer}
                onSelect={(customer) => setSelectedCustomerId(customer.id)}
                selected={selectedCustomerId === props.customer.id}
              />
              {household.map((hm) => (
                <Person
                  key={`HM_${hm.id}`}
                  person={hm}
                  onSelect={(customer) => setSelectedCustomerId(customer.id)}
                  selected={selectedCustomerId === hm.id}
                />
              ))}
            </List>
          </>
        )*/}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={purchaseCancelled}
          disabled={submittingPurchase}
        >
          {t('common.Cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddToCart}
          disabled={submittingPurchase}
        >
          {t('cart.AddToCart')}
          {submittingPurchase && <ButtonSpinner />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PurchaseDialog.propTypes = {
  // authUser: PropTypes.object,
  customer: PropTypes.object.isRequired,
  // household: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  purchaseCancelled: PropTypes.func.isRequired,
  purchaseConfirmed: PropTypes.func.isRequired,
  purchaseDialog: PropTypes.object.isRequired,
  submittingPurchase: PropTypes.bool.isRequired,
};


const mapStateToProps = (state) => ({
  // authUser: authUserSelector(state),
  customer: customerSelector(state),
  // household: householdSelector(state),
  location: locationSelector(state),
  purchaseDialog: purchaseDialogSelector(state),
  submittingPurchase: submittingPurchaseSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  purchaseConfirmed: (choices) => dispatch(purchaseConfirmedAction(choices)),
  purchaseCancelled: () => dispatch(purchaseCancelledAction()),
});

const PurchaseDialog2 = connect(mapStateToProps, mapDispatchToProps)(PurchaseDialog);


export default PurchaseDialog2;
