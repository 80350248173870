import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './config/i18n';
import './styles/loader.scss';
import './styles/vendor.scss';
import './styles/bootstrap.scss';
import './styles/layout.scss';

import { SW_INIT, SW_UPDATE } from './app/redux/actions';
import store from './app/redux/store';
import { saveState } from './service/storage';
import { setNewLocale } from './service/utility/i18n';
import * as serviceWorker from './serviceWorkerRegistration';

import App from './app';

const initialState = store.getState();

store.subscribe(() => {
  saveState(store.getState());
});

if (initialState.app) {
  if (initialState.app.lang) {
    setNewLocale(initialState.app.lang);
  } else {
    store.dispatch({
      type: 'SET_LANG',
      lang: navigator.language,
    });
  }
}

const urlParams = new URLSearchParams(window.location.search);
const settings = urlParams.get('_settings');
let settingsObj;

if (settings) {
  try {
    settingsObj = JSON.parse(atob(settings));
  // eslint-disable-next-line no-empty
  } catch {}
}

if (settingsObj) {
  store.dispatch({
    type: 'SET_SETTINGS',
    settings: settingsObj,
  });
}

if (settings) {
  urlParams.delete('_settings');
  const search = urlParams.toString();
  window.history.replaceState(null, null, `${window.location.pathname}${search ? `?${search}` : ''}`);
}


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (registration) => store.dispatch({ type: SW_UPDATE, payload: registration }),
});
