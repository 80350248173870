import i18n from 'config/i18n';
import { usesPriceBeforeTax } from 'service/i18n';
import { formatPriceWithoutTax } from 'service/utility/pricing';


export const getPayloadPriceField = (pricingTier) => (
  pricingTier.taxInclusiveDeposit !== null
    ? 'taxInclusivePrice'
    : 'price'
);

const getPricePerHeadField = (account) => (
  usesPriceBeforeTax(account)
    ? 'pricePerHead'
    : 'taxInclusivePricePerHead'
);

const getFlatFeeField = (account) => (
  usesPriceBeforeTax(account)
    ? 'flatFee'
    : 'taxInclusiveFlatFee'
);

const getPricePerHead = (pricingTier, account) => (
  pricingTier[getPricePerHeadField(account)]
);

const getFlatFee = (pricingTier, account) => (
  pricingTier[getFlatFeeField(account)]
);

export const getDeposit = (pricingTier) => (
  pricingTier.taxInclusiveDeposit !== null
    ? pricingTier.taxInclusiveDeposit
    : pricingTier.deposit
);

export const formatPricingTierPrice = (pricingTier, account) => {
  const pricePerHead = getPricePerHead(pricingTier, account);
  const flatFee = getFlatFee(pricingTier, account);

  return (
    pricePerHead
      ? i18n.t('calendar.XperPerson', { price: formatPriceWithoutTax(pricePerHead, account) })
      : i18n.t('calendar.XflatFee', { price: formatPriceWithoutTax(flatFee, account) })
  );
};

export const formatGuestsInterval = (pricingTier) => (
  i18n.t(
    'calendar.forXPeople',
    { people: `${pricingTier.minGuests}${pricingTier.maxGuests ? `–${pricingTier.maxGuests}` : '+'}` },
  )
);

export const formatPricingTierDeposit = (pricingTier, account) => (
  i18n.t(
    'calendar.Xdeposit',
    { deposit: formatPriceWithoutTax(getDeposit(pricingTier), account) },
  )
);
