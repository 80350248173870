import React from 'react';
import { PropTypes } from 'prop-types';
import PersonIcon from '@mui/icons-material/Person';

import Avatar from './Avatar';


const PersonAvatar = ({ src, size, onClick }) => (
  <Avatar
    className="person-avatar"
    size={size}
    src={src}
    alt={<PersonIcon />}
    onClick={onClick}
  />
);

PersonAvatar.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  src: PropTypes.string,
};

PersonAvatar.defaultProps = {
  size: 'md',
  src: '',
};


export default PersonAvatar;
