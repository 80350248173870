import { initAxios, initAxiosForms, initAxiosRegion, initAxiosWidgets } from './axios';

export const getRegion = (namespace) => initAxiosRegion().then(
  (axios) => axios.get(`/region?namespace=${namespace}`)
);

export const getCustomer = () => initAxios().then(
  (axios) => axios.get('/customers')
);
export const putCustomer = (data) => initAxios().then(
  (axios) => axios.put('/customers', data)
);
export const getHousehold = () => initAxios().then(
  (axios) => axios.get('/customers/associated')
);
export const putAssociated = (data) => initAxios().then(
  (axios) => axios.put('/customers/associated', data)
);
export const getPaymentCards = (merchant) => initAxios().then(
  (axios) => axios.get(`/customers/cards?merchant=${merchant}`)
);
export const putPaymentCard = (data) => initAxios().then(
  (axios) => axios.put('/customers/cards', data)
);
export const getGiftCards = () => initAxios().then(
  (axios) => axios.get('/customers/giftcards')
);
export const putPaymentCard2 = (sessionId, data) => initAxiosWidgets().then(
  (axios) => axios.put(`/carts/session/${sessionId}/cards`, data)
);

export const getCartById = (cartId) => initAxiosWidgets().then(
  (axios) => axios.get(`/carts/${cartId}`)
);
export const getCustomerCartAtLocation = (customerId, locationId) => initAxios().then(
  (axios) => axios.get(`/carts/customer/${customerId}?locationId=${locationId}`)
);
export const putCart = (data) => initAxiosWidgets().then(
  (axios) => axios.put('/carts', data)
);
export const assignCart = (cartId) => initAxios().then(
  (axios) => axios.post(`/carts/${cartId}/customer`)
);
export const addCartItems = (cartId, data) => initAxiosWidgets().then(
  (axios) => axios.post(`/carts/${cartId}/items`, data)
);
export const removeCartItem = (cartId, itemId) => initAxiosWidgets().then(
  (axios) => axios.delete(`/carts/${cartId}/items/${itemId}`)
);
export const updateItemQuantity = (cartId, itemId, quantity) => initAxiosWidgets().then(
  (axios) => axios.post(`/carts/${cartId}/items/${itemId}/quantity/${quantity}`)
);
export const updateCartItem = (cartId, itemId, payload) => initAxiosWidgets().then(
  (axios) => axios.put(`/carts/${cartId}/items/${itemId}`, payload)
);
export const postOrderToCart = (cartId, data) => initAxios().then(
  (axios) => axios.post(`/carts/${cartId}/orders`, data)
);
export const postOrderToCart2 = (sessionId, data) => initAxiosWidgets().then(
  (axios) => axios.post(`/carts/session/${sessionId}/orders`, data)
);
export const createCartSession = (data) => initAxiosWidgets().then(
  (axios) => axios.post('/carts/session', data)
);
export const getMerchantPrivateKey2 = (sessionId) => initAxiosWidgets().then(
  (axios) => axios.get(`/carts/session/${sessionId}/cards/initSave`)
);
export const newSale = (orderId, data) => initAxios().then(
  (axios) => axios.post(`/orders/${orderId}/sales`, data)
);
export const newSale2 = (sessionId, data) => initAxiosWidgets().then(
  (axios) => axios.post(`/carts/session/${sessionId}/sales`, data)
);
export const applyPromoCode = (cartId, promocode) => initAxiosWidgets().then(
  (axios) => axios.post(`/carts/${cartId}/promocode`, { promocode })
);
export const removePromoCode = (cartId) => initAxiosWidgets().then(
  (axios) => axios.delete(`/carts/${cartId}/promocode`)
);
export const applyGiftCard = (cartId, giftCardExternalId) => initAxios().then(
  (axios) => axios.post(`/carts/${cartId}/giftcards`, { giftCardExternalId })
);
export const applyGiftCard2 = (sessionId, giftCardExternalId) => initAxiosWidgets().then(
  (axios) => axios.post(`/carts/session/${sessionId}/giftcards`, { giftCardExternalId })
);
export const removeGiftCard = (cartId) => initAxios().then(
  (axios) => axios.delete(`/carts/${cartId}/giftcards`)
);
export const removeGiftCard2 = (sessionId) => initAxiosWidgets().then(
  (axios) => axios.delete(`/carts/session/${sessionId}/giftcards`)
);

export const signWaiver = (data, query = '') => initAxios().then(
  (axios) => axios.post(`/waivers/sign?${query}`, data)
);
export const getMerchantPrivateKey = (locationId) => initAxios().then(
  (axios) => axios.get(`/paymentcards/locations/${locationId}/initsave`)
);

export const getAccount = (accountId) => initAxios(false).then(
  (axios) => axios.get(`/accounts/${accountId}`)
);
export const getLocations = () => initAxiosWidgets().then(
  (axios) => axios.get('/locations')
);
export const getLocationById = (locationId) => initAxiosWidgets().then(
  (axios) => axios.get(`/locations/${locationId}`)
);
export const getCalendarSlots = (query = '') => initAxiosWidgets().then(
  (axios) => axios.get(`/cal?${query}`)
);
export const getMembershipTypes = (query = '') => initAxiosWidgets().then(
  (axios) => axios.get(`/membershiptypes?${query}`)
);
export const getMembershipTypeById = (membershipTypeId) => initAxiosWidgets().then(
  (axios) => axios.get(`/membershiptypes/${membershipTypeId}`)
);
export const getPassTypes = (query = '') => initAxiosWidgets().then(
  (axios) => axios.get(`/passtypes?${query}`)
);
export const getPassTypeById = (passTypeId) => initAxiosWidgets().then(
  (axios) => axios.get(`/passtypes/${passTypeId}`)
);
export const getGiftCardTypes = (query = '') => initAxiosWidgets().then(
  (axios) => axios.get(`/giftcardtypes?${query}`)
);
export const getGiftCardTypeById = (giftCardTypeId) => initAxiosWidgets().then(
  (axios) => axios.get(`/giftcardtypes/${giftCardTypeId}`)
);
export const getPricingTierById = (pricingTierId) => initAxiosWidgets().then(
  (axios) => axios.get(`/pricingTiers/${pricingTierId}`)
);
export const getEvents = (query = '') => initAxiosWidgets().then(
  (axios) => axios.get(`/events?${query}`)
);
export const getEventById = (eventId) => initAxiosWidgets().then(
  (axios) => axios.get(`/events/${eventId}`)
);
export const getEventOccurrences = (eventId, query = '') => initAxiosWidgets().then(
  (axios) => axios.get(`/events/${eventId}/occurrences?${query}`)
);
export const getOccurrence = (eventId, dateTime) => initAxiosWidgets().then(
  (axios) => axios.get(`/events/${eventId}/occurrences/${dateTime}`)
);
export const getCategories = (query = '') => initAxiosWidgets().then(
  (axios) => axios.get(`/activities?${query}`)
);
export const getCategoryById = (categoryId) => initAxiosWidgets().then(
  (axios) => axios.get(`/activities/${categoryId}`)
);
export const getEventRelations = (eventId, relation, query = '') => initAxiosWidgets().then(
  (axios) => axios.get(`/events/${eventId}/${relation}?${query}`)
);
export const getBooking = (bookingId) => initAxiosWidgets().then(
  (axios) => axios.get(`bookings/${bookingId}`)
);
export const getBookingTickets = (bookingId) => initAxiosWidgets().then(
  (axios) => axios.get(`bookings/${bookingId}/tickets`)
);
export const getMemberships = () => initAxios().then(
  (axios) => axios.get('/customers/memberships')
);
export const getPasses = () => initAxios().then(
  (axios) => axios.get('/customers/passes')
);
export const putPass = (data) => initAxios().then(
  (axios) => axios.put('/customers/passes', data)
);
export const getBookings = () => initAxios().then(
  (axios) => axios.get('/customers/bookings')
);
export const getGiftCardByExternalId = (externalId) => initAxios().then(
  (axios) => axios.get(`/giftcards/${externalId}`)
);
export const postReservation = (bookingId, data) => initAxios().then(
  (axios) => axios.post(`/bookings/${bookingId}/customers`, data)
);
export const cancelReservation = (bookingId, reservationId) => initAxios().then(
  (axios) => axios.delete(`/bookings/${bookingId}/reservations/${reservationId}`)
);
export const getHouseholdReservations = (bookingId) => initAxios().then(
  (axios) => axios.get(`/bookings/${bookingId}/customers`)
);
export const getChats = (query = '') => initAxios().then(
  (axios) => axios.get(`/support/chats?${query}`)
);
export const getChatById = (chatId) => initAxios().then(
  (axios) => axios.get(`/support/chats/${chatId}`)
);
export const getIssueTypes = (query = '') => initAxios().then(
  (axios) => axios.get(`/support/issuetypes?${query}`)
);
export const newChat = (data) => initAxios().then(
  (axios) => axios.post('/support/chats', data)
);
export const postMessage = (data, chatId) => initAxios().then(
  (axios) => axios.post(`/support/chats/${chatId}/messages`, data)
);
export const getFormFromSessionId = (sessionSUID) => initAxiosForms().then(
  (axios) => axios.get(`/form/submission/${sessionSUID}`)
);
export const submitFormFromSessionId = (sessionSUID, data) => initAxiosForms().then(
  (axios) => axios.post(`/form/submission/${sessionSUID}`, data)
);
export const getSubscriptionSettings = (namespace, customerUUID) => initAxiosWidgets().then(
  (axios) => axios.get(`/marketing/${namespace}:${customerUUID}`)
);
export const putSubscriptionSettings = (namespace, customerUUID, payload) => initAxiosWidgets().then(
  (axios) => axios.put(`/marketing/${namespace}:${customerUUID}`, payload)
);
export const getMarketingSettings = () => initAxiosWidgets().then(
  (axios) => axios.get('/marketing/settings/')
);
export const getCustomerPoints = () => initAxios().then(
  (axios) => axios.get('/points')
);
export const getPointsLogs = (query = '') => initAxios().then(
  (axios) => axios.get(`/points/logs?${query}`)
);
export const getPointsSettings = () => initAxios().then(
  (axios) => axios.get('/points/settings')
);
