import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { StateSearch } from 'common/searches';
import {
  account as accountSelector,
} from 'app/redux/selectors';
import { commaSpaceJoin, getAddressArray } from 'service/utility';
import { isRequired } from 'service/utility/errorMessages';

import CountryField from '../CountryField';
import PostalCodeField from '../PostalCodeField';
import TextField from '../TextField';

import AutocompleteAddress from './AutocompleteAddress';


class Address extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      formattedAddress: this.getFormattedAddressForValue(this.props.value),
    };
  }

  usesGoogleAutocomplete = () => (
    Boolean(this.props.account.googleAutocomplete?.isActive)
  );

  getGoogleApiKey = () => (
    this.usesGoogleAutocomplete() ? this.props.account.googleAutocomplete?.apiKey ?? null : null
  );

  getFormattedAddressForValue = (value) => (
    !value || !this.usesGoogleAutocomplete() ? null : commaSpaceJoin(getAddressArray(value))
  );

  handleChange = (varName) => (update) => {
    const { onChange } = this.props;

    if (!onChange) return;

    const hasNewValue = update.hasOwnProperty('value');
    const hasNewError = update.hasOwnProperty('error');

    if (!hasNewValue && !hasNewError) return;

    const compositeUpdate = {};

    if (hasNewValue) {
      compositeUpdate.values = {
        [varName]: update.value,
      };
    }

    if (hasNewError) {
      compositeUpdate.errors = {
        [varName]: update.error,
      };
    }

    onChange(compositeUpdate);
  };

  handleCountryCodeChange = this.handleChange('countryCode');

  handleAddress1Change = this.handleChange('address1');

  handleAddress2Change = this.handleChange('address2');

  handleStateChange = this.handleChange('state');

  handleCityChange = this.handleChange('city');

  handlePostalCodeChange = this.handleChange('postalCode');

  handleFormattedAddressChange = (update) => {
    const { onChange } = this.props;

    if (!onChange) return;

    if (update.hasOwnProperty('error')) {
      onChange({
        errors: {
          address1: update.error,
        },
      });
    }

    if (update.hasOwnProperty('value')) {
      this.setState({
        formattedAddress: update.value,
      });
    }
  };

  handleAutocompleteAddressBlur = () => {
    const { onChange, value, required, t } = this.props;
    const { formattedAddress } = this.state;

    if (formattedAddress) {
      this.setState({
        formattedAddress: value ? this.getFormattedAddressForValue(value) : null,
      });

      if (onChange) {
        onChange({
          errors: {
            address1: !value.address1 && required.address1 ? isRequired(t('common.Address')) : null,
          },
        });
      }
    } else {
      onChange({
        values: {
          address1: null,
          state: null,
          city: null,
          postalCode: null,
        },
        errors: {
          address1: required.address1 ? isRequired(t('common.Address')) : null,
        },
      });
    }
  };

  handlePlaceSelect = (place) => {
    const { onChange } = this.props;

    if (!onChange) return;

    const postalCode = place.address_components.find(
      (addressComponent) => addressComponent.types.includes('postal_code')
    )?.short_name ?? null;
    const city = place.address_components.find(
      (addressComponent) => addressComponent.types.includes('locality')
    )?.short_name ?? null;
    const state = place.address_components.find(
      (addressComponent) => addressComponent.types.includes('administrative_area_level_1')
    )?.short_name ?? null;
    const route = place.address_components.find(
      (addressComponent) => addressComponent.types.includes('route')
    );
    const streetNumber = place.address_components.find(
      (addressComponent) => addressComponent.types.includes('street_number')
    );
    const address1 = route ? (
      `${streetNumber ? `${streetNumber.short_name} ` : ''}${route.short_name}`
    ) : null;

    const values = {
      address1,
      state,
      city,
      postalCode,
    };

    this.setState({
      formattedAddress: this.getFormattedAddressForValue(values),
    });

    onChange({
      values,
      errors: {
        address1: null,
      },
    });
  };


  render() {
    const { t, value, error, required, disabled } = this.props;
    const { formattedAddress } = this.state;

    const useState = value.countryCode !== 'DE';
    const useUsStates = value.countryCode === 'US';
    const stateLabel = (
      value.countryCode === 'US'
        ? t('common.State')
        : value.countryCode === 'CA'
          ? t('common.Province')
          : t('common.StateProvinceRegion')
    );
    const cityLabel = (
      value.countryCode === 'US' || value.countryCode === 'CA'
        ? t('common.City')
        : t('common.CityTown')
    );
    const postalCodeLabel = (
      value.countryCode === 'US'
        ? t('common.ZipCode')
        : value.countryCode === 'CA'
          ? t('common.PostalCode')
          : t('common.ZipPostalCode')
    );

    return (
      <>
        <div className="field-wrapper">
          <CountryField
            fullWidth
            label={t('common.Country')}
            value={value.countryCode}
            error={error.countryCode}
            onChange={this.handleCountryCodeChange}
            required={required.countryCode}
            disabled={disabled}
          />
        </div>
        {this.usesGoogleAutocomplete() ? (
          <>
            <AutocompleteAddress
              countryCode={value.countryCode}
              googleMapsApiKey={this.getGoogleApiKey()}
              label={t('common.Address')}
              value={formattedAddress}
              error={error.address1}
              required={required.address1}
              onChange={this.handleFormattedAddressChange}
              onBlur={this.handleAutocompleteAddressBlur}
              onSelect={this.handlePlaceSelect}
            />
            <div className="field-wrapper">
              <TextField
                fullWidth
                label={t('common.Address2')}
                value={value.address2}
                error={error.address2}
                onChange={this.handleAddress2Change}
                required={required.address2}
                disabled={disabled}
              />
            </div>
          </>
        ) : (
          <>
            <div className="field-wrapper">
              <TextField
                fullWidth
                label={t('common.Address1')}
                value={value.address1}
                error={error.address1}
                onChange={this.handleAddress1Change}
                required={required.address1}
                disabled={disabled}
              />
            </div>
            <div className="field-wrapper">
              <TextField
                fullWidth
                label={t('common.Address2')}
                value={value.address2}
                error={error.address2}
                onChange={this.handleAddress2Change}
                required={required.address2}
                disabled={disabled}
              />
            </div>
            <div className="field-wrapper d-flex cml-3">
              {useState && (
                useUsStates ? (
                  <StateSearch
                    label={stateLabel}
                    value={value.state}
                    error={error.state}
                    onChange={this.handleStateChange}
                    required={required.state}
                    disabled={disabled}
                  />
                ) : (
                  <TextField
                    className="flex-1"
                    label={stateLabel}
                    value={value.state}
                    error={error.state}
                    onChange={this.handleStateChange}
                    required={required.state}
                    disabled={disabled}
                  />
                )
              )}
              <TextField
                className="flex-1"
                label={cityLabel}
                value={value.city}
                error={error.city}
                onChange={this.handleCityChange}
                required={required.city}
                disabled={disabled}
              />
            </div>
            <div className="field-wrapper d-flex">
              <PostalCodeField
                className="flex-05"
                label={postalCodeLabel}
                value={value.postalCode}
                error={error.postalCode}
                onChange={this.handlePostalCodeChange}
                required={required.postalCode}
                disabled={disabled}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

Address.propTypes = {
  account: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func,
  required: PropTypes.object,
  t: PropTypes.func,
  value: PropTypes.object,
};

Address.defaultProps = {
  disabled: false,
  required: {
    countryCode: false,
    address1: false,
    address2: false,
    state: false,
    city: false,
    postalCode: false,
  },
};

const mapStateToProps = (state) => ({
  account: accountSelector(state),
});

const Address2 = connect(mapStateToProps)(Address);

const Address3 = withTranslation()(Address2);

export default Address3;
