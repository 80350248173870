import { createReducer } from 'service/utility';

import { SIGN_OUT } from 'app/auth/redux/actions';
import { SET_CUSTOMER } from '../actions';

const initialState = {};

export const customer = createReducer(initialState, {
  [SET_CUSTOMER]: (state, { customer: customer_ }) => customer_,
  [SIGN_OUT]: () => initialState,
});
