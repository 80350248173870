export const loadGoogleAnalytics = (measurementID) => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementID}`;

  const script2 = document.createElement('script');
  const scriptContent2 = document.createTextNode(`
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${measurementID}');
  `);
  script2.appendChild(scriptContent2);

  const first = document.head.firstChild;
  document.head.insertBefore(script, first);
  document.head.insertBefore(script2, first);
};

export const loadMetaPixel = (pixelID) => {
  const script = document.createElement('script');
  const scriptContent = document.createTextNode(`
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelID}');
    fbq('track', 'PageView');
  `);
  script.appendChild(scriptContent);

  const script2 = document.createElement('noscript');
  const img2 = document.createElement('img');
  img2.height = 1;
  img2.width = 1;
  img2.style = 'display:none';
  img2.src = `https://www.facebook.com/tr?id=${pixelID}&ev=PageView&noscript=1`;
  script2.appendChild(img2);

  document.head.appendChild(script);
  document.head.appendChild(script2);
};
