import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const SuccessBadge = ({ className }) => (
  <div className={className}>
    <CheckCircleIcon fontSize="inherit" />
  </div>
);

SuccessBadge.propTypes = {
  className: PropTypes.string.isRequired,
};


const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1.5rem',
    width: 48,
    height: 48,
    color: theme.palette.success.main,
    fontSize: '3.6rem',
  },
});

const SuccessBadge2 = withStyles(SuccessBadge, styles);


export default SuccessBadge2;
