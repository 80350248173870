import { call, put, select, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { authUser as authUserSelector } from 'app/auth/redux/selectors';
import { cartIdProp } from 'config/models';
import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { loadCart } from './cartSagas';
import { REMOVE_GIFT_CARD, setCartLoading } from '../actions';
import { cartIdSelector, cartSessionIdSelector } from '../selectors';


export function *removeGiftCardSaga() {
  while (true) {
    yield take(REMOVE_GIFT_CARD);

    const cartId = yield select(cartIdSelector);
    if (!cartId) return null;

    const authUser = yield select(authUserSelector);
    const sessionId = yield select(cartSessionIdSelector);
    if (!authUser && !sessionId) return null;

    const toast1 = toast('Removing gift card...');

    try {
      yield put(setCartLoading(true));

      if (authUser) {
        console.log(`Removing gift card from the cart with ${cartIdProp}=${cartId}`);
        yield call(API.removeGiftCard, cartId);
      } else {
        console.log(`Removing gift card from the cart with sessionId=${sessionId}`);
        yield call(API.removeGiftCard2, sessionId);
      }

      toast.dismiss(toast1);
      yield call(loadCart, cartId);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('removeGiftCardSaga error: ');
      console.log(errorMessage);

      toast.dismiss(toast1);
      toast.error(errorMessage);
    } finally {
      yield put(setCartLoading(false));
    }
  }
}
