import { createReducer } from 'service/utility';

import { SET_INITIAL_ADD_TO_CART_FINISHED } from '../actions';


const initialState = false;

const initialAddToCartFinished = createReducer(initialState, {
  [SET_INITIAL_ADD_TO_CART_FINISHED]: (state, { loading }) => loading,
});


export default initialAddToCartFinished;
