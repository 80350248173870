export const APP_NAME = 'Approach Portal';

const APP_CONFIG = {
  localhost: {
    apiRoot: 'https://localhost:3000',
    approachApiRoot: 'https://localhost:3000',
    widgetsApiRoot: 'https://localhost:3000',
    widgetsApiKey: 'eVeRMVQZeD4DDDU0tdf9p5PfJzzFRE7j1cb110kw',
    emergePay: 'https://assets.emergepay-sandbox.chargeitpro.com/cip-hosted-fields.js',
    clearent: 'https://hpp-sb.clearent.net/js/clearent.js',
    fullSteam: 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js',
    aws: {
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_P2BNFF8dm',
      userPoolWebClientId: 'td3ub1ccl77d2mfh8j6r8rtrn',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    firebase: {
      apiKey: 'AIzaSyD8XgnPxHMGf0KS2ZWjTo8Vxa61ysQmwHM',
      authDomain: 'approach-dev.firebaseapp.com',
      databaseURL: 'https://approach-dev.firebaseio.com',
      projectId: 'approach-dev',
      storageBucket: 'approach-dev.appspot.com',
      messagingSenderId: '476730786973',
    },
    googleCaptchaKey: '6LfFVf4kAAAAALbljQ-XJnmMW-5NyIcZx0cDwIxe',
    termsAndConditions: 'https://approach.app/eula',
    privacyPolicy: 'https://approach.app/privacy',
  },
  testing: {
    apiRoot: {
      'us-east-1': 'https://portal.api.testing.tilefive.com',
      'ap-southeast-2': 'https://portal.api.au-d.tilefive.com',
    },
    approachApiRoot: 'https://app.api.testing.tilefive.com',
    widgetsApiRoot: {
      'us-east-1': 'https://widgets.api.testing.tilefive.com',
      'ap-southeast-2': 'https://widgets.api.au-d.tilefive.com',
    },
    widgetsApiKey: {
      'us-east-1': 'eVeRMVQZeD4DDDU0tdf9p5PfJzzFRE7j1cb110kw',
      'ap-southeast-2': '4hyQWnuWfR11lDNJqOsN5pL8Myy1A1x4CMekvMgd',
    },
    emergePay: 'https://assets.emergepay-sandbox.chargeitpro.com/cip-hosted-fields.js',
    clearent: 'https://hpp-sb.clearent.net/js/clearent.js',
    fullSteam: 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js',
    aws: {
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_P2BNFF8dm',
      userPoolWebClientId: 'td3ub1ccl77d2mfh8j6r8rtrn',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    firebase: {
      apiKey: 'AIzaSyD8XgnPxHMGf0KS2ZWjTo8Vxa61ysQmwHM',
      authDomain: 'approach-dev.firebaseapp.com',
      databaseURL: 'https://approach-dev.firebaseio.com',
      projectId: 'approach-dev',
      storageBucket: 'approach-dev.appspot.com',
      messagingSenderId: '476730786973',
    },
    googleCaptchaKey: '6LfFVf4kAAAAALbljQ-XJnmMW-5NyIcZx0cDwIxe',
    termsAndConditions: 'https://approach.app/eula',
    privacyPolicy: 'https://approach.app/privacy',
  },
  staging: {
    apiRoot: {
      'us-east-1': 'https://portal.api.staging.tilefive.com',
      'ap-southeast-2': 'https://portal.api.au-s.tilefive.com',
    },
    approachApiRoot: 'https://app.api.staging.tilefive.com',
    widgetsApiRoot: {
      'us-east-1': 'https://widgets.api.staging.tilefive.com',
      'ap-southeast-2': 'https://widgets.api.au-s.tilefive.com',
    },
    widgetsApiKey: {
      'us-east-1': 'fsHlriEoQY8oxluJLm3vp3nERawSTRQYapppB5mN',
      'ap-southeast-2': 'Se3u5UPEjc4HbqERg0GeD6b5xpFPvRtB1DrVALcN',
    },
    emergePay: 'https://assets.emergepay-sandbox.chargeitpro.com/cip-hosted-fields.js',
    clearent: 'https://hpp-sb.clearent.net/js/clearent.js',
    fullSteam: 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js',
    aws: {
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_cKgYnBOWk',
      userPoolWebClientId: '5uf74q6pnpkd41lcjn49l7kq2k',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    firebase: {
      apiKey: 'AIzaSyBkVhB9zG7OgFZUjBZB5tJJ-LLTcxZYYsI',
      authDomain: 'approach-stage.firebaseapp.com',
      databaseURL: 'https://approach-stage.firebaseio.com',
      projectId: 'approach-stage',
      storageBucket: 'approach-stage.appspot.com',
      messagingSenderId: '257264323242',
    },
    googleCaptchaKey: '6LdgDAQlAAAAAMxswJKYNkDP75oxB9bB3xL2Y5r6',
    termsAndConditions: 'https://approach.app/eula',
    privacyPolicy: 'https://approach.app/privacy',
  },
  production: {
    apiRoot: {
      'us-east-1': 'https://portal.api.prod.tilefive.com',
      'ap-southeast-2': 'https://portal.api.au-p.tilefive.com',
    },
    approachApiRoot: 'https://app.api.prod.tilefive.com',
    widgetsApiRoot: {
      'us-east-1': 'https://widgets.api.prod.tilefive.com',
      'ap-southeast-2': 'https://widgets.api.au-p.tilefive.com',
    },
    widgetsApiKey: {
      'us-east-1': 'OQ2z4Q3jSU1BW3y9dyfEW5FlEFu1ozIj7jE27qjy',
      'ap-southeast-2': 'mW0RQ7JFRV1iO7HKCe8wS8JdbTCmnGHT5bl3NyzP',
    },
    emergePay: 'https://assets.emergepay.chargeitpro.com/cip-hosted-fields.js',
    clearent: 'https://hpp.clearent.net/js/clearent.js',
    fullSteam: 'https://hostedpayments.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js',
    aws: {
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_x871NwuXM',
      userPoolWebClientId: 'jikhc095m6r9olu8rudg4gh5d',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    firebase: {
      apiKey: 'AIzaSyAbHcdTCEcSekzXcCGtoC3rXOL_N37VyFI',
      authDomain: 'approach-production.firebaseapp.com',
      databaseURL: 'https://approach-production.firebaseio.com',
      projectId: 'approach-production',
      storageBucket: 'approach-production.appspot.com',
      messagingSenderId: '414043972075',
    },
    googleCaptchaKey: '6LenhP4kAAAAAARkcdrsD3SS53ZedkJecpwDy4MM',
    termsAndConditions: 'https://approach.app/eula',
    privacyPolicy: 'https://approach.app/privacy',
  },
};

const getAppConfig = () => {
  const env = (process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

  if (env === 'production') return APP_CONFIG.production;
  if (env === 'test' || env === 'staging') return APP_CONFIG.staging;
  if (env === 'testing') return APP_CONFIG.testing;
  if (env === 'development') return APP_CONFIG.testing;

  return APP_CONFIG.localhost;
};

const AppConfig = getAppConfig();

export default AppConfig;
