import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
  ListItemButton, ListItemText, List,
} from '@mui/material';

import { ButtonSpinner, Spinner } from 'common/statusIndicators';
import { Note } from 'common/styledComponents';
import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';


class NewRequestDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      issueTypes: [],
      loading: true,
      selectedIssueType: null,
      sending: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadIssueTypes();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadIssueTypes = async () => {
    try {
      const { data: { data: issueTypes } } = await API.getIssueTypes(
        'page=0&pagesize=999&order=ASC&sort=name'
      );

      console.log('NewRequestDialog; loaded the following Issue Types: ', issueTypes);

      this.setState({
        issueTypes: issueTypes.filter((e) => e.isActive),
        loading: false,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.getIssueTypes error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  handleIssueTypeSelect = (event, index) => {
    const { issueTypes } = this.state;

    this.setState({ selectedIssueType: issueTypes[index] });
  };


  render() {
    const { t, open, issueTypesIdsInUse, onClose, classes, onSelect } = this.props;
    const { issueTypes, loading, selectedIssueType, sending } = this.state;

    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          {t('support.NewSupportRequest')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography paragraph>
            {t('support.SelectOneOfTheFollowingRequestTypes')}
          </Typography>
          <div className={`flex-1 mx-2 border ${classes.issueTypesContainer}`}>
            {loading ? (
              <Spinner />
            ) : issueTypes.length > 0 ? (
              issueTypes.map((issueType, index) => (
                <List key={`IT_${issueType.id}`}>
                  <ListItemButton
                    selected={issueType === selectedIssueType}
                    onClick={(event) => this.handleIssueTypeSelect(event, index)}
                    disabled={issueTypesIdsInUse.includes(issueType.id)}
                  >
                    <ListItemText primary={issueType.name} />
                  </ListItemButton>
                </List>
              ))
            ) : (
              <Typography
                paragraph
                className="m-1"
              >
                {t('support.SupportRequestAreNotInUse')}
              </Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions className="flex-column justify-content-start">
          <div>
            <Note text={t('common.YouCanOnlyHaveOneOpenRequest')} />
          </div>
          <div className="w-100 ml-0 mt-3 d-flex justify-content-end align-items-center">
            <Button
              onClick={onClose}
              disabled={loading}
            >
              {t('common.Cancel')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onSelect(selectedIssueType)}
              disabled={loading || !selectedIssueType}
            >
              {t('support.CreateRequest')}
              {sending && <ButtonSpinner />}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

NewRequestDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  issueTypesIdsInUse: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func,
};

NewRequestDialog.defaultProps = {
  issueTypesIdsInUse: [],
};


const styles = (theme) => ({
  issueTypesContainer: {
    backgroundColor: theme.palette.background.default,
  },
});

const NewRequestDialog2 = withStyles(NewRequestDialog, styles);


const NewRequestDialog3 = withTranslation()(NewRequestDialog2);


export default NewRequestDialog3;
