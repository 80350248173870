import { createReducer } from 'service/utility';

import { SET_INITIAL_ADD_TO_CART } from '../actions';


const initialState = false;

const initialAddToCart = createReducer(initialState, {
  [SET_INITIAL_ADD_TO_CART]: (state, { loading }) => loading,
});


export default initialAddToCart;
