import axios from 'axios';
import { Auth } from 'aws-amplify';

import { region as regionSelector } from 'app/redux/selectors';
import store from 'app/redux/store';
import APP_CONFIG from 'config/app';

export const getNamespace = () => {
  const { host, protocol, hostname } = window.location;

  if (host.indexOf('localhost') > -1 || protocol === 'file:' || hostname.match(
    /^192\.168(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}$/
  )) {
    return 'alpha1';
  }

  const bearer = host.split('.')[0];

  return bearer;
};

const getCognitoIdToken = async () => {
  const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();

  return jwt;
};


export const initAxiosRegion = async () => {
  const axiosConfig = {
    baseURL: `${APP_CONFIG.apiRoot['us-east-1']}`,
  };
  const instance = axios.create(axiosConfig);

  return instance;
};

export const initAxios = async (initCognito = true) => {
  const state = store.getState();
  const region = regionSelector(state);
  const axiosConfig = {
    baseURL: `${APP_CONFIG.apiRoot[region]}`,
  };

  if (initCognito) {
    const cognitoToken = await getCognitoIdToken();

    axiosConfig.headers = { Authorization: cognitoToken };
  }

  const instance = axios.create(axiosConfig);

  return instance;
};

export const initAxiosApproach = async () => {
  const namespace = getNamespace();
  const cognitoToken = await getCognitoIdToken();
  const axiosConfig = {
    baseURL: `${APP_CONFIG.approachApiRoot}`,
    headers: { Authorization: `${namespace} ${cognitoToken}` },
  };

  const instance = axios.create(axiosConfig);

  return instance;
};

export const initAxiosWidgets = async () => {
  const state = store.getState();
  const region = regionSelector(state);
  const axiosConfig = {
    baseURL: `${APP_CONFIG.widgetsApiRoot[region]}`,
    headers: {
      Authorization: getNamespace(),
      'X-Api-Key': APP_CONFIG.widgetsApiKey[region],
    },
  };

  const instance = axios.create(axiosConfig);

  return instance;
};

export const initAxiosForms = async () => {
  const state = store.getState();
  const region = regionSelector(state);
  const axiosConfig = {
    baseURL: `${APP_CONFIG.apiRoot[region]}`,
    headers: {
      Authorization: getNamespace(),
    },
  };

  const instance = axios.create(axiosConfig);

  return instance;
};
