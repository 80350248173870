import { combineReducers } from 'redux';

import { account } from './account';
import { confirmActionDialog } from './confirmActionDialog';
import { confirmProfileDialog } from './confirmProfileDialog';
import { customer } from './customer';
import { household } from './household';
import landingPage from './landingPage';
import lang from './lang';
import loadingCustomer from './loadingCustomer';
import { loadingLocationsFinished } from './loadingLocationsFinished';
import { location } from './location';
import { locations } from './locations';
import { purchaseDialog } from './purchaseDialog';
import region from './region';
import serviceWorker from './serviceWorker';
import settings from './settings';
import submittingProfile from './submittingProfile';
import submittingPurchase from './submittingPurchase';


const appReducer = combineReducers({
  account,
  confirmActionDialog,
  confirmProfileDialog,
  customer,
  household,
  landingPage,
  lang,
  loadingCustomer,
  loadingLocationsFinished,
  location,
  locations,
  region,
  purchaseDialog,
  serviceWorker,
  settings,
  submittingProfile,
  submittingPurchase,
});

export default appReducer;
