import { makeActionCreator } from 'service/utility';


export const CHANGE_LOCATION = 'CHANGE_LOCATION';
export const changeLocation = makeActionCreator(CHANGE_LOCATION, 'location');

export const CHANGE_LOCATION_BY_ID = 'CHANGE_LOCATION_BY_ID';
export const changeLocationById = makeActionCreator(CHANGE_LOCATION_BY_ID, 'locationId');


export const SET_REGION = 'SET_REGION';
export const setRegion = makeActionCreator(SET_REGION, 'region');

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const setAccount = makeActionCreator(SET_ACCOUNT, 'account');

export const SET_LOADING_CUSTOMER = 'SET_LOADING_CUSTOMER';
export const setLoadingCustomer = makeActionCreator(SET_LOADING_CUSTOMER, 'loading');

export const SET_CUSTOMER = 'SET_CUSTOMER';
export const setCustomer = makeActionCreator(SET_CUSTOMER, 'customer');

export const SET_HOUSEHOLD = 'SET_HOUSEHOLD';
export const setHousehold = makeActionCreator(SET_HOUSEHOLD, 'household');

export const SET_LOCATION = 'SET_LOCATION';
export const setLocation = makeActionCreator(SET_LOCATION, 'location');

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const setLocations = makeActionCreator(SET_LOCATIONS, 'locations');

export const SET_LANG = 'SET_LANG';
export const setLang = makeActionCreator(SET_LANG, 'lang');

export const SET_SETTINGS = 'SET_SETTINGS';
export const setSettings = makeActionCreator(SET_SETTINGS, 'settings');

export const SET_LANDING_PAGE = 'SET_LANDING_PAGE';
export const setLandingPage = makeActionCreator(SET_LANDING_PAGE, 'location');


export const CONFIRM_ACTION = 'CONFIRM_ACTION';
export const confirmAction = makeActionCreator(CONFIRM_ACTION, 'options');

export const SET_CONFIRM_ACTION_DIALOG = 'SET_CONFIRM_ACTION_DIALOG';
export const setConfirmActionDialog = makeActionCreator(SET_CONFIRM_ACTION_DIALOG, 'isOpen', 'options');

export const ACTION_CONFIRMED = 'ACTION_CONFIRMED';
export const actionConfirmed = makeActionCreator(ACTION_CONFIRMED);

export const ACTION_DENIED = 'ACTION_DENIED';
export const actionDenied = makeActionCreator(ACTION_DENIED);

export const ACTION_CANCELLED = 'ACTION_CANCELLED';
export const actionCancelled = makeActionCreator(ACTION_CANCELLED);


export const CONFIRM_PROFILE = 'CONFIRM_PROFILE';
export const confirmProfile = makeActionCreator(CONFIRM_PROFILE, 'customer', 'confirmCallback', 'history');

export const SET_CONFIRM_PROFILE_DIALOG = 'SET_CONFIRM_PROFILE_DIALOG';
export const setConfirmProfileDialog = makeActionCreator(SET_CONFIRM_PROFILE_DIALOG, 'isOpen', 'customer');

export const PROFILE_CONFIRMED = 'PROFILE_CONFIRMED';
export const profileConfirmed = makeActionCreator(PROFILE_CONFIRMED);

export const PROFILE_CANCELLED = 'PROFILE_CANCELLED';
export const profileCancelled = makeActionCreator(PROFILE_CANCELLED);

export const SET_PROFILE_SUBMITTING = 'SET_PROFILE_SUBMITTING';
export const setProfileSubmitting = makeActionCreator(SET_PROFILE_SUBMITTING, 'submitting');


export const CONFIRM_PURCHASE = 'CONFIRM_PURCHASE';
export const confirmPurchase = makeActionCreator(CONFIRM_PURCHASE, 'callback', 'options');

export const SET_PURCHASE_DIALOG = 'SET_PURCHASE_DIALOG';
export const setPurchaseDialog = makeActionCreator(SET_PURCHASE_DIALOG, 'isOpen', 'options');

export const PURCHASE_CONFIRMED = 'PURCHASE_CONFIRMED';
export const purchaseConfirmed = makeActionCreator(PURCHASE_CONFIRMED, 'choices');

export const PURCHASE_CANCELLED = 'PURCHASE_CANCELLED';
export const purchaseCancelled = makeActionCreator(PURCHASE_CANCELLED);

export const SET_PURCHASE_SUBMITTING = 'SET_PURCHASE_SUBMITTING';
export const setPurchaseSubmitting = makeActionCreator(SET_PURCHASE_SUBMITTING, 'submitting');


export const SW_INIT = 'SW_INIT';
export const swInit = makeActionCreator(SW_INIT);

export const SW_UPDATE = 'SW_UPDATE';
export const swUpdate = makeActionCreator(SW_UPDATE, 'payload');
