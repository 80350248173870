import { APP_NAME } from 'config/app';

const getKeyName = (key) => `@${APP_NAME}_${key}`;

const stateKey = getKeyName('state');


export const loadState = () => {
  const serializedState = localStorage.getItem(stateKey);
  const state = serializedState ? JSON.parse(serializedState) : {};

  return state;
};

export const saveState = (state) => {
  const serializedState = JSON.stringify({
    app: {
      account: state.app.account,
      customer: state.app.customer,
      household: state.app.household,
      lang: state.app.lang,
      location: state.app.location,
    },
    auth: {
      user: state.auth.user,
      temporaryEmail: state.auth.temporaryEmail,
      temporaryPassword: state.auth.temporaryPassword,
    },
    cart: {
      cartId: state.cart.cartId,
      temporaryCustomer: state.cart.temporaryCustomer,
    },
  });

  localStorage.setItem(stateKey, serializedState);
};
