import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import '@adyen/adyen-web/dist/adyen.css';
import 'dragula/dist/dragula.css';

import React from 'react';
import { PropTypes } from 'prop-types';
import { isEqual } from 'lodash';
import { BrowserRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { toast } from 'react-toastify';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';

import {
  account as accountSelector,
  serviceWorker as serviceWorkerSelector,
  settingsSelector,
} from 'app/redux/selectors';
import APP_CONFIG from 'config/app';
import THEME_CONFIG from 'config/theme';
import TOAST_CONFIG from 'config/toast';

import Initializer from './Initializer';
import Region from './Region';
import GlobalRoutes from './GlobalRoutes';

import { isLocalhost } from '../serviceWorkerRegistration';

Amplify.configure({
  Auth: APP_CONFIG.aws,
});
toast.configure(TOAST_CONFIG);


const muiCache = createCache({
  key: 'mui',
  prepend: true,
});


class App extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (!isEqual(this.props.account, nextProps.account)) {
      return true;
    }

    return false;
  }

  getTheme = () => {
    const { settings } = this.props;
    const theme = { ...THEME_CONFIG };

    if (settings.styles) {
      if (settings.styles.font) {
        const l = document.createElement('link');
        l.href = `https://fonts.googleapis.com/css2?family=${settings.styles.font}:wght@400;500&display=swap`;
        l.rel = 'stylesheet';
        document.head.appendChild(l);

        const s = document.createElement('style');
        const ff = `${settings.styles.font}, ${theme.typography.fontFamily}`;
        s.appendChild(document.createTextNode(`body { font-family: ${ff} }`));
        document.head.appendChild(s);

        theme.typography.fontFamily = ff;
      }
      if (settings.styles.fontSize) {
        const s = document.createElement('style');
        s.appendChild(document.createTextNode(`html { font-size: ${settings.styles.fontSize}px }`));
        document.head.appendChild(s);

        theme.typography.htmlFontSize = settings.styles.fontSize;
      }
      if (settings.styles.headerBackgroundColor) {
        theme.palette.background.header = settings.styles.headerBackgroundColor;
      }
      if (settings.styles.backgroundImageUrl) {
        theme.palette.background.imageURL = settings.styles.backgroundImageUrl;
      }
      if (settings.styles.stickyHeaderBackgroundColor) {
        theme.palette.background.stickytop = settings.styles.stickyHeaderBackgroundColor;
      }
      if (settings.styles.textColor) {
        const s = document.createElement('style');
        s.appendChild(document.createTextNode(`body { color: ${settings.styles.textColor} }`));
        document.head.appendChild(s);

        theme.palette.text.primary = settings.styles.textColor;
        // theme.components.MuiButton.styleOverrides.contained.color = settings.styles.textColor;
        theme.components.MuiLink.styleOverrides.root.color = settings.styles.textColor;
        theme.components.MuiListItemText.styleOverrides.primary.color = settings.styles.textColor;
        theme.components.MuiListItemText.styleOverrides.secondary.color = settings.styles.textColor;
        theme.components.MuiFormLabel.styleOverrides.root.color = settings.styles.textColor;
        theme.components.MuiListSubheader.styleOverrides.root.color = settings.styles.textColor;
        // eslint-disable-next-line max-len
        theme.components.MuiLoadingButton.styleOverrides.loadingIndicator.color = settings.styles.textColor;
      }
      if (settings.styles.primaryColor) {
        theme.palette.primary.main = settings.styles.primaryColor;
      }
      if (settings.styles.primaryContrastTextColor) {
        theme.palette.primary.contrastText = settings.styles.primaryContrastTextColor;
      }
      if (settings.styles.secondaryColor) {
        theme.palette.secondary.main = settings.styles.secondaryColor;
      }
      if (settings.styles.paperColor) {
        theme.palette.background.paper = settings.styles.paperColor;
        theme.palette.background.default = settings.styles.paperColor;
        theme.components.MuiLoadingButton.styleOverrides.root['&:hover'].backgroundColor = settings.styles.paperColor;
      }
      if (settings.styles.sectionIconBackgroundColor) {
        theme.palette.background.sectionIcon = settings.styles.sectionIconBackgroundColor;
      }
      if (settings.styles.buttonBorderRadius) {
        theme.shape.buttonBorderRadius = settings.styles.buttonBorderRadius;
        theme.components.MuiButton.styleOverrides.root.borderRadius = settings.styles.buttonBorderRadius;
      }
      if (settings.styles.backgroundColor) {
        theme.palette.background.default = settings.styles.backgroundColor;
      }
    }


    const theme2 = createTheme(theme);
    const theme3 = responsiveFontSizes(theme2);

    return theme3;
  }

  render() {
    return (
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={this.getTheme()}>
          <BrowserRouter>
            <Region>
              <Initializer>
                <GlobalRoutes />
              </Initializer>
            </Region>
          </BrowserRouter>
        </ThemeProvider>
      </CacheProvider>
    );
  }
}

App.propTypes = {
  account: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  account: accountSelector(state),
  settings: settingsSelector(state),
});

const App2 = connect(mapStateToProps)(App);


const App3 = () => {
  const serviceWorker = useSelector(serviceWorkerSelector);

  if (isLocalhost) {
    window.location.href = 'http://alpha1.portal.testing.tilefive.com:3000/';
  }

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorker.registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  if (serviceWorker.initialized) {
    console.log('App: Service Worker is initialized for the first time');
  }

  if (serviceWorker.updated) {
    updateServiceWorker();

    return null;
  }

  return (
    <App2 />
  );
};


export default App3;
