import { createReducer } from 'service/utility';

import { SET_PURCHASE_SUBMITTING } from '../actions';


const initialState = false;

const submittingPurchase = createReducer(initialState, {
  [SET_PURCHASE_SUBMITTING]: (state, { submitting }) => submitting,
});

export default submittingPurchase;
