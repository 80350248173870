import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { FormHelperText } from '@mui/material';

import { isRequired, isInvalid, cannotBeInTheFuture } from 'service/utility/errorMessages';
import * as timeUtils from 'service/utility/timeUtils';

import DayField from './DayField';
import MonthField from './MonthField';
import YearField from './YearField';


class BirthdateField extends React.PureComponent {
  componentDidMount() {
    const { value, onChange } = this.props;

    if (onChange) {
      const error = this.validateSelf(value);

      if (error) {
        onChange({ error });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value, onChange } = this.props;

      if (onChange) {
        const error = this.validateSelf(value);

        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }


  validateSelf = (value) => {
    const { required, label, validate } = this.props;

    if (timeUtils.isEmptyOrIncompleteIso8601Date(value) && required) {
      return isRequired(label);
    }

    if (Boolean(value) && timeUtils.isIso8601Date(value) && !timeUtils.isValidIso8601Date(value)) {
      return isInvalid(label);
    }

    if (!timeUtils.isInThePast(value)) {
      return cannotBeInTheFuture(label);
    }

    return validate ? validate(value) : null;
  };


  handleChange = (varName) => (update) => {
    const { onChange, value: rawValue } = this.props;

    if (!onChange || !update.hasOwnProperty('value')) return;

    const compositeUpdate = {};
    const value = rawValue || timeUtils.EMPTY_ISO8601_DATE;
    const payload = {
      year: Number(timeUtils.iso8601Year(value)) || null,
      month: Number(timeUtils.iso8601Month(value)) || null,
      day: Number(timeUtils.iso8601Day(value)) || null,
    };

    payload[varName] = update.value;
    compositeUpdate.value = timeUtils.buildIso8601Date(payload.day, payload.month, payload.year);

    onChange(compositeUpdate);
  };

  handleYearChange = this.handleChange('year');

  handleMonthChange = this.handleChange('month');

  handleDayChange = this.handleChange('day');


  getYMDComponent = (ymd) => {
    const { value: rawValue, disabled, required } = this.props;
    const value = rawValue || timeUtils.EMPTY_ISO8601_DATE;

    if (ymd === 'Y') {
      const year = Number(timeUtils.iso8601Year(value)) || null;

      return (
        <YearField
          value={year}
          onChange={this.handleYearChange}
          disabled={disabled}
          required={required}
        />
      );
    }

    if (ymd === 'M') {
      const month = Number(timeUtils.iso8601Month(value)) || null;

      return (
        <MonthField
          value={month}
          onChange={this.handleMonthChange}
          disabled={disabled}
          required={required}
        />
      );
    }

    if (ymd === 'D') {
      const day = Number(timeUtils.iso8601Day(value)) || null;

      return (
        <DayField
          value={day}
          onChange={this.handleDayChange}
          disabled={disabled}
          required={required}
        />
      );
    }

    return null;
  };


  render() {
    const { className, error } = this.props;

    const ymd = timeUtils.getShortDateYMD();

    return (
      <div className={classnames('birthdate-field', { [className]: className })}>
        {this.getYMDComponent(ymd[0])}
        {this.getYMDComponent(ymd[1])}
        {this.getYMDComponent(ymd[2])}
        {error && (
          <FormHelperText variant="outlined">
            {error}
          </FormHelperText>
        )}
      </div>
    );
  }
}

BirthdateField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.string,
};

BirthdateField.defaultProps = {
  disabled: false,
  error: '',
  required: false,
  value: '',
};


export default BirthdateField;
