import React from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem } from '@mui/material';


const StateSearchItem = ({ children, isFocused, innerProps: { onClick } }) => (
  <MenuItem
    selected={isFocused}
    onClick={onClick}
  >
    {children}
  </MenuItem>
);

StateSearchItem.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
};


export default StateSearchItem;
