import React from 'react';
import { PropTypes } from 'prop-types';
import { components } from 'react-select';
import { withStyles } from 'tss-react/mui';


const ValueContainer_ = ({ children, classes, ...rest }) => (
  <components.ValueContainer
    {...rest}
    className={classes.valueContainer}
  >
    {children}
  </components.ValueContainer>
);

ValueContainer_.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
};


const styles = {
  valueContainer: {
    paddingLeft: '12px !important',
  },
};

const ValueContainer = withStyles(ValueContainer_, styles);


export default ValueContainer;
