import {
  location as locationSelector,
  settingsSelector,
} from 'app/redux/selectors';
import { isAssignableItemType } from 'config/itemTypes';
import { getCurrentLocationTimezone } from 'service/i18n';
import { isSignupFee } from 'service/utility/cart';
import * as timeUtils from 'service/utility/timeUtils';

export const isCartLoading = (state) => state.cart.loading;
export const cartIdSelector = (state) => state.cart.cartId;
export const cartSelector = (state) => state.cart.cart;
export const cart2Totals = (cart) => (cart && cart.totals) || {};
export const cartTotalsSelector = (state) => cart2Totals(cartSelector(state));
export const totals2Items = (totals) => (totals && totals.items) || [];
export const cartItemsSelector = (state) => totals2Items(cartTotalsSelector(state));
export const cart2ItemsSelector = (cart) => totals2Items(cart2Totals(cart));
export const cartItemCount = (state) => cartItemsSelector(state).filter(
  (item) => !isSignupFee(item)
).length;
export const termsNotAgreed = (state) => (
  settingsSelector(state).promptForTAC
    ? cartItemsSelector(state).some((item) => (
      item.type !== 'FEE' && Boolean(item.typeInfo?.termsConditions) && !item.termsAndConditionsAgreed
    ))
    : false
);
export const cartHasAssignableItemsSelector = (state) => cartItemsSelector(state).some(
  (item) => isAssignableItemType(item.type)
);
export const cartItemsNotAssigned = (state) => cartItemsSelector(state).some(
  (item) => isAssignableItemType(item.type) && !item.assignedCustomerId
);
export const itemsRequireCreditCard = (state) => cartItemsSelector(state).some(
  (item) => item.type === 'MEMBERSHIP' && item.membershipType.isRecurring
);
export const canPayWithGiftCardSelector = (state) => (
  cartItemsSelector(state).every((item) => item.type !== 'GIFT_CARD')
);
export const paymentStatusSelector = (state) => state.cart.paymentStatus;
export const transactionInProgress = (state) => paymentStatusSelector(state).started;
export const temporaryCustomerSelector = (state) => state.cart.temporaryCustomer;
export const cartHasValidSessionSelector = (state) => {
  const currentLocation = locationSelector(state);
  const timeZone = getCurrentLocationTimezone(currentLocation);
  const cart = cartSelector(state);
  const { sessionId, sessionEnd } = cart;

  return !(
    !sessionId ||
    (Boolean(sessionId) && Boolean(sessionEnd) && timeUtils.isInThePast(sessionEnd, timeZone))
  );
};
export const cartPromoCodeSelector = (state) => cartSelector(state).promocode;
export const cartGiftCardSelector = (state) => cartSelector(state).giftcard;
export const cartSessionIdSelector = (state) => cartSelector(state).sessionId;
export const initialAddToCartSelector = (state) => state.cart.initialAddToCart;
export const initialAddToCartFinishedSelector = (state) => state.cart.initialAddToCartFinished;
export const giftCardAmountUsedSelector = (state) => {
  const cartTotals = cartTotalsSelector(state);
  const cartGiftCard = cartGiftCardSelector(state);

  const { total = 0 } = cartTotals;
  const giftCardAmountUsed = cartGiftCard ? Math.min(cartGiftCard.balance, total) : 0;

  return giftCardAmountUsed;
};
export const cardAmountDueSelector = (state) => {
  const cartTotals = cartTotalsSelector(state);
  const giftCardAmountUsed = giftCardAmountUsedSelector(state);

  const { total = 0 } = cartTotals;
  const cardAmountDue = total - giftCardAmountUsed;

  return cardAmountDue;
};
export const canFinishDirectlySelector = (state) => (
  !cardAmountDueSelector(state) && !itemsRequireCreditCard(state)
);
