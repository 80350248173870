import React from 'react';
import { PropTypes } from 'prop-types';


const StatusOverlay = ({ children }) => (
  <div className="status-overlay">
    {children}
  </div>
);

StatusOverlay.propTypes = {
  children: PropTypes.any,
};


export default StatusOverlay;
