import { call, put, select, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { cartIdProp } from 'config/models';
import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { loadCart } from './cartSagas';
import { UPDATE_ITEM_QUANTITY, setCartLoading } from '../actions';
import { cartIdSelector } from '../selectors';


export function *updateItemQuantitySaga() {
  while (true) {
    const { itemId, quantity } = yield take(UPDATE_ITEM_QUANTITY);
    const cartId = yield select(cartIdSelector);

    if (!cartId || !itemId || typeof quantity !== 'number') return null;

    console.log(`Updating the quantity to ${quantity} for item with id = ${itemId}
      in the cart with ${cartIdProp} = ${cartId} :`);

    const toast1 = toast('Updating quantity...');

    try {
      yield put(setCartLoading(true));
      yield call(API.updateItemQuantity, cartId, itemId, quantity);
      yield call(loadCart, cartId);

      toast.dismiss(toast1);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('updateItemQuantitySaga error: ');
      console.log(errorMessage);

      toast.dismiss(toast1);
      toast.error(errorMessage);
    } finally {
      yield put(setCartLoading(false));
    }
  }
}
