import { call, put, select, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { cartIdProp } from 'config/models';
import * as NAVIGATION from 'config/navigation';
import { navigateTo } from 'service/navigation';

import { createNewCart, putItemsInCart } from './cartSagas';
import { PUT_ITEMS_IN_CART, setCartLoading } from '../actions';
import { cartIdSelector } from '../selectors';


export function *putItemsInCartSaga() {
  while (true) {
    const { items, history } = yield take(PUT_ITEMS_IN_CART);

    console.log('putItemsInCartSaga; items = ', items);

    if (!items) return null;

    yield put(setCartLoading(true));
    let cartId = yield select(cartIdSelector);

    //If there is no current cartId, it's probably because one hasn't been created yet, so we create one.
    if (!cartId) {
      const newCart = yield call(createNewCart);

      // If newCart is null it's because createNewCart failed with an error, so we return.
      if (!newCart) return null;

      cartId = newCart[cartIdProp];
    }

    const toast1 = toast('Adding to cart...');
    const success = yield call(putItemsInCart, cartId, items);
    toast.dismiss(toast1);
    yield put(setCartLoading(false));

    if (success) {
      yield call(navigateTo, history, NAVIGATION.CART);
    }
  }
}
