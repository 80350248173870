import {
  getSubtotalProp, getCartItemSubtotalProp, getItemPrice,
} from 'service/utility/pricing';

import { getCurrentCurrencyCode } from './i18n';


export const loadGoogleTagManager = (gtmID) => {
  const script = document.createElement('script');
  const scriptContent = document.createTextNode(`
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmID}');
    `);
  script.appendChild(scriptContent);
  document.head.appendChild(script);

  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmID}`;
  iframe.height = 0;
  iframe.width = 0;
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);

  document.body.appendChild(noscript);
};

const getCartItem = (cartItem, location, account) => {
  const item = {
    item_id: cartItem.type,
    item_name: '',
    item_category: '',
    price: 0,
    quantity: cartItem.quantity,
  };

  switch (cartItem.type) {
    case 'MEMBERSHIP':
      item.item_id = `${cartItem.type}#${cartItem.membershipType.id}`;
      item.item_name = cartItem.membershipType.name;
      item.item_category = cartItem.membershipType.revenueCategory
        ? `${cartItem.membershipType.revenueCategory.name}#${cartItem.membershipType.revenueCategory.id}`
        : null;
      item.price = getItemPrice(cartItem.membershipType, location, account);
      break;
    case 'FEE':
      item.item_name = cartItem.options?.description ?? cartItem.description;
      item.item_category = cartItem.membershipType.revenueCategory
        ? `${cartItem.membershipType.revenueCategory.name}#${cartItem.membershipType.revenueCategory.id}`
        : null;
      item.price = cartItem[getCartItemSubtotalProp(account)];
      break;
    case 'PASS':
      item.item_id = `${cartItem.type}#${cartItem.passType.id}`;
      item.item_name = cartItem.passType.name;
      item.item_category = cartItem.passType.revenueCategory
        ? `${cartItem.passType.revenueCategory.name}#${cartItem.passType.revenueCategory.id}`
        : null;
      item.price = getItemPrice(cartItem.passType, location, account);
      break;
    case 'GIFT_CARD':
      if (cartItem.giftCardType) {
        item.item_id = `${cartItem.type}#${cartItem.giftCardType.id}`;
      }
      item.item_name = cartItem.giftCardType ? cartItem.giftCardType.name : 'Gift Cards';
      item.item_category = 'Gift Cards';
      item.price = getItemPrice(cartItem.giftCardType || cartItem, location, account);
      break;
    case 'EVENT_BOOKING_INVOICE':
      item.item_id = `${cartItem.type}#${cartItem.pricingTier.id}`;
      item.item_name = cartItem.pricingTier.name;
      item.item_category = cartItem.pricingTier.revenueCategory
        ? `${cartItem.pricingTier.revenueCategory.name}#${cartItem.pricingTier.revenueCategory.id}`
        : null;
      item.price = cartItem[getCartItemSubtotalProp(account)];
      break;
    case 'EVENT_BOOKING_TICKET':
      item.item_id = `${cartItem.type}#${cartItem.ticketType.id}`;
      item.item_name = cartItem.ticketType.name;
      item.item_category = cartItem.ticketType.revenueCategory
        ? `${cartItem.ticketType.revenueCategory.name}#${cartItem.ticketType.revenueCategory.id}`
        : null;
      item.price = getItemPrice(cartItem.ticketType, location, account);
      break;
    default:
      break;
  }

  return item;
};

const getCartPayload = (totals, items, location, account) => ({
  currency: getCurrentCurrencyCode(account),
  value: totals[getSubtotalProp(account)],
  items: items.map((item) => getCartItem(item, location, account)),
});

const pushToDataLayer = (event, ecommerce) => {
  if (Array.isArray(window.dataLayer)) {
    window.dataLayer.push({ event, ecommerce });
  }
};

export const addAddToCartTag = (items, location, account) => {
  const items2 = items.map((item) => getCartItem(item, location, account));
  const payload = {
    value: items2.reduce((a, b) => a + b.price * b.quantity, 0),
    currency: getCurrentCurrencyCode(account),
    items: items2,
  };

  pushToDataLayer('add_to_cart', payload);
};

export const addCheckOutCartTag = (totals, items, location, account) => {
  const payload = getCartPayload(totals, items, location, account);
  pushToDataLayer('checkout', payload);
};

export const addLoginTag = () => {
  pushToDataLayer('login');
};

export const addPurchaseTag = (totals, orderId, location, account) => {
  const payload = {
    transaction: `#${orderId}`,
    currency: getCurrentCurrencyCode(account),
    value: totals[getSubtotalProp(account)],
    tax: totals.totalTax,
    coupon: totals.promocodeId,
    items: totals.items.map((item) => getCartItem(item, location, account)),
  };
  pushToDataLayer('purchase', payload);
};

export const addRemoveFromCartTag = (removedItems, location, account) => {
  const payload = {
    currency: getCurrentCurrencyCode(account),
    items: removedItems.map((item) => getCartItem(item, location, account)),
  };
  pushToDataLayer('remove_from_cart', payload);
};

export const addViewCartTag = (totals, items, location, account) => {
  const payload = (
    items.length
      ? getCartPayload(totals, items, location, account)
      : null
  );
  pushToDataLayer('view_cart', payload);
};
