import { take, call, select } from 'redux-saga/effects';

import { CREATE_CART_SESSION } from '../actions';
import { cartSelector } from '../selectors';
import { createCartSession } from './cartSagas';


export function *createCartSessionSaga() {
  while (true) {
    yield take(CREATE_CART_SESSION);

    const cart = yield select(cartSelector);
    console.log('createCartSessionSaga; cart = ', cart);

    yield call(createCartSession, cart);
  }
}
