import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';

import { account as accountSelector } from 'app/redux/selectors';
import { COUNTRY_PICKLIST_VALUES } from 'config/countries';
import { getCurrentCountryCode } from 'service/i18n';

import PicklistField from '../PicklistField';


class CountryField extends React.PureComponent {
  componentDidMount() {
    const currentCountryCode = getCurrentCountryCode(this.props.account);

    if (currentCountryCode && !this.props.value) {
      this.props.onChange({ value: currentCountryCode });
    }
  }

  render() {
    const { t, tReady, i18n, account, dispatch, className, label, ...rest } = this.props;

    const label2 = label || t('common.Country');
    const countryFieldClassName = classnames('country-field', { [className]: Boolean(className) });
    const FlagIcon = this.props.value ? require('country-flag-icons/react/3x2')[this.props.value] : null;

    return (
      <PicklistField
        {...rest}
        label={label2}
        picklistValues={COUNTRY_PICKLIST_VALUES}
        className={countryFieldClassName}
        InputProps={{
          startAdornment: FlagIcon && (
            <InputAdornment
              position="start"
              className="flag-wrapper"
            >
              <FlagIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

CountryField.propTypes = {
  account: PropTypes.object,
  className: PropTypes.string,
  dispatch: PropTypes.func,
  i18n: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  t: PropTypes.func,
  tReady: PropTypes.bool,
  value: PropTypes.string,
};

CountryField.defaultProps = {
  value: '',
};


const mapStateToProps = (state) => ({
  account: accountSelector(state),
});

const CountryField2 = connect(mapStateToProps)(CountryField);


const CountryField3 = withTranslation()(CountryField2);


export default CountryField3;
