import i18n from 'config/i18n';


export const isRequired = (field) => (
  i18n.t('errors.isRequired', { field: field || i18n.t('common.This') })
);

export const noNegative = (field) => (
  i18n.t('errors.noNegative', { field: field || i18n.t('common.Value') })
);

export const noZero = (field) => (
  i18n.t('errors.noZero', { field: field || i18n.t('common.Value') })
);

export const isInvalid = (field) => (
  field ? i18n.t('errors.isInvalid', { field }) : i18n.t('errors.Invalidvalue')
);

export const invalidEmail = () => (
  i18n.t('errors.Invalidemail')
);

export const invalidPhone = () => (
  i18n.t('errors.Invalidphonenumber')
);

export const noDigits = () => (
  i18n.t('errors.NameCannotContainDigits')
);

export const noEmpty = (field) => (
  i18n.t('errors.cannotBeEmpty', { field: field || i18n.t('common.Value') })
);

export const mustBeBetween = (min, max) => (
  i18n.t('errors.mustBeBetween', { min, max })
);

export const mustBeAdult = (field) => (
  i18n.t('errors.mustBeAdult', { field: field || i18n.t('common.Person') })
);

export const cannotBeInTheFuture = (field) => (
  i18n.t('errors.cannotBeInTheFuture', { field: field || i18n.t('common.Value') })
);
