import { FLAT_DISCOUNT, PERCENT_DISCOUNT } from 'config/discounts';
import i18n from 'config/i18n';
import {
  getCurrentLocale, getCurrentCurrencyCode, usesPriceBeforeTax, getCurrentCurrencyDecimalPlaces,
} from 'service/i18n';
import { op, isNullish } from 'service/utility';
import { roundTo2Decimals, formatPercent } from 'service/utility/numbers';


export const getPriceProp = (account) => (
  usesPriceBeforeTax(account)
    ? 'price'
    : 'taxInclusivePrice'
);

export const getSignupFeeProp = (account) => (
  usesPriceBeforeTax(account)
    ? 'signupFee'
    : 'taxInclusiveSignupFee'
);

export const getDiscountPercentageProp = (account) => (
  usesPriceBeforeTax(account)
    ? 'discountPercentage'
    : 'taxInclusiveDiscountPercentage'
);

export const getDiscountFlatProp = (account) => (
  usesPriceBeforeTax(account)
    ? 'discountFlat'
    : 'taxInclusiveDiscountFlat'
);

export const getDiscountAmountProp = (account) => (
  usesPriceBeforeTax(account)
    ? 'discountAmount'
    : 'taxInclusiveDiscountAmount'
);

export const getCartItemSubtotalProp = (account) => (
  usesPriceBeforeTax(account)
    ? 'subtotal'
    : 'total'
);

export const getSubtotalProp = (account) => (
  usesPriceBeforeTax(account)
    ? 'subtotal'
    : 'taxInclusiveSubtotal'
);

export const getTotalDiscountProp = (account) => (
  usesPriceBeforeTax(account)
    ? 'totalDiscount'
    : 'taxInclusiveTotalDiscount'
);


export const formatPriceWithoutTax = (value, account, options = {}) => {
  if (isNullish(value)) return '--';

  const number = Number(value);
  const locale = getCurrentLocale(account);
  const currencyCode = options.currency || getCurrentCurrencyCode(account);
  const currencyDecimalPlaces = (
    Object.prototype.hasOwnProperty.call(options, 'decimalPlaces')
      ? options.decimalPlaces
      : getCurrentCurrencyDecimalPlaces(account)
  );

  return new Intl.NumberFormat(
    locale,
    { style: 'currency', currency: currencyCode, minimumFractionDigits: currencyDecimalPlaces }
  ).format(number);
};

export const getCumulativeTaxRate = (item, location) => {
  const isTaxExempt = op(item, 'isTaxExempt', false);
  const locationLocaleTaxRate = op(location, 'localeTax', 0);
  const itemLocaleTaxRate = op(item, 'localeTaxRate', locationLocaleTaxRate);
  const locationStateTaxRate = op(location, 'stateTax', 0);
  const itemStateTaxRate = op(item, 'stateTaxRate', locationStateTaxRate);
  const locationCityTaxRate = op(location, 'cityTax', 0);
  const itemCityTaxRate = op(item, 'cityTaxRate', locationCityTaxRate);
  const locationAdditionalTaxRate = op(location, 'additionalTax', 0);
  const itemAdditionalTaxRate = op(item, 'additionalTaxRate', locationAdditionalTaxRate);
  const taxRate = (
    isTaxExempt
      ? 0
      : (
        Number(itemLocaleTaxRate) + Number(itemStateTaxRate) +
        Number(itemCityTaxRate) + Number(itemAdditionalTaxRate)
      )
  );

  return taxRate;
};

export const calculatePriceWithTax = (value, item, location) => {
  if (isNullish(value)) return null;

  const taxRate = getCumulativeTaxRate(item, location);
  const valueWithTax = roundTo2Decimals(Number(value) * (100 + taxRate) / 100);

  return valueWithTax;
};

export const formatPriceWithTax = (value, item, location, account) => {
  const valueWithTax = calculatePriceWithTax(value, item, location);

  return formatPriceWithoutTax(valueWithTax, account);
};

export const formatPrice = (value, item, location, account) => {
  if (isNullish(value)) return '--';

  return (
    usesPriceBeforeTax(account)
      ? formatPriceWithoutTax(value, account)
      : formatPriceWithTax(value, item, location, account)
  );
};

export const getItemPrice = (item, location, account) => {
  if (usesPriceBeforeTax(account)) {
    return item.price;
  }

  if (
    Object.prototype.hasOwnProperty.call(item, 'taxInclusivePrice') &&
    item.taxInclusivePrice !== null
  ) {
    return item.taxInclusivePrice;
  }

  return calculatePriceWithTax(item.price, item, location);
};

export const getItemValue = (item, location, account) => {
  if (usesPriceBeforeTax(account)) {
    return item.value;
  }

  if (
    Object.prototype.hasOwnProperty.call(item, 'taxInclusiveValue') &&
    item.taxInclusiveValue !== null
  ) {
    return item.taxInclusiveValue;
  }

  return calculatePriceWithTax(item.value, item, location);
};

export const formatItemPrice = (item, location, account) => {
  const itemPrice = getItemPrice(item, location, account);

  return formatPriceWithoutTax(itemPrice, account);
};

export const formatItemValue = (item, location, account) => {
  const itemValue = getItemValue(item, location, account);

  return formatPriceWithoutTax(itemValue, account);
};

export const getItemDiscountType = (item) => (
  op(item, 'discountFlat')
    ? FLAT_DISCOUNT
    : op(item, 'discountPercentage')
      ? PERCENT_DISCOUNT
      : null
);

export const formatItemDiscount = (item) => (
  op(item, 'discountFlat')
    ? formatPriceWithoutTax(item.discountFlat)
    : op(item, 'discountPercentage')
      ? formatPercent(item.discountPercentage)
      : null
);

export const getItemDiscountText = (item) => {
  const discountType = getItemDiscountType(item);

  if (!discountType) return null;

  return i18n.t(
    discountType === FLAT_DISCOUNT ? 'cart.XFlatOff' : 'cart.XPercentOff',
    { value: formatItemDiscount(item) }
  );
};

export const getItemDiscountPercentageProp = (item, itemType, account) => {
  if (usesPriceBeforeTax(account)) {
    return 'discountPercentage';
  }

  if (Object.prototype.hasOwnProperty.call(itemType, 'taxInclusivePrice') && itemType.taxInclusivePrice !== null) {
    return 'taxInclusiveDiscountPercentage';
  }

  return 'discountPercentage';
};

export const getItemDiscountFlatProp = (item, itemType, account) => {
  if (usesPriceBeforeTax(account)) {
    return 'discountFlat';
  }

  if (Object.prototype.hasOwnProperty.call(itemType, 'taxInclusivePrice') && itemType.taxInclusivePrice !== null) {
    return 'taxInclusiveDiscountFlat';
  }

  return 'discountFlat';
};

export const getItemDiscountPercentage = (item, itemType, account) => (
  item[getItemDiscountPercentageProp(item, itemType, account)]
);

export const getItemDiscountFlat = (item, itemType, account) => (
  item[getItemDiscountFlatProp(item, itemType, account)]
);

export const getPBTMembershipDiscountText = (membership, account) => {
  if (membership.discountFlat) {
    return formatPriceWithoutTax(membership.discountFlat, account);
  }

  if (membership.discountPercentage) {
    return formatPercent(membership.discountPercentage);
  }

  return i18n.t('common.None');
};

export const getMembershipDiscountText = (membership, account) => {
  if (usesPriceBeforeTax(account)) {
    return getPBTMembershipDiscountText(membership, account);
  }

  if (membership.taxInclusiveDiscountFlat) {
    return formatPriceWithoutTax(membership.taxInclusiveDiscountFlat, account);
  }

  if (membership.taxInclusiveDiscountPercentage) {
    return formatPercent(membership.taxInclusiveDiscountPercentage);
  }

  return getPBTMembershipDiscountText(membership, account);
};

export const getHoldFeeProp = (account) => (
  usesPriceBeforeTax(account)
    ? 'onHoldMonthlyDues'
    : 'taxInclusiveHoldFee'
);

export const getMembershipTypeHoldFee = (membershipType, account) => (
  membershipType[getHoldFeeProp(account)]
);
