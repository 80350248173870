import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PrivateRoute } from 'common/navigation';
import { SuspenseFallback } from 'common/statusIndicators';
import * as NAVIGATION from 'config/navigation';


const Checkout = React.lazy(() => import('./checkout'));
const Routes = React.lazy(() => import('./Routes'));
const SignDocument = React.lazy(() => import('./signDocument'));
const Survey = React.lazy(() => import('./survey'));
const ThankYou = React.lazy(() => import('./thankyou'));
const ManageSubscription = React.lazy(() => import('./manageSubscription'));


const GlobalRoutes = () => (
  <React.Suspense fallback={<SuspenseFallback />}>
    <Switch>
      <Route
        path={NAVIGATION.SURVEY}
        component={Survey}
      />
      <Route
        path={NAVIGATION.MANAGE_SUBSCRIPTION}
        component={ManageSubscription}
      />
      <PrivateRoute
        path={NAVIGATION.SIGN_DOCUMENT}
        component={SignDocument}
      />
      <Route
        path={NAVIGATION.CHECKOUT}
        component={Checkout}
      />
      <Route
        path={NAVIGATION.THANK_YOU}
        component={ThankYou}
      />
      <Route
        path={NAVIGATION.HOME}
        component={Routes}
      />
    </Switch>
  </React.Suspense>
);


export default GlobalRoutes;
