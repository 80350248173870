import { toast } from 'react-toastify';
import { take, call, put, select } from 'redux-saga/effects';

import { setCart, setCartLoading } from 'app/cart/redux/actions';
import { cartSelector, cart2ItemsSelector } from 'app/cart/redux/selectors';
import { assignCart as assignCartSaga } from 'app/cart/redux/sagas';
import {
  setProfileSubmitting, setLoadingCustomer, setCustomer, confirmProfile, setHousehold,
} from 'app/redux/actions';
import { location as locationSelector } from 'app/redux/selectors';
import * as NAVIGATION from 'config/navigation';
import * as API from 'service/api';
import { navigateTo } from 'service/navigation';
import { getErrorMessage } from 'service/utility';
import { isExistingNonPortalCustomer } from 'service/utility/customer';

import { SIGN_IN, setCurrentUser, setAuthFinished } from '../actions';


function *onProfileConfirm(customer, history) {
  yield put(setProfileSubmitting(true));

  const { source, ...payload } = customer;
  const { data: updatedCustomer } = yield call(API.putCustomer, payload);

  console.log('Updated customer: ', updatedCustomer);
  yield put(setCustomer(updatedCustomer));

  yield put(setProfileSubmitting(false));
  yield call(navigateTo, history, NAVIGATION.PROFILE);
}

export function *signInSaga() {
  while (true) {
    // User
    const { user, history } = yield take(SIGN_IN);

    console.log('Setting current user to ', user);
    yield put(setLoadingCustomer(true));
    yield put(setCurrentUser(user));


    // Customer
    let customer = {};

    try {
      const { data: customer_ } = yield call(API.getCustomer);

      customer = customer_;
      console.log('*signInSaga; customer: ', customer);
    } catch (error) {
      if (error.response.status !== 404) {
        const errorMessage = getErrorMessage(error);

        console.log('API.getCustomer error: ');
        console.log(errorMessage);

        toast.error(errorMessage);
      }
    } finally {
      yield put(setLoadingCustomer(false));
    }

    yield put(setCustomer(customer));

    if (isExistingNonPortalCustomer(customer)) {
      yield put(confirmProfile(customer, onProfileConfirm, history));
    }


    // Household
    let household = [];

    try {
      const { data: household_ } = yield call(API.getHousehold);

      household = household_;
      console.log('*signInSaga; household: ', household);
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        const errorMessage = getErrorMessage(error);

        console.log('API.getHousehold error: ');
        console.log(errorMessage);

        toast.error(errorMessage);
      }
    }

    yield put(setHousehold(household));


    // Cart
    let signedInCart;
    const currentLocation = yield select(locationSelector);
    const currentLocationId = currentLocation ? currentLocation.id : null;

    if (currentLocationId) {
      try {
        yield put(setCartLoading(true));
        // eslint-disable-next-line max-len
        ({ data: signedInCart } = yield call(API.getCustomerCartAtLocation, customer.id, currentLocationId));
        console.log('*signInSaga; signedInCart: ', signedInCart);
      } catch (error) {
        if (error.response.status === 404) {
          console.log('*signInSaga; no signed in cart');
        } else {
          const errorMessage = getErrorMessage(error);

          console.log('API.getCustomerCartAtLocation error: ');
          console.log(errorMessage);

          toast.error(errorMessage);
        }
      } finally {
        yield put(setCartLoading(false));
      }
    }

    if (signedInCart && cart2ItemsSelector(signedInCart)?.length > 0) {
      yield put(setCart(signedInCart));
    } else {
      const cart = yield select(cartSelector);

      if (cart) {
        yield call(assignCartSaga, cart);
      }
    }

    yield put(setAuthFinished());
  }
}
