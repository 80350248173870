import { makeActionCreator } from 'service/utility';

export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE';
export const applyPromoCode = makeActionCreator(APPLY_PROMO_CODE, 'promoCode');

export const APPLY_GIFT_CARD = 'APPLY_GIFT_CARD';
export const applyGiftCard = makeActionCreator(APPLY_GIFT_CARD, 'giftCardId');

export const CREATE_CART_SESSION = 'CREATE_CART_SESSION';
export const createCartSession = makeActionCreator(CREATE_CART_SESSION);

export const GET_CUSTOMER_CART_AT_LOCATION = 'GET_CUSTOMER_CART_AT_LOCATION';
export const getCustomerCartAtLocation = makeActionCreator(GET_CUSTOMER_CART_AT_LOCATION, 'locationId', 'aaPayload');

export const LOAD_CART = 'LOAD_CART';
export const loadCart = makeActionCreator(LOAD_CART, 'cartId');

export const PUT_ITEMS_IN_CART = 'PUT_ITEMS_IN_CART';
export const putItemsInCart = makeActionCreator(PUT_ITEMS_IN_CART, 'items', 'history');

export const REMOVE_GIFT_CARD = 'REMOVE_GIFT_CARD';
export const removeGiftCard = makeActionCreator(REMOVE_GIFT_CARD);

export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const removeItemFromCart = makeActionCreator(REMOVE_ITEM_FROM_CART, 'itemId');

export const REMOVE_PROMO_CODE = 'REMOVE_PROMO_CODE';
export const removePromoCode = makeActionCreator(REMOVE_PROMO_CODE);

export const RESET_PAYMENT_STATUS = 'RESET_PAYMENT_STATUS';
export const resetPaymentStatus = makeActionCreator(RESET_PAYMENT_STATUS);

export const SET_CART = 'SET_CART';
export const setCart = makeActionCreator(SET_CART, 'cart');

export const SET_CART_LOADING = 'SET_CART_LOADING';
export const setCartLoading = makeActionCreator(SET_CART_LOADING, 'loading');

export const SET_INITIAL_ADD_TO_CART = 'SET_INITIAL_ADD_TO_CART';
export const setInitialAddToCart = makeActionCreator(SET_INITIAL_ADD_TO_CART, 'loading');

export const SET_INITIAL_ADD_TO_CART_FINISHED = 'SET_INITIAL_ADD_TO_CART_FINISHED';
export const setInitialAddToCartFinished = makeActionCreator(SET_INITIAL_ADD_TO_CART_FINISHED, 'loading');

export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const setPaymentStatus = makeActionCreator(SET_PAYMENT_STATUS, 'payload');

export const SET_TEMPORARY_CUSTOMER = 'SET_TEMPORARY_CUSTOMER';
export const setTemporaryCustomer = makeActionCreator(SET_TEMPORARY_CUSTOMER, 'customer');

export const UPDATE_ITEM_QUANTITY = 'UPDATE_ITEM_QUANTITY';
export const updateItemQuantity = makeActionCreator(UPDATE_ITEM_QUANTITY, 'itemId', 'quantity');

export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';
export const updateCartItems = makeActionCreator(UPDATE_CART_ITEMS, 'items', 'silent');
