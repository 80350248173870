import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from 'tss-react/mui';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Link as MuiLink, Typography, List,
} from '@mui/material';

import { cartSelector } from 'app/cart/redux/selectors';
import { changeLocation as changeLocationAction } from 'app/redux/actions';
import {
  location as locationSelector,
  locations as locationsSelector,
} from 'app/redux/selectors';
import { ALL_LOCATIONS } from 'app/redux/reducers/location';
import LocationDetails from 'common/LocationDetails';

import Location from './Location';


class LocationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLocationId: this.props.currentLocation.id,
    };
  }

  handleLocationSelect = (selectedLocationId) => {
    this.setState({ selectedLocationId });
  };

  handleSaveAndClose = () => {
    const { availableLocations, changeLocation, onLocationSelect, onClose } = this.props;
    const { selectedLocationId } = this.state;

    const selectedLocation = (
      selectedLocationId
        ? availableLocations.find((e) => e.id === selectedLocationId)
        : ALL_LOCATIONS
    );

    changeLocation(selectedLocation);

    if (onLocationSelect) {
      onLocationSelect(selectedLocation);
    }

    if (onClose) {
      onClose();
    }
  };


  render() {
    const {
      t, classes, open, currentLocation, availableLocations, firstAction, cart, onClose,
    } = this.props;
    const { selectedLocationId } = this.state;

    return (
      <Dialog
        open={open}
        scroll="paper"
        maxWidth="xs"
        fullWidth
      >
        {Boolean(currentLocation.id) && (
          <DialogTitle
            component="div"
            classes={{ root: 'pb-0' }}
          >
            <Typography variant="subtitle2">
              {t('common.CurrentLocation')}
            </Typography>
            <div className={classes.currentLocationContainer}>
              <LocationDetails location={currentLocation} />
            </div>
          </DialogTitle>
        )}
        <DialogContent classes={{ root: 'py-0 d-flex flex-column justify-content-start align-items-start' }}>
          <div className="w-100 pt-3">
            <Typography variant="subtitle2">
              {currentLocation.id
                ? t('common.ChooseNewLocation')
                : t('common.ChooseALocationYouWantToAt', { action: firstAction })}
            </Typography>
          </div>
          <div className={classes.locationListContainer}>
            <List disablePadding>
              {availableLocations.map((location, idx) => (
                <Location
                  key={location.id}
                  location={location}
                  onSelect={this.handleLocationSelect}
                  selected={selectedLocationId === location.id}
                  divider={idx < availableLocations.length - 1}
                />
              ))}
            </List>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {cart && (
            <div className="pl-3 mb-3">
              <Typography
                variant="subtitle2"
                component="span"
              >
                {`${t('common.Warning')}:\u00A0`}
              </Typography>
              <Typography
                variant="body2"
                component="span"
              >
                {t('common.IfYouChangeYourLocation')}
              </Typography>
            </div>
          )}
          <div className="ml-0 w-100 cml-3 d-flex flex-row justify-content-end align-items-center">
            {onClose && (
              <MuiLink
                component="button"
                onClick={onClose}
              >
                {t('common.Cancel')}
              </MuiLink>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSaveAndClose}
              disabled={!selectedLocationId}
            >
              {t('common.Save')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

LocationDialog.propTypes = {
  availableLocations: PropTypes.array.isRequired,
  cart: PropTypes.object,
  changeLocation: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currentLocation: PropTypes.object.isRequired,
  firstAction: PropTypes.string,
  onClose: PropTypes.func,
  onLocationSelect: PropTypes.func,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func,
};

LocationDialog.defaultProps = {
  firstAction: 'shop',
};


const mapStateToProps = (state) => ({
  availableLocations: locationsSelector(state),
  cart: cartSelector(state),
  currentLocation: locationSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeLocation: (location) => dispatch(changeLocationAction(location)),
});

const LocationDialog2 = connect(mapStateToProps, mapDispatchToProps)(LocationDialog);


const styles = (theme) => ({
  currentLocationContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `2px solid ${theme.palette.divider}`,
    width: '100%',
    padding: '0.75rem 1.25rem',
  },
  locationListContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  dialogActions: {
    borderTop: `2px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
});

const LocationDialog3 = withStyles(LocationDialog2, styles);


const LocationDialog4 = withTranslation()(LocationDialog3);


export default LocationDialog4;
