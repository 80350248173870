import { createReducer } from 'service/utility';

import { SET_REGION } from '../actions';


const initialState = null;

const regionReducer = createReducer(initialState, {
  [SET_REGION]: (state, { region }) => region,
});

export default regionReducer;
