import clm from 'country-locale-map';
import { DateTime, Info as LuxonInfo } from 'luxon';
import i18next from 'i18next';

import { COUNTRY_MAP } from 'config/countries';
import { COUNTRY_TO_CURRENCY_MAP } from 'config/currencies';


export const getCountryName = (countryCode) => COUNTRY_MAP[countryCode] || '';

export const getCurrencyCodeByCountry = (countryCode) => COUNTRY_TO_CURRENCY_MAP[countryCode] || null;


export const getCurrentCountryCode = (account) => (account && account.defaultCountryCode) || 'US';

export const getCurrentLocale = (account) => clm.getLocaleByAlpha2(getCurrentCountryCode(account)).replace(/_/, '-');

export const getCurrentLanguage = () => i18next.language;

export const usesPriceBeforeTax = (account) => {
  const countryCode = getCurrentCountryCode(account);

  return countryCode === 'US' || countryCode === 'CA';
};

export const requiresEmergencyContact = (account) => {
  const countryCode = getCurrentCountryCode(account);

  return countryCode === 'US' || countryCode === 'CA';
};

export const getCurrentCurrencyCode = (account) => getCurrencyCodeByCountry(getCurrentCountryCode(account)) || 'USD';

export const getCurrentCurrencySymbol = (account) => {
  const locale = getCurrentLocale(account) || 'en-US';
  const currencyCode = getCurrentCurrencyCode(account);

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  }).format('0').replace(/0/, '');
};

export const getCurrentCurrencyDecimalPlaces = (account) => {
  const countryCode = getCurrentCountryCode(account);

  if (countryCode === 'US' || countryCode === 'CA') return 2; // 3

  return 2;
};

export const getCurrentLocationTimezone = (currentLocation) => (
  currentLocation?.timeZone || DateTime.local().zoneName
);

export const getCurrentFirstDOW = (account) => {
  const countryCode = getCurrentCountryCode(account);

  if (countryCode === 'US' || countryCode === 'CA') return 6;

  return 0;
};

export const getCurrentWeekdaysDisplayOrder = (account) => {
  const firstDOW = getCurrentFirstDOW(account);
  const dow = [0, 1, 2, 3, 4, 5, 6];
  const idx = dow.indexOf(firstDOW);

  return dow.slice(idx).concat(dow.slice(0, idx));
};


export const getCalendarMonthsLong = () => LuxonInfo.months('long');
export const getCalendarWeekdaysLong = () => LuxonInfo.weekdays('long');
export const getCalendarWeekdaysShort = () => LuxonInfo.weekdays('short');
export const getCalendarWeekdaysNarrow = () => LuxonInfo.weekdays('narrow');
