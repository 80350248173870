import authSagas from 'app/auth/redux/sagas';
import cartSagas from 'app/cart/redux/sagas';

import appSagas from './sagas';

const sagasList = [
  ...appSagas,
  ...authSagas,
  ...cartSagas,
];

export const initSagas = (sagaMiddleware) => {
  sagasList.forEach(sagaMiddleware.run.bind(sagaMiddleware));
};
